import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {

  @Input() product!: any;
  @Output() unfavorited = new EventEmitter<any>();

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (Array.isArray(this.product.pictures)) {
      this.product = this.prioritizeHighlightPicture(this.product);
    }
  }

  private prioritizeHighlightPicture(product: any) {
    const highlightPicture = product.pictures.find((pic: any) => pic.highlight);

    return {
      ...product,
      highlight: highlightPicture?.file
    };
  }

  isFavoritesRoute(): boolean {
    return this.router.url === '/favorites';
  }

  unfavorite(e: any): void {
    e.stopPropagation();
    this.unfavorited.emit(this.product);
  }
}
