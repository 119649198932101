import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { environment } from 'src/environments/environment';
import { AuthRefreshResponse, AuthResponse, LoginRequest, StringResponse } from 'src/app/models';
import { Subject, tap } from 'rxjs';

const URL = `${environment.url}/auth`;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loginSubject = new Subject<any>();

  constructor(private http: HttpClient) { }

  login(user: LoginRequest): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(URL, user, { withCredentials: true }).pipe(
      tap(response => {
        this.loginSubject.next(response);
      })
    );
  }

  logout(token?: string): Observable<StringResponse> {
    return this.http.post<StringResponse>(`${URL}/logout`, token, { withCredentials: true });
  }

  checkPasswordRecovery(token: string): Observable<StringResponse> {
    return this.http.post<StringResponse>(`${URL}/check-password-recovery`, { token });
  }

  forgotPassword(email: string): Observable<StringResponse> {
    return this.http.post<StringResponse>(`${URL}/forgot-password`, email);
  }

  passwordRecovery(token: string, newPassword: string): Observable<StringResponse> {
    return this.http.post<StringResponse>(`${URL}/password-recovery`, { token, newPassword });
  }

  refreshToken(token: string): Observable<AuthRefreshResponse> {
    return this.http.post<AuthRefreshResponse>(`${URL}/refresh-token`, { token }, { withCredentials: true });
  }

  revokeToken(): Observable<StringResponse> {
    return this.http.post<StringResponse>(`${URL}/revoke-token`, null, { withCredentials: true });
  }

  verifyEmail(token: string): Observable<StringResponse> {
    return this.http.post<StringResponse>(`${URL}/verify-email`, { token });
  }

  getLoginObservable() {
    return this.loginSubject.asObservable();
  }
}
