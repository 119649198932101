import { animate, query, style, transition, trigger, group } from "@angular/animations";

export const fader = trigger('routeAnimations', [
  transition('* <=> *', [
    group([
      query(':enter, :leave', [
        style({
          position: 'absolute',
          left: 0,
          width: '100%',
          opacity: 0,
          transform: 'scale(0) translateY(100%)'
        }),
        animate('600ms ease',
          style({
            opacity: 1,
            transform: 'scale(1) translateY(0)'
          })
        )
      ], { optional: true })
    ])
  ])
]);

export const slider = trigger('routeAnimations', [
  transition('* <=> isLeft', slideTo('left')),
  transition('* <=> isRight', slideTo('right')),
  transition('isRight <=> *', slideTo('left')),
  transition('isLeft <=> *', slideTo('right')),
]);

function slideTo(direction: string) {
  const optional = { optional: true };
  const styles = {
    position: 'absolute',
    top: 0,
    width: '100%'
  };

  // Define animation styles based on direction
  const enterStyles = { [direction]: '0%' };
  const leaveStyles = { [direction]: direction === 'left' ? '100%' : '-100%' };

  return [
    query(':enter, :leave', [
      style(styles)
    ], optional),
    query(':enter', [
      style(enterStyles)
    ]),
    group([
      query(':leave', [
        animate('600ms ease', style(leaveStyles))
      ], optional),
      query(':enter', [
        animate('600ms ease', style({ left: '0%', right: '0%' }))
      ])
    ])
  ];
}
