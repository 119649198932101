import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slide-card',
  templateUrl: './slide-card.component.html',
  styleUrls: ['./slide-card.component.scss']
})
export class SlideCardComponent implements OnInit {
  @Input() data!: any;
  @Input() type!: any;
  @Input() isHighlight = false;
  @Input() detach = false;
  @Output() unfavorited = new EventEmitter<any>();

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (!this.data.pictures) {
      this.data.pictures = '../../../assets/icons/no_photography_FILL0_wght400_GRAD0_opsz24.svg';
    }
  }

  isFavoritesRoute(): boolean {
    return this.router.url === '/favorites';
  }

  unfavorite(e: any): void {
    e.stopPropagation();
    this.unfavorited.emit(this.data);
  }
}
