import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';
import { Router } from '@angular/router';

import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/ws-user/auth/auth.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { RecordError } from 'src/app/services/tracking/tracking.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private token: string | undefined;
  private lang: string | undefined;
  private isRefreshing: boolean = false;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private tokenService: TokenStorageService,
    private translateService: TranslateConfigService,
    private route: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.pathExcludes(request)) {
      return next.handle(request);
    }

    this.token = this.tokenService.getJwt()!;
    this.lang = this.translateService.getCurrentLang() ?? 'pt-BR';

    request = this.addHeader(request, this.lang, this.token);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && error.error?.errors[0] === "TokenExpired") {
          const lang = this.translateService.getCurrentLang() ?? 'pt-BR';

          return this.handleTokenRefresh(next, request);
        } else {
          const errorMsg = error.error.errors[0] || "Unknown Error Occurred";
          // this.route.navigate(['/home']);

          return throwError(errorMsg);
        }
      })
    );
  }

  private pathExcludes(request: HttpRequest<any>) {
    const pathEndsExcludes: string[] = [
      '/auth',
      '/auth/verify-email',
      '/auth/forgot-password',
      '/auth/check-password-recovery',
      '/auth/refresh-token',
      '/auth/password-recovery'
    ];

    const pathIncludesExcludes: string[] = [
      '/assets/i18n',
    ];

    return pathEndsExcludes.some(path => request.url.endsWith(path)) ||
      pathIncludesExcludes.some(path => request.url.includes(path));
  }

  private addHeader(request: HttpRequest<any>, lang: string, jwt: string | null) {
    let headers: { [key: string]: string } = {
      'Accept-Language': `${lang}`,
      'Content-Type': 'application/json'
    };

    if (jwt) {
      headers['Authorization'] = `Bearer ${jwt}`;
    }

    return request.clone({
      setHeaders: headers
    });
  }

  private handleTokenRefresh(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next('');

      return this.authService
        .refreshToken(this.tokenService.getRefresh())
        .pipe(
          switchMap((newToken: any) => {
            this.tokenService.removeJwt();

            if (newToken.data?.jwtToken) {
              this.tokenService.setJwt(newToken.data.jwtToken);
              this.tokenService.setRefresh(newToken.data.refreshToken);
              this.isRefreshing = false;
              this.refreshTokenSubject.next(newToken.data.jwtToken);

              return next.handle(this.addHeader(request, 'pt-BR', newToken.data.jwtToken));
            }
            this.isRefreshing = false;
            this.refreshTokenSubject.next('');

            console.log(JSON.stringify(newToken.errors[0], null, 2))

            RecordError(newToken.errors[0]);
            // this.dialog.closeAll();
            this.route.navigate(['/login']);
            this.toastService.displayToast(newToken.errors[0], 'error');

            return throwError(() => new Error('No JWT in refresh token response, redirecting to login'));
          }),
          catchError((error) => {
            // this.dialog.closeAll();
            this.isRefreshing = false;
            this.tokenService.removeJwt();

            console.log(JSON.stringify(error, null, 2))

            RecordError(error);
            this.route.navigate(['/login']);
            this.toastService.displayToast(JSON.stringify(error.name), 'error');
            return throwError(() => new Error('Failed to refresh token, redirecting to login'));
          })
        );

    } else {
      return this.refreshTokenSubject
        .pipe(
          filter((token: string) => token != ''),
          take(1),
          switchMap((jwt: string) => {
            this.tokenService.setJwt(jwt);
            return next.handle(this.addHeader(request, 'pt-BR', jwt));
          })
        );
    }
  }
}
