<ion-content *ngIf="isLoaded">
  <div class="bg-grad">
    <header class="px-4 pt-9">
      <div class="w-full flex items-center">
        <h4 class="m-0">{{ 'products' | translate }}</h4>
      </div>
      <ion-searchbar class="custom w-full mb-5" mode="ios" inputmode="search" (keyup.enter)="handleSearch($event)"
        [placeholder]="'search' | translate"></ion-searchbar>
    </header>
    <section class="px-4 pb-9 card-container m-0">
      <ion-grid class="ion-no-padding">
        <ion-row class="flex g-6 pt-6 justify-center">
          <ion-col size="auto" class="pt-4" *ngFor="let item of categories" (click)="gotoDetail(item)"
            class="card flex direction-col ion-no-padding">
            <div>
              <img data-test="img" class="card-image p-2" [src]="item.picture"
                onerror="this.src='../../../assets/icons/no_photography_FILL0_wght400_GRAD0_opsz24.svg'">
              <p class="card-title m-4">{{ item.title }}</p>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>
  </div>
</ion-content>

<ion-content *ngIf="!isLoaded">
  <div class="bg-grad">
    <header class="header p-6">
      <div class="w-full flex items-center">
        <div>
          <h4 class="m-0">{{ 'products' | translate }}</h4>
        </div>
      </div>
      <ion-searchbar class="custom w-full" mode="ios" [placeholder]="'search' | translate"
        [disabled]="true"></ion-searchbar>
    </header>
    <section class="px-6 pb-5 card-container m-0">
      <ion-grid>
        <ion-row class="flex g-6 pt-6 justify-center">
          <ion-col size="auto" class="pt-4" class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true" style="min-height: 100%; border-radius: 12px;"></ion-skeleton-text>
          </ion-col>
          <ion-col size="auto" class="pt-4" class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true" style="min-height: 100%; border-radius: 12px;"></ion-skeleton-text>
          </ion-col>
          <ion-col size="auto" class="pt-4" class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true" style="min-height: 100%; border-radius: 12px;"></ion-skeleton-text>
          </ion-col>
          <ion-col size="auto" class="pt-4" class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true" style="min-height: 100%; border-radius: 12px;"></ion-skeleton-text>
          </ion-col>
          <ion-col size="auto" class="pt-4" class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true" style="min-height: 100%; border-radius: 12px;"></ion-skeleton-text>
          </ion-col>
          <ion-col size="auto" class="pt-4" class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true" style="min-height: 100%; border-radius: 12px;"></ion-skeleton-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>
  </div>
</ion-content>
