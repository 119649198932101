import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-simple-card-list',
  templateUrl: './simple-card-list.component.html',
  styleUrls: ['./simple-card-list.component.scss']
})
export class SimpleCardListComponent implements OnInit {
  @Input() data!: any;
  @Input() type!: any;
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();

  ngOnInit(): void {
    if (this.type === 'products') {
      this.data = this.data.map((item: any) => {
        const highlightPicture = item.pictures.find((pic: any) => pic.highlight);
        return {
          ...item,
          name: item.title,
          picture: highlightPicture ? highlightPicture.file : null,
        };
      });
    }
  }

  selectItem(item: any) {
    this.onSelect.emit(item);
  }
}
