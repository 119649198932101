import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { StartMonitoring } from './app/services/tracking/tracking.service';

StartMonitoring({
  ios: 'AAafeca12f756c4a67232c506659c3208a76aa36f4-NRMA',
  android: 'AA47789cc6ae8311189fe27c15d8c4b55bc5ba552b-NRMA'
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.error(JSON.stringify(err, null, 2)));
