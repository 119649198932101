<div class="flex g-3">
  <button data-test="btn" class="product-options py-1 px-3" [disabled]="disabled" (click)="addtoFavorites()"
    *ngIf="!isFavorite">
    <img data-test="img" class="pb-1" [ngClass]="{'svg-dark-mode': darkMode}"
      src="../../../assets/icons/star_FILL0_wght400_GRAD0_opsz24.svg">
    {{ 'favorite' | translate }}
  </button>
  <button data-test="btn" class="product-options py-1 px-3" [disabled]="disabled" (click)="addtoFavorites()"
    *ngIf="isFavorite">
    <img data-test="img" class="pb-1" src="../../../assets/icons/icon-favorite.svg">
    {{ 'favorited' | translate }}
  </button>
  <button data-test="btn" class="product-options py-1 px-3" [disabled]="disabled" (click)="toShare()">
    <img data-test="img" class="pb-1" [ngClass]="{'svg-dark-mode': darkMode}"
      src="../../../assets/icons/share_FILL0_wght400_GRAD0_opsz24.svg">
    {{ 'share' | translate }}
  </button>
</div>
