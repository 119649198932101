<ion-grid class="ion-no-padding">
  <ion-row class="flex g-6 pt-6">
    <ion-col size="auto" class="pt-4" *ngFor="let item of data" (click)="selectItem(item)"
      class="card flex direction-col ion-no-padding">
      <div>
        <img data-test="img" class="card-image" [src]="item.picture">
        <p class="card-title m-4">{{ item.name }}</p>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
