<button id="filter-button" class="flex filter-button items-center ps-4 pe-4" [disabled]="disabled">
  <ion-icon data-test="ico" size="large" name="options-outline" class="pe-3 filter-icon"></ion-icon>
  <p class="filter-button-label">
    {{ 'filter.by' | translate }}
  </p>
  <span data-test="spa" class="ps-1 filter-button-categories">
    {{ categoriesNamesWithComma }}
  </span>
</button>
<ion-modal #modal trigger="filter-button">
  <ng-template>
    <app-typeahead class="ion-page" type="category" [items]="categories" [selectedItems]="categoriesSelected"
      [title]="'categories' | translate" (selectionChange)="categoriesChange($event)"
      (selectionCancel)="modal.dismiss()"></app-typeahead>
  </ng-template>

</ion-modal>
