<ion-content *ngIf="isLoaded">
  <header class="px-4 pt-8">
    <div class="w-full flex items-center">
      <app-back-button></app-back-button>
      <div>
        <h4 class="m-0">{{ parentCategoryName }}</h4>
      </div>
    </div>
    <ion-searchbar class="custom w-full" mode="ios" inputmode="search" (ionInput)="search($event)"
      (keyup)="listenForSearchEnter($event)" [placeholder]="'search' | translate"></ion-searchbar>
  </header>
  <section class="px-4 pb-9 pt-4 card-container m-0">
    <app-segment [subcategories]="subcategories" [procedures]="procedures"
      (onSelectSub)="selectSubcategory($event)"></app-segment>
  </section>
</ion-content>
