<div class="flex items-center role-container w-full">
  <img [src]="icon" class="pe-3 role-icon">
  <div class="role-input w-full flex direction-col pt-4">
    <p class="role-label">{{ title }}</p>
    <button [id]="type" fill="clear" size="small" class="w-full flex" slot="start" [disabled]="disabled"
      [ngClass]="disabled ? 'mb-4':''">
      <span *ngIf="itemFromSelect" class="selected-role">{{ itemFromSelect }}</span>
      <span *ngIf="!itemFromSelect">{{ placeholder | translate }}</span>
      <img slot="end" class="pb-2" src="../../../assets/icons/expand_more_FILL0_wght400_GRAD0_opsz24.svg"
        *ngIf="!disabled">
    </button>
  </div>

  <ion-modal #modal [trigger]="type" [initialBreakpoint]="1" [breakpoints]="[0, 1]">
    <ng-template>
      <ion-header class="px-6 pt-4 ion-no-border">
        <ion-toolbar>
          <ion-title class="modal-title p-0">{{ title }}</ion-title>
          <ion-buttons slot="end">
            <ion-button (click)="modal.dismiss()" class="close-modal">{{ 'close' | translate }}</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list class="px-6 pb-7">
          <ion-item class="ion-no-padding" *ngFor="let item of data" (click)="selectItem(item); modal.dismiss()">
            <ion-label class="role-key">
              <p class="option">{{ item.title }}</p>
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-modal>
</div>
