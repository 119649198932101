<div class="flex direction-col location-container w-full" id="open-country">
  <div class="flex items-center w-full">
    <img data-test="img" src="../../../assets/icons/public_FILL0_wght400_GRAD0_opsz24 1.svg" class="pe-3">
    <div class="w-full flex direction-col pt-4">
      <p class="title-label" *ngIf="userCountries.length <= 0" [ngClass]="'userCountries > 0' ? 'aws' : 'mb-0'">{{
        'country' | translate }}
      </p>
      <button data-test="btn" fill="clear" size="small" class="w-full flex justify-between" slot="start"
        [disabled]="!isLoaded">
        <ion-spinner *ngIf="!isLoaded" class="mb-4" name="dots"></ion-spinner>
        <span *ngIf="userCountries.length <= 0 && isLoaded" class="input my-4">{{ 'country.select' | translate }}</span>
        <span class="title-label with-countries" *ngIf="userCountries.length > 0">{{ 'country' | translate }}</span>
        <img slot="end" class="pb-2" src="../../../assets/icons/add_FILL0_wght400_GRAD0_opsz24.svg">
      </button>
    </div>
  </div>
  <ion-modal trigger="open-country" #modal data-test="mdl">
    <ng-template>
      <app-typeahead class="ion-page" type="country" [items]="allCountries" [selectedItems]="userCountries"
        [title]="'country' | translate" (selectionChange)="selectionCountry($event)"
        (selectionCancel)="modal.dismiss()"></app-typeahead>
    </ng-template>
  </ion-modal>
  <section class="flex justify-between" *ngIf="userCountries.length > 0">
    <div class="flex wrap g-3 chip-container">
      <ion-chip *ngFor="let item of userCountries" data-test="chp">
        <ion-label>{{ item.countryTitle }}</ion-label>
        <ion-icon class="ms-1" data-test="ico" (click)="$event.stopPropagation(); removeCountry(item)"
          name="close"></ion-icon>
      </ion-chip>
    </div>
    <img data-test="img" slot="end" class="pb-2"
      src="../../../assets/icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg">
  </section>
</div>

<div class="flex direction-col location-container w-full" id="open-city" *ngIf="userCountries.length > 0">
  <div class="flex items-center w-full">
    <img data-test="img" src="../../../assets/icons/villa_FILL0_wght400_GRAD0_opsz24 1.svg" class="pe-3">
    <div class="w-full flex direction-col pt-4">
      <p class="title-label" *ngIf="userCities.length <= 0" [ngClass]="'userCities > 0' ? 'aws' : 'mb-0'">{{ 'city' |
        translate }}</p>
      <button data-test="btn" fill="clear" size="small" class="w-full flex justify-between" slot="start"
        [disabled]="!isLoadedCities">
        <span class="title-label with-countries" *ngIf="userCities.length > 0">{{ 'city' | translate }}</span>
        <ion-spinner *ngIf="!isLoadedCities" class="mb-4" name="dots"></ion-spinner>
        <span *ngIf="userCities.length <= 0 && isLoadedCities" class="input my-4">{{ 'city.select' | translate }}</span>
        <img slot="end" class="pb-2" src="../../../assets/icons/add_FILL0_wght400_GRAD0_opsz24.svg">
      </button>
    </div>
  </div>
  <ion-modal trigger="open-city" #cityModal data-test="mdl">
    <ng-template>
      <app-typeahead class="ion-page" type="city" [items]="allCities" [selectedItems]="userCities"
        [title]="'city' | translate" (selectionChange)="selectionCity($event)"
        (selectionCancel)="cityModal.dismiss()"></app-typeahead>
    </ng-template>
  </ion-modal>
  <section class="flex justify-between" *ngIf="userCities.length > 0 && isLoadedCities">
    <div class="flex wrap g-3 chip-container">
      <ion-chip *ngFor="let item of userCities" data-test="chp">
        <ion-label>{{ item.cityTitle }} - {{ item.stateShort }}</ion-label>
        <ion-icon class="ms-1" data-test="ico" (click)="$event.stopPropagation(); removeCity(item)"
          name="close"></ion-icon>
      </ion-chip>
    </div>
    <img data-test="img" slot="end" class="pb-2"
      src="../../../assets/icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg">
  </section>
</div>
