<article data-test="art" class="p-3 g-2 flex items-start justify-between">
  <div class="flex justify-between g-4">
    <div class="display flex justify-center direction-col">
      <p class="day">{{ event.eventStart | date: 'd'}}</p>
      <p class="month">{{ event.eventStart | date: 'MMM'}}</p>
    </div>
    <div class="flex direction-col">
      <p class="title">{{ event.title }}</p>
      <p class="type" [ngStyle]="{'color': event.color}">{{ event.type }}</p>
      <p class="date">{{ event.eventStart | date: 'EEEE, HH:mm' | titlecase }}</p>
      <p class="location">{{ event.cityTitle }}, {{ event.stateShort }}</p>
    </div>
  </div>
  <img [src]="event.pictures[0].file" class="img" onerror="this.src='../../../assets/icons/no_photography_FILL0_wght400_GRAD0_opsz24.svg'"/>
</article>
