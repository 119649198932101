import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shorts-card',
  templateUrl: './shorts-card.component.html',
  styleUrls: ['./shorts-card.component.scss']
})
export class ShortsCardComponent {
  @Input() procedure!: any;

}
