import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache/cache.service';
import { ToastService } from 'src/app/services/toast/toast.service';

import { CategoryService } from 'src/app/services/ws-common/categories/category.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {

  isLoaded = false;
  categories: any = [];
  searchQuery = '';

  constructor(private router: Router, private categoryService: CategoryService, private toastService: ToastService, private cache: CacheService) { }

  ngOnInit(): void {
    this.fetchCategories();
  }

  private fetchCategories() {
    this.isLoaded = false;
    this.categoryService.getSubcategoryProductRelated().subscribe({
      next: (r) => {
        let arrayRef = r.data.filter((c: any) => !!c.children && c.children.length >= 1);
        this.categories = [...arrayRef];
        this.isLoaded = true;
      },
      error: (e) => this.toastService.displayToast(e.error.errors[0], 'error')
    });
  }

  /*This is needed because of iOS keyboard*/
  listenForSearchEnter(e: KeyboardEvent) {
    if (e.code === 'Enter' || e.key === 'Enter') {
      this.router.navigate(['/products/search'], { queryParams: { query: this.searchQuery, prev: 'products/categories' } });
    }
  }

  search(e: CustomEvent) {
    const query = (e.detail as any).value;
    if (query) {
      this.searchQuery = query;
    }
  }

  gotoDetail(category: any) {
    this.router.navigate(['/products/subcategories'], { queryParams: { category: category.id } });
  }

  goBack() {
    this.router.navigate(['/home']);
  }

  handleRefresh(event: any) {
    this.cache.clearAll();
    this.fetchCategories();
    event.target.complete();
  }
}
