import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonModal } from '@ionic/angular';

import { OverlayEventDetail } from '@ionic/core/components';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputComponent),
      multi: true,
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class DateInputComponent implements ControlValueAccessor, OnInit {
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() optional = false;
  @ViewChild(IonModal) modal!: IonModal;
  // @ViewChild('date') datetime!: IonDatetime;
  providedInput!: Date;

  @Output() selectDate = new EventEmitter<string>;
  @Input() date!: any;
  // date = new Date();
  disableBtn = true;

  ngOnInit(): void {
    this._value = this.date;
  }

  public _value: any;
  public disabled!: boolean;
  onChanged: any = () => { };
  onTouched: any = () => { };

  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  cancel() {
    this.modal.dismiss(null, 'cancel');
  }

  dateChange(value: any) {
    this.providedInput = value;
    this.disableBtn = false;
  }

  confirm() {
    this.date = this.providedInput;
    this.onTouched();
    this.onChanged(this.date);
    this.modal.dismiss(this.date, 'confirm');
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
      // this.message = `Hello, ${ev.detail.data}!`;
    }
  }
}
