import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';

import { PipesModule } from '../pipes/pipes.module';
import { TabsComponent } from './tabs/tabs.component';
import { RoleInputComponent } from './role-input/role-input.component';
import { LocationInputComponent } from './location-input/location-input.component';
import { SimpleInputComponent } from './simple-input/simple-input.component';
import { TypeaheadComponent } from './typeahead/typeahead.component';
import { ShortsCardComponent } from './shorts-card/shorts-card.component';
import { SlideCardComponent } from './slide-card/slide-card.component';
import { GuideCardComponent } from './guide-card/guide-card.component';
import { SegmentComponent } from './segment/segment.component';
import { SimpleCardListComponent } from './simple-card-list/simple-card-list.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DateInputComponent } from './date-input/date-input.component';
import { FormsModule } from '@angular/forms';
import { PasswordCheckComponent } from './password-check/password-check.component';
import { InputSkeletonComponent } from './skeletons/input-skeleton/input-skeleton.component';
import { EventCardComponent } from './event-card/event-card.component';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { CountryInputComponent } from './country-input/country-input.component';
import { DirectivesModule } from '../utils/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { SliderComponent } from './slider/slider.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NoResultsCardComponent } from './no-results-card/no-results-card.component';
import { BackButtonComponent } from './back-button/back-button.component';
import { RelatedContentComponent } from './related-content/related-content.component';
import { MonthYearPickerComponent } from './month-year-picker/month-year-picker.component';



@NgModule({
  declarations: [
    TabsComponent,
    RoleInputComponent,
    LocationInputComponent,
    SimpleInputComponent,
    TypeaheadComponent,
    ShortsCardComponent,
    SlideCardComponent,
    GuideCardComponent,
    SegmentComponent,
    SimpleCardListComponent,
    DateInputComponent,
    PasswordCheckComponent,
    InputSkeletonComponent,
    EventCardComponent,
    ActionButtonsComponent,
    CountryInputComponent,
    FilterButtonComponent,
    SliderComponent,
    ProductCardComponent,
    CalendarComponent,
    NoResultsCardComponent,
    BackButtonComponent,
    RelatedContentComponent,
    MonthYearPickerComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    FormsModule,
    DirectivesModule,
    TranslateModule,
    PipesModule
  ],
  exports: [
    TabsComponent,
    RoleInputComponent,
    LocationInputComponent,
    SimpleInputComponent,
    ShortsCardComponent,
    SlideCardComponent,
    GuideCardComponent,
    SegmentComponent,
    SimpleCardListComponent,
    DateInputComponent,
    PasswordCheckComponent,
    InputSkeletonComponent,
    EventCardComponent,
    ActionButtonsComponent,
    CountryInputComponent,
    FilterButtonComponent,
    SliderComponent,
    ProductCardComponent,
    CalendarComponent,
    NoResultsCardComponent,
    BackButtonComponent,
    RelatedContentComponent
  ]
})
export class ComponentsModule { }
