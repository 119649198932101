import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Capacitor } from '@capacitor/core';
import { Observable, tap, from, finalize, catchError, switchMap } from 'rxjs';

import { GetDTHeaders, FilterHeaders, HttpTransaction, NetworkFailure, RecordMetric, RecordBreadcrumb } from 'src/app/services/tracking/tracking.service';
import { EMetricUnit } from 'src/app/enums';

/**
 * CustomInterceptor é uma classe que implementa a interface HttpInterceptor do Angular.
 * Seu propósito é interceptar requisições HTTP e respostas para realizar operações adicionais,
 * como rastreamento de bytes e tratamento de erros específicos da plataforma.
 */
@Injectable()
export class CustomInterceptor implements HttpInterceptor {
  // Inicializa as variáveis para rastreamento de bytes enviados e recebidos, e o tempo de início.
  sentBytes: number = 0;
  receivedBytes: number = 0;
  startTime: number = Date.now();
  startPerformance: number = performance.now();
  body: object = {};
  status: number = 0;
  breadcrumbName: string = 'HttpResponse';

  constructor() { }

  /**
   * Método que intercepta todas as requisições HTTP.
   * @param request A requisição HTTP de entrada.
   * @param next O manipulador HTTP que passará a requisição para o próximo interceptor na cadeia.
   * @returns Um Observable que emite eventos HttpEvent para a requisição.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Reinicia as variáveis de rastreamento para cada nova requisição.
    this.sentBytes = 0;
    this.receivedBytes = 0;
    this.startTime = Date.now();
    this.startPerformance = performance.now();
    this.breadcrumbName = 'HttpResponse';

    // Verifica se a plataforma é nativa e processa os cabeçalhos.
    if (Capacitor.isNativePlatform()) {
      // Obtém os cabeçalhos personalizados e clona a requisição com os novos cabeçalhos.
      return from(GetDTHeaders())
        .pipe(
          switchMap((dtHeaders) => {
            let clonedRequest = request.clone({ setHeaders: FilterHeaders(dtHeaders) });

            // Calcula e adiciona os bytes enviados ao total.
            this.sentBytes += this.calculateSentBytes(clonedRequest);

            // Processa a requisição clonada e rastreia a resposta.
            return next
              .handle(clonedRequest)
              .pipe(
                tap((event) => {
                  // Se a resposta for bem-sucedida, atualiza o corpo, status e bytes recebidos.
                  if (event instanceof HttpResponse) {
                    this.body = event.body ?? this.body;
                    this.status = event.status;
                    this.receivedBytes = this.calculateReceivedBytes(event);
                    RecordMetric('ReceivedBytes', 'DataTransfer', this.receivedBytes, EMetricUnit.OPERATIONS, EMetricUnit.BYTES);
                  }
                }),
                catchError((error) => {
                  this.breadcrumbName = 'HttpErrorResponse';
                  // Em caso de erro, chama o método de tratamento de erro.
                  this.handleError(error, clonedRequest);
                  throw error;
                }),
                finalize(() => {
                  // Ao finalizar, registra a transação HTTP.
                  let endTime = Date.now();
                  let url = clonedRequest.url ? Capacitor.convertFileSrc(clonedRequest.url) : '';

                  if (url.startsWith('http')) {
                    HttpTransaction(
                      this.body,
                      this.status,
                      clonedRequest,
                      dtHeaders,
                      { sentBytes: this.sentBytes, receivedBytes: this.receivedBytes },
                      { startTime: this.startTime, endTime: endTime },
                      url
                    );

                    RecordBreadcrumb(this.breadcrumbName, {
                      'url': url,
                      'method': clonedRequest.method,
                      'status': this.status,
                      'duration': performance.now() - this.startPerformance
                    });

                    let duration = (performance.now() - this.startPerformance) / 1000;
                    RecordMetric('TotalRequestTime', 'Performance', duration, EMetricUnit.OPERATIONS, EMetricUnit.SECONDS);
                    RecordMetric('SentBytes', 'DataTransfer', this.sentBytes, EMetricUnit.OPERATIONS, EMetricUnit.BYTES);
                  }
                })
              )
          }));
    } else {
      // Se não for uma plataforma nativa, processa a requisição normalmente.
      return next.handle(request);
    }
  }

  /**
   * Calcula o tamanho em bytes dos cabeçalhos e do corpo da requisição.
   * @param req A requisição HTTP.
   * @returns O tamanho total em bytes da requisição.
   */
  private calculateSentBytes(req: HttpRequest<any>): number {
    // Calcula o tamanho dos cabeçalhos e do corpo da requisição.
    let headerBytes = new Blob([JSON.stringify(req.headers)]).size;
    let bodyBytes = req.body ? new Blob([JSON.stringify(req.body)]).size : 0;

    // Retorna a soma dos bytes dos cabeçalhos e do corpo.
    return headerBytes + bodyBytes;
  }

  /**
   * Calcula o tamanho em bytes dos cabeçalhos e do corpo da resposta.
   * @param res A resposta HTTP.
   * @returns O tamanho total em bytes da resposta.
   */
  private calculateReceivedBytes(res: HttpResponse<any>): number {
    // Calcula o tamanho dos cabeçalhos e do corpo da resposta.
    let headerBytes = new Blob([JSON.stringify(res.headers)]).size;
    let bodyBytes = res.body ? new Blob([JSON.stringify(res.body)]).size : 0;

    // Retorna a soma dos bytes dos cabeçalhos e do corpo.
    return headerBytes + bodyBytes;
  }

  /**
   * Trata erros que ocorrem durante a requisição HTTP.
   * @param error O erro capturado.
   * @param request A requisição HTTP que gerou o erro.
   */
  private handleError(error: any, request: HttpRequest<any>): void {
    // Registra o tempo de término e obtém a URL da requisição.
    let endTime = Date.now();
    let url = error.url ? Capacitor.convertFileSrc(error.url) : '';

    // Se for um erro de resposta HTTP e a URL começar com 'http', processa o erro de rede.
    if (error instanceof HttpErrorResponse && url.startsWith('http')) {
      this.body = error?.error ?? this.body;
      this.status = error.status;

      RecordMetric('ErrorStatus', 'Error', this.status, EMetricUnit.OPERATIONS);

      NetworkFailure(
        error,
        request,
        this.startTime,
        endTime
      );
    }
  }
}
