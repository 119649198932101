<ion-segment data-test="sgm" mode="md" value="subcategories" (ionChange)="onChangeTab($event)">
  <ion-segment-button data-test="btn" mode="md" value="subcategories" class="p-4">
    <ion-label data-test="lbl" class="tab-title">{{ 'categories' | translate }}</ion-label>
  </ion-segment-button>
  <ion-segment-button mode="md" value="procedures" class="p-4">
    <ion-label data-test="lbl" class="tab-title">{{ 'procedures' | translate }}</ion-label>
  </ion-segment-button>
</ion-segment>

<ion-list data-test="lst" *ngIf="currentTab == 'subcategories'" class="pt-4 pb-4">
  <ion-item data-test="itm" *ngFor="let subcategory of subcategories" class="ion-no-padding"
    (click)="selectSub(subcategory)">
    <ion-label data-test="lbl" class="list-items" class="ion-text-wrap">
      {{ subcategory.title }}
    </ion-label>
  </ion-item>
</ion-list>

<ion-list data-test="lst" *ngIf="currentTab == 'procedures'" class="pt-4 pb-4">
  <ion-item data-test="itm" *ngFor="let procedure of procedures" class="ion-no-padding" (click)="selectSub(procedure)">
    <ion-label data-test="lbl" class="list-items" class="ion-text-wrap">
      {{ procedure.title }}
    </ion-label>
  </ion-item>
</ion-list>
