<ion-content *ngIf="isLoaded">
    <ion-refresher mode="ios" slot="fixed" [pullMin]="350" [pullMax]="650" (ionRefresh)="handleRefresh($event)">
      <ion-refresher-content mode="ios" pullingIcon="chevron-down-circle-outline"
        refreshingSpinner="circles"></ion-refresher-content>
    </ion-refresher>
    <header class="px-4 pt-8">
      <div class="w-full flex items-center">
        <app-back-button></app-back-button>
        <div>
          <h4 class="m-0">{{ subcategoryName }}</h4>
        </div>
      </div>
      <ion-searchbar class="custom w-full" mode="ios" inputmode="search" (ionInput)="search($event)"
        (keyup)="listenForSearchEnter($event)" [placeholder]="'search' | translate"></ion-searchbar>
    </header>
    <section class="p-6 card-container m-0 flex justify-between g-6" *ngIf="products.length > 0">
      <app-product-card class="flex justify-center" *ngFor="let product of products" (click)="gotoDetail(product)"
        [product]="product"></app-product-card>
    </section>
</ion-content>

<ion-content *ngIf="!isLoaded">
    <header class="header px-4 pt-8">
      <div class="w-full flex items-center">
        <img class="pe-3" (click)="gotoPreviousPage()"
          src="../../../assets/icons/arrow_back_FILL0_wght400_GRAD0_opsz24_white.svg">
        <div>
          <h4 class="m-0">{{ subcategoryName }}</h4>
        </div>
      </div>
      <ion-searchbar class="custom w-full" mode="ios" [placeholder]="'search' | translate"
        [disabled]="true"></ion-searchbar>
    </header>
    <section class="p-6 card-container m-0 flex justify-between g-6">
      <ion-skeleton-text [animated]="true" style="min-height: 180px; border-radius: 12px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" style="min-height: 180px; border-radius: 12px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" style="min-height: 180px; border-radius: 12px;"></ion-skeleton-text>
      <ion-skeleton-text [animated]="true" style="min-height: 180px; border-radius: 12px;"></ion-skeleton-text>
    </section>
</ion-content>
