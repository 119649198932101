import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[phone]',
})
export class PhoneDirective {

  constructor(public model: NgControl) { }

  @HostListener('input', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInputChange(event, true);
  }

  onInputChange(event: any, backspace: any) {
    var newVal = event.target.value.replace(/\D/g, '');
    newVal = `(${newVal.slice(0, 2)})${newVal.slice(2)}`;

    if (newVal.length >= 13) {
      newVal = `${newVal.slice(0, 9)}-${newVal.slice(9)}`;

    } else if (newVal.length === 12) {
      newVal = `${newVal.slice(0, 8)}-${newVal.slice(8)}`;
    }

    if (backspace && newVal.length <= 4) {
      newVal = newVal.substring(0, newVal.length - 1);
    }

    if (newVal === '()') {
      newVal = '';
    }

    if (this.model.valueAccessor) {
      event.target.value = newVal;
      this.model.valueAccessor.writeValue(newVal);
    }
  }
}
