<ion-content>
    <div class="px-6 pt-10 card-container h-full flex direction-col">
      <header class="flex direction-col items-center">
        <img data-test="img" class="pt-10 pb-6"
          [src]="isDarkTheme ? '../../../assets/img/sintegra_darkmode.svg' : '../../../assets/img/sintegra.svg'">
        <section class="pt-7 pb-7 flex direction-col items-center">
          <h3 class="title color-prim-50">{{'welcome' | translate }}</h3>
          <p class="color-prim-60">{{"signin" | translate }}</p>
        </section>
      </header>
      <section class="flex direction-col items-center">
        <form data-test="frm" class="w-full flex direction-col" [formGroup]="form" (ngSubmit)="onSubmit()">
          <ion-list data-test="lst" class="ion-no-padding g-4 flex direction-col">
            <app-simple-input formControlName="email"
              [iconPath]="'../../../assets/icons/mail_FILL0_wght400_GRAD0_opsz24.svg'" [label]="'E-mail'"
              [type]="'email'" [placeholder]="'Example@example.com'" id="email" name="email" [valid]="isValid('email')"
              [invalidInputMsg]="'invalid.email' | translate" [required]="true"></app-simple-input>
            <app-simple-input formControlName="password"
              [iconPath]="'../../../assets/icons/lock_FILL0_wght400_GRAD0_opsz24.svg'" [label]="'password' | translate"
              [type]="'password'" [placeholder]="'password.type' | translate" id="password" name="password"
              [required]="true"></app-simple-input>
          </ion-list>
          <div class="flex items-center pt-5">
            <ion-checkbox data-test="cbx" formControlName="keepLoggedIn" class="pe-3"
              labelPlacement="start"></ion-checkbox>
            <p class="agreement-p">
              {{"keepLogged" | translate }}
            </p>
          </div>
          <ion-button data-test="btn" expand="block" type="submit" class="w-full my-7 submit-button self-center"
            *ngIf="!awaitingResponse" [disabled]="!form.valid">{{"login" | translate }}</ion-button>
          <ion-button data-test="btn" expand="block" type="submit" class="w-full my-7 submit-button self-center"
            *ngIf="awaitingResponse" disabled="true">
            <ion-spinner name="circles"></ion-spinner>
          </ion-button>
        </form>
        <ion-button data-test="btn" class="color-prim-50" fill="clear" [routerLink]="['/login/recover']">
          <p class="color-prim-60 pt-2">{{"password.recover" | translate }}</p>
        </ion-button>
        <ion-button data-test="btn" fill="clear" [routerLink]="['/login/register']">
          <p class="color-sec-50">{{"account.create" | translate }}</p>
        </ion-button>
      </section>
      <p class="pb-6 app-version">v{{ appVersion }}</p>
    </div>
</ion-content>
