<div class="clean-input flex items-center">
  <img src="../../../assets/icons/calendar_today_FILL0_wght400_GRAD0_opsz24.svg" data-test="img" class="pe-3">
  <div class="input-container pb-3 w-full">
    <div class="flex direction-col w-full input pt-4">
      <label class="label" data-test="lbl" id="date-modal">
        {{ label }}
        <ion-text data-text="txt" class="optional">{{ 'optional' | translate }}</ion-text>
      </label>
      <ion-text class="py-1" data-text="txt" *ngIf="!date"> {{ placeholder }}</ion-text>
      <ion-text class="py-1 selected-date" data-text="txt" *ngIf="date"> {{ date | date }}</ion-text>
    </div>
  </div>
</div>
<ion-modal trigger="date-modal" class="date" data-test="mdl" (willDismiss)="onWillDismiss($event)"
  [initialBreakpoint]="0.67">
  <ng-template class="py-4 px-6">
    <ion-header class="py-4 px-6 ion-no-border">
      <ion-toolbar>
        <ion-title class="modal-title" data-test="ttl">{{ 'personal.birthdate' | translate }}</ion-title>
        <ion-buttons slot="end">
          <ion-button class="close-modal" data-test="btn" (click)="cancel()">{{ 'close' | translate }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="py-4 px-6">
      <!-- <ion-datetime #date presentation="date" data-test="dtt" (ionChange)="dateChange(date.value)"></ion-datetime> -->
      <app-calendar (onSelectDay)="dateChange($event)" [isInput]="true" [highlightDates]="[date]"></app-calendar>
      <ion-button class="save-button px-6 mt-5" data-test="btn" (click)="confirm()" [disabled]="disableBtn">{{ 'confirm'
        |
        translate }}</ion-button>
    </ion-content>
  </ng-template>
</ion-modal>
