<p class="password-ul mt-4">{{ 'password.contain' | translate }}</p>
<ul aria-label="A senha deve conter:">
  <li class="flex items-center password-li pt-4 pb-2">
    <ion-icon name="checkmark-outline" *ngIf="passwordHasLength" size="large" class="check-icon success"></ion-icon>
    <ion-icon name="close-outline" *ngIf="!passwordHasLength" size="large" class="check-icon" color="danger"></ion-icon>
    <p>{{ 'password.contain.char' | translate }}</p>
  </li>
  <li class="flex items-center password-li pt-2 pb-2">
    <ion-icon name="checkmark-outline" *ngIf="passwordHasNumber" size="large" class="check-icon success"></ion-icon>
    <ion-icon name="close-outline" *ngIf="!passwordHasNumber" size="large" class="check-icon" color="danger"></ion-icon>
    <p>{{ 'password.contain.number' | translate }}</p>
  </li>
  <li class="flex items-center password-li pt-2 pb-2">
    <ion-icon name="checkmark-outline" *ngIf="passwordHasLetter" size="large" class="check-icon success"></ion-icon>
    <ion-icon name="close-outline" *ngIf="!passwordHasLetter" size="large" class="check-icon" color="danger"></ion-icon>
    <p>{{ 'password.contain.upper' | translate }}</p>
  </li>
  <li class="flex items-center password-li pt-2 pb-2">
    <ion-icon name="checkmark-outline" *ngIf="passwordHasSpecialChar" size="large" class="check-icon success"></ion-icon>
    <ion-icon name="close-outline" *ngIf="!passwordHasSpecialChar" size="large" class="check-icon"
      color="danger"></ion-icon>
    <p>{{ 'password.contain.special' | translate }}</p>
  </li>
</ul>
