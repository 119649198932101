import { Injectable } from '@angular/core';

import { RemoveUser } from 'src/app/services/tracking/tracking.service';
import { AuthRefresh } from 'src/app/models';

const TOKEN_KEY = 'jwtToken';
const REFRESH_KEY = 'refreshToken';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  signOut(): void {
    RemoveUser();
    localStorage.clear();
  }

  setJwt(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  }

  setRefresh(token: string): void {
    localStorage.setItem(REFRESH_KEY, token);
  }

  setTokens(tokens: AuthRefresh): void {
    localStorage.setItem(TOKEN_KEY, tokens.jwtToken);
    localStorage.setItem(REFRESH_KEY, tokens.refreshToken);
  }

  removeJwt(): void {
    localStorage.removeItem(TOKEN_KEY);
  }

  removeRefresh(): void {
    localStorage.removeItem(REFRESH_KEY);
  }

  removeTokens():void {
    RemoveUser();
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_KEY);
  }  

  getJwt(): string {
    return localStorage.getItem(TOKEN_KEY)!;
  }

  getRefresh(): string {
    return localStorage.getItem(REFRESH_KEY)!;
  }

  getTokens(): AuthRefresh {
    return {
      jwtToken: this.getJwt(),
      refreshToken: this.getRefresh()
    };
  }

  loggedIn(): boolean {
    return !!this.getJwt();
  }
}
