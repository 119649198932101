<ion-content *ngIf="isLoaded">
  <header class="px-4 product pt-8">
    <div class="w-full flex items-center">
      <app-back-button></app-back-button>
      <div class="page-title">
        <p class="m-0">{{ 'product.detail' | translate }}</p>
      </div>
    </div>
  </header>
  <div class="card-detail pt-6 flex direction-col g-4">
    <swiper-container #productSwiper [pagination]="true">
      <swiper-slide *ngFor="let image of images" class="flex justify-center">
        <img [src]="image" style="max-height: 200px; min-height: 100px;"
          onerror="this.src='../../../assets/icons/no_photography_FILL0_wght400_GRAD0_opsz24.svg'">
      </swiper-slide>
    </swiper-container>

    <section class="pb-9">
      <section class="px-4">
        <p class="pt-3 mb-4 product-title">{{ product.title }}</p>
        <app-action-buttons (onAddtoFavorites)="addToFavorites($event)" [itemToShare]="product"
          [isFavorite]="product.favorite"></app-action-buttons>
        <div class="product-registry flex g-3 items-center py-3">
          <a *ngIf="product.registerLink" [href]="product.registerLink">
            <img class="py-3" [ngClass]="{'svg-dark-mode': darkMode}"
              src="../../../assets/icons/open_in_new_FILL0_wght400_GRAD0_opsz24.svg">
          </a>
          <p *ngIf="product.register">
            {{ 'registry.anvisa' | translate }} {{ product.register }}
          </p>
        </div>
        <p class="px-4 product-desc ql-editor" [innerHTML]="product.description | safeHtml"></p>
        <ion-button expand="block" type="button" class="w-full py-4 purchase-button" (click)="requestTraining()"
          *ngIf="!isRequestTraining">
          {{ 'training.request' | translate }}</ion-button>
        <ion-button expand="block" type="button" class="w-full py-4 purchase-button" disabled="true"
          *ngIf="isRequestTraining">
          <ion-spinner name="circles"></ion-spinner></ion-button>

        <ion-button expand="block" type="button" class="w-full pb-4 demo-button" (click)="requestDemonstration()"
          *ngIf="!isRequestDemonstration">
          {{ 'request.demo' | translate }}</ion-button>
        <ion-button expand="block" type="button" class="w-full pb-4 demo-button" disabled="true"
          *ngIf="isRequestDemonstration">
          <ion-spinner name="circles"></ion-spinner></ion-button>
      </section>
      <section *ngIf="relatedContent.length > 0" class="flex direction-col g-3">
        <h4 class="px-4">{{ 'related.content' | translate }}</h4>
        <app-related-content [relatedContent]="relatedContent"></app-related-content>
      </section>
      <div class="border-bottom m-4" *ngIf="instructions.length > 0"></div>
      <section class="flex direction-col g-6 px-4" *ngIf="instructions.length > 0">
        <h4>{{ 'instructions' | translate }}</h4>
        <div class="flex g-3 items-center" *ngFor="let instruction of instructions">
          <img class="file-type" src="../../../assets/img/types/{{ instruction.extension }}.svg">
          <div class="flex g-3 items-center w-full justify-between">
            <p class="instruction-title">{{ instruction.title }}</p>
            <button class="download-button" (click)="downloadInstruction(instruction)">
              <img src="../../../assets/icons/download_FILL0_wght400_GRAD0_opsz24.svg">
            </button>
          </div>
        </div>
      </section>
    </section>
  </div>
</ion-content>
<ion-content *ngIf="!isLoaded">
  <header class="px-4 product pt-8">
    <div class="w-full flex items-center">
      <app-back-button></app-back-button>
      <div class="page-title">
        <p class="m-0">{{ 'product.detail' | translate }}</p>
      </div>
    </div>
  </header>
  <div class="card-detail pt-5 flex direction-col g-4">
    <swiper-container #productSwiper [pagination]="true">
      <swiper-slide class="flex justify-center">
        <ion-skeleton-text [animated]="true"
          style="width: 93%; min-height: 220px; border-radius: 12px;"></ion-skeleton-text>
      </swiper-slide>
    </swiper-container>
    <section class="pb-9">
      <section class="px-4">
        <ion-skeleton-text [animated]="true"
          style="width: 50%; min-height: 28px; border-radius: 4px; margin-bottom: 12px;"></ion-skeleton-text>
        <app-action-buttons (onAddtoFavorites)="addToFavorites($event)" [isFavorite]="false"
          [disabled]="true"></app-action-buttons>
        <div class="product-registry flex g-3 items-center py-3">
          <ion-skeleton-text [animated]="true"
            style="width: 80%; min-height: 22px; border-radius: 4px; margin-bottom: 12px;"></ion-skeleton-text>
        </div>
        <ion-skeleton-text [animated]="true"
          style="width: 100%; height: 16px; border-radius: 4px; margin-bottom: 2px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 100%; height: 16px; border-radius: 4px; margin-bottom: 2px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 100%; height: 16px; border-radius: 4px; margin-bottom: 2px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 100%; height: 16px; border-radius: 4px; margin-bottom: 2px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 100%; height: 16px; border-radius: 4px; margin-bottom: 2px;"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 100%; height: 16px; border-radius: 4px; margin-bottom: 2px;"></ion-skeleton-text>
        <ion-button expand="block" type="button" class="w-full py-4 purchase-button" disabled="true">
          {{ 'training.request' | translate }}</ion-button>
        <ion-button expand="block" type="button" class="w-full pb-4 demo-button" disabled="true">
          {{ 'request.demo' | translate }}</ion-button>
      </section>
      <section class="flex direction-col g-3">
        <ion-skeleton-text [animated]="true"
          style="width: 70%; height: 26px; border-radius: 4px; margin: 12px"></ion-skeleton-text>
        <ion-skeleton-text [animated]="true"
          style="width: 93%; height: 170px; border-radius: 8px; margin-left: 12px;"></ion-skeleton-text>
      </section>
    </section>
  </div>
</ion-content>
