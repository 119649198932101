import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafeHtmlPipe } from './safe-html/safe-html.pipe';
import { SafeUrlPipe } from './safe-url/safe-url.pipe';
import { FilterByNamePipe } from './filter-by-name/filter-by-name.pipe';
import { FilterByUserInterestPipe } from './filter-by-user-interest/filter-by-user-interest.pipe';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeUrlPipe,
    FilterByNamePipe,
    FilterByUserInterestPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafeHtmlPipe,
    SafeUrlPipe,
    FilterByNamePipe,
    FilterByUserInterestPipe
  ]
})

export class PipesModule { }
