import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { ProductsListComponent } from './products-list/products-list.component';
import { ComponentsModule } from '../components/components.module';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CategoriesListComponent } from './categories-list/categories-list.component';
import { SubcategoriesListComponent } from './subcategories-list/subcategories-list.component';
import { ProductsSearchComponent } from './products-search/products-search.component';
import { PipesModule } from "../pipes/pipes.module";

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'categories' },
  {
    path: 'list',
    component: ProductsListComponent,
  },
  {
    path: 'search',
    component: ProductsSearchComponent,
  },
  {
    path: 'categories',
    component: CategoriesListComponent,
  },
  {
    path: 'subcategories',
    component: SubcategoriesListComponent,
  },
  {
    path: 'detail/:id',
    component: ProductDetailComponent
  }
]

@NgModule({
  declarations: [
    ProductsListComponent,
    ProductDetailComponent,
    CategoriesListComponent,
    SubcategoriesListComponent,
    ProductsSearchComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    IonicModule,
    ComponentsModule,
    TranslateModule,
    PipesModule
  ]
})
export class ProductsModule { }
