<nav class="flex w-full" [ngStyle]="iosTab">
  <ul class="flex w-full justify-around">
    <li class="nav-tab">
      <button data-test="btn" routerLink="/home" [routerLinkActive]="activeSvgClass">
        <img data-test="img" [ngClass]="{'svg-dark-mode': darkMode}"
          src="../../../assets/icons/home_FILL0_wght400_GRAD0_opsz24.svg">
        <p class="tab-name color-neutral-20">{{ 'home' | translate }}</p>
      </button>
    </li>
    <li class="nav-tab">
      <button data-test="btn" routerLink="/products" [routerLinkActive]="activeSvgClass">
        <img data-test="img" [ngClass]="{'svg-dark-mode': darkMode}"
          src="../../../assets/icons/list_alt_FILL0_wght400_GRAD0_opsz24.svg">
        <p class="tab-name color-neutral-20">{{ 'products' | translate }}</p>
      </button>
    </li>
    <li class="nav-tab">
      <button data-test="btn" routerLink="/events" [routerLinkActive]="activeSvgClass">
        <img data-test="img" [ngClass]="{'svg-dark-mode': darkMode}"
          src="../../../assets/icons/calendar_month_FILL0_wght400_GRAD0_opsz24.svg">
        <p class="tab-name color-neutral-20">{{ 'events' | translate }}</p>
      </button>
    </li>
    <li class="nav-tab">
      <button data-test="btn" routerLink="/favorites" [routerLinkActive]="activeSvgClass">
        <img data-test="img" [ngClass]="{'svg-dark-mode': darkMode}"
          src="../../../assets/icons/star_FILL0_wght400_GRAD0_opsz24.svg">
        <p class="tab-name color-neutral-20">{{ 'favorites' | translate }}</p>
      </button>
    </li>
  </ul>
</nav>
