<ion-header class="ion-no-border" [ngClass]="{'ios': isIosDevice}">
  <ion-toolbar class="buttons" mode="md">
    <ion-title data-test="ttl" class="color-neutral-20 modal-title">{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button data-test="btn" (click)="cancelChanges()" class="color-sec-50 close-modal">{{ 'close' | translate
        }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar>
    <ion-searchbar data-test="inp" mode="ios" (ionInput)="searchbarInput($event)" [placeholder]="'search' | translate"
      inputmode="search"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-list data-test="lst" [inset]="true" *ngIf="!noResultsSearch && filteredItems.length < 100"
    class="ion-no-padding">
    <ion-item data-test="itm" slot="start" *ngFor="let item of filteredItems; trackBy: trackItems"
      class="ion-no-padding">
      <ion-checkbox data-test="cbx" labelPlacement="end" justify="start" [value]="item" [checked]="isChecked(item)"
        (ionChange)="checkboxChange($event)" *ngIf="type !== 'city'">{{ item.countryTitle ?? item.title
        }}</ion-checkbox>
      <ion-checkbox data-test="cbx" labelPlacement="end" justify="start" [value]="item" [checked]="isChecked(item)"
        (ionChange)="checkboxChange($event)" *ngIf="type === 'city'">{{ item.cityTitle }} - {{ item.stateShort
        }}</ion-checkbox>
    </ion-item>
  </ion-list>
  <app-no-results-card *ngIf="noResultsSearch"></app-no-results-card>
</ion-content>

<ion-footer class="ion-no-border">
  <ion-button data-test="btn" expand="block" type="submit" class="w-full pt-3 pb-7 px-6 confirm-button"
    (click)="submit()">{{ 'confirm' |
    translate }}</ion-button>
</ion-footer>
