<div class="month-year-picker">
  <div class="picker-container">
    <div class="month-picker">
      <button class="calendar-left-arrow" (click)="prevMonth()">
        <img src="../../../assets/icons/arrow_back_ios_FILL0_wght400_GRAD0_opsz24.svg">
      </button>
      <select [(ngModel)]="selectedMonth">
        <option *ngFor="let month of months; let i = index" [value]="i">{{ month }}</option>
      </select>
      <button class="calendar-right-arrow" (click)="nextMonth()">
        <img src="../../../assets/icons/arrow_forward_ios_FILL0_wght400_GRAD0_opsz24.svg">
      </button>
    </div>
    <div class="year-picker">
      <button class="calendar-left-arrow" (click)="prevYear()">
        <img src="../../../assets/icons/arrow_back_ios_FILL0_wght400_GRAD0_opsz24.svg">
      </button>
      <select [(ngModel)]="selectedYear" class="m-3">
        <option *ngFor="let year of years" [value]="year">{{ year }}</option>
      </select>
      <button class="calendar-right-arrow" (click)="nextYear()">
        <img src="../../../assets/icons/arrow_forward_ios_FILL0_wght400_GRAD0_opsz24.svg">
      </button>
    </div>
    <button (click)="confirmSelection()">{{ 'go' | translate }}</button>
  </div>
</div>
