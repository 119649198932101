import { NREnums } from "@newrelic/newrelic-capacitor-plugin";

export enum ENetworkFailure {
  UNKNOWN = NREnums.NetworkFailure.Unknown,
  BAD_RESPONSE = NREnums.NetworkFailure.BadServerResponse,
  BAD_REQUEST = NREnums.NetworkFailure.BadURL,
  CANNOT_HOST = NREnums.NetworkFailure.CannotConnectToHost,
  DNS_FAILED = NREnums.NetworkFailure.DNSLookupFailed,
  SECURE_FAILED = NREnums.NetworkFailure.SecureConnectionFailed,
  TIMEOUT = NREnums.NetworkFailure.TimedOut
}
