import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { App } from '@capacitor/app';

import { TranslateConfigService } from './services/translate-config/translate-config.service';
import { UserAgentService } from './services/user-agent/user-agent.service';
import { NavigationHistoryService } from './services/navigation/navigation-history.service';
import { slider } from './route-animations';
import { CacheService } from './services/cache/cache.service';
import { filter } from 'rxjs/operators';
import { AuthService } from './services/ws-user/auth/auth.service';
import { MeService } from './services/ws-user/me/me.service';
import { ThemeService } from './services/theme/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slider
  ]
})
export class AppComponent implements OnInit {
  title = 'app-mobile';
  isLoggedIn = false;
  iosMainStyle!: any;
  iosTabStyle = {};
  user!: any;

  prepareRoute(outlet: RouterOutlet) {
    return outlet?.activatedRouteData?.['animation'];
  }

  constructor(
    public router: Router,
    private translateConfigService: TranslateConfigService,
    private userAgent: UserAgentService,
    private navigationHistoryService: NavigationHistoryService,
    private cache: CacheService,
    private themeService: ThemeService,
    private meService: MeService, private authService: AuthService) {
    this.translateConfigService.initLanguage();
    this.userAgent.setUserAgent();
  }

  ngOnInit(): void {
    this.getMe();
    App.addListener('backButton', () => {
      if (this.navigationHistoryService.hasPreviousUrl()) {
        this.navigationHistoryService.navigateBack();
      } else {
        App.exitApp();
      }
    });

    if (/ios/.test(this.userAgent.userAgent)) {
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.iosMainStyle = this.styleMainIos();
        this.iosTabStyle = { 'margin-bottom': '8px' };
      });
    }
  }

  private getMe() {
    if (localStorage.getItem('jwtToken')) {
      this.meService.getMeCache().subscribe();
      this.meService.meSubject$.subscribe({
        next: (r) => {
          this.user = r.data;
          this.themeService.setTheme(this.user.theme);
          localStorage.setItem('mode', this.user.theme);
        }
      });
    } else {
      this.authService.getLoginObservable().subscribe({
        next: () => {
          this.meService.meSubject$.subscribe({
            next: (r) => {
              this.user = r.data;
              this.themeService.setTheme(this.user.theme);
              localStorage.setItem('mode', this.user.theme);
            }
          });
        }
      });

      this.themeService.setThemeWindow();
    }
  }

  styleMainIos(): any {
    return {
      'background': 'linear-gradient(to right, var(--color-blue-50) 29.01%, var(--color-ocean-50) 100%)',
      'padding-top': '50px'
    };
  }

  // const appUrl = 'seu-esquema-de-url://pagina';
  // const fallbackUrl = 'https://itunes.apple.com/us/app/seu-nome-do-aplicativo/id1234567890?ls=1&mt=8';
  //PARECE Q FUNCIONA, TESTAR EM MOBILE
  // const agent = localStorage.getItem('userAgent');
  // if (window.location.href.indexOf('http') === -1) {
  //   console.log('tem o app instalado');
  //   const appUrl = 'seu-esquema-de-url://pagina';
  //   window.location.href = appUrl;
  // } else {
  //   if (agent === 'android') {
  //     console.log('nao tem o app instalado');
  //     window.location.href = 'https://play.google.com/store/apps/details?id=seu_id_do_aplicativo';
  //   } else {
  //     window.location.href = 'https://itunes.apple.com/us/app/seu-nome-do-aplicativo/id1234567890?ls=1&mt=8';

  //   }
  // }

  handleRefresh(event: any) {
    this.cache.clearAll();
    event.target.complete();
  }

}
