import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarComponent implements OnInit {

  @Input() highlightDates: any = [];
  @Input() isInput = false;
  @Output() onSelectDay = new EventEmitter();
  monthArray = [
    this.translate.instant('month.january'),
    this.translate.instant('month.february'),
    this.translate.instant('month.march'),
    this.translate.instant('month.april'),
    this.translate.instant('month.may'),
    this.translate.instant('month.june'),
    this.translate.instant('month.july'),
    this.translate.instant('month.august'),
    this.translate.instant('month.september'),
    this.translate.instant('month.october'),
    this.translate.instant('month.november'),
    this.translate.instant('month.december')
  ];
  today = new Date();
  todaysDate = this.today.getDate();
  currentYear = this.today.getFullYear();
  currentMonth = this.today.getMonth();
  showMonthYearPicker = false;
  initialMonth!: number;
  initialYear!: number;
  selectedDay: number | null = null;
  previousSelectedElement: HTMLElement | null = null;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.isInput && this.highlightDates[0] !== null) {
      const highlightDate = new Date(this.highlightDates[0]);
      this.currentYear = highlightDate.getFullYear();
      this.currentMonth = highlightDate.getMonth();
      this.selectedDay = highlightDate.getDate();
      this.generateCalendarDays(highlightDate);
    } else {
      this.generateCalendarDays(this.today);
    }
    this.initialYear = this.currentYear;
    this.initialMonth = this.currentMonth;
  }

  goLeftOrRight(offset: number): void {
    const monthYear = document.getElementsByClassName("calendar-month-year")[0];
    const month = parseInt(monthYear.getAttribute("data-month") || '0', 10);
    const year = parseInt(monthYear.getAttribute("data-year") || '0', 10);

    const newMonth = (month + offset + 12) % 12;
    const newYear = year + Math.floor((month + offset) / 12);

    this.currentMonth = newMonth;
    this.currentYear = newYear;
    const newDate = new Date(newYear, newMonth, 1);
    this.generateCalendarDays(newDate);
  }

  goLeft(): void {
    this.goLeftOrRight(-1);
  }

  goRight(): void {
    this.goLeftOrRight(1);
  }

  generateCalendarDays(currentDate: Date): void {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const totalDaysInMonth = this.getTotalDaysInAMonth(year, month);
    const firstDayOfWeek = this.getFirstDayOfWeek(year, month);
    const calendarDays = document.getElementsByClassName("calendar-days")[0];

    this.removeAllChildren(calendarDays);

    for (let i = 1; i <= firstDayOfWeek; i++) {
      let li = document.createElement("li");
      li.classList.add("calendar-day");
      calendarDays.appendChild(li);
    }

    for (let day = 1; day <= totalDaysInMonth; day++) {
      let li = document.createElement("li");
      li.textContent = day.toString();
      li.classList.add("calendar-day");
      li.setAttribute("data-day", day.toString());
      li.addEventListener('click', () => this.handleDayClick(day, li));

      if (this.isInput && this.highlightDates[0] !== null) {
        const highlightDate = new Date(this.highlightDates[0]);
        if (
          (highlightDate.getDate() === day &&
            highlightDate.getMonth() === month &&
            highlightDate.getFullYear() === year) ||
          (this.selectedDay === day && this.currentMonth === month && this.currentYear === year)) {
          // li.classList.add("calendar-day-active");
          this.previousSelectedElement = li;
        }
      } else
        if (this.todaysDate === day && this.today.getMonth() === month && this.today.getFullYear() === year) {
          li.classList.add("calendar-day-active");
        }

      calendarDays.appendChild(li);
    }

    const monthYear = document.getElementsByClassName("calendar-month-year")[0];
    monthYear.setAttribute("data-month", month.toString());
    monthYear.setAttribute("data-year", year.toString());

    if (this.highlightDates[0] !== null) {
      this.highlightDates.forEach((d: any) => {
        const dateStart = new Date(`${d.dateStart}T00:00:00`);
        const dateFinish = new Date(`${d.dateFinish}T00:00:00`);
        const isMultiDayConference = dateStart < dateFinish;

        if (dateStart.getMonth() !== month || dateStart.getFullYear() !== year) {
          return;
        }

        if (isMultiDayConference) {
          for (let currentDate = dateStart; currentDate <= dateFinish; currentDate.setDate(currentDate.getDate() + 1)) {
            const dayElement = calendarDays.querySelector(`li[data-day="${currentDate.getDate()}"]`);
            if (dayElement) {
              let underline = document.createElement("div");
              underline.classList.add(`calendar-day-active-full-${d.type}`);
              dayElement.appendChild(underline);
            }
          }

          return;
        }

        const dayElement = calendarDays.querySelector(`li[data-day="${dateStart.getDate()}"]`);
        if (dayElement) {
          let underline = document.createElement("div");
          underline.classList.add(`calendar-day-active-${d.type}`);
          dayElement.appendChild(underline);
        }
      });

    }
  }

  getTotalDaysInAMonth(year: number, month: number): number {
    return new Date(year, month + 1, 0).getDate();
  }

  getFirstDayOfWeek(year: number, month: number): number {
    return new Date(year, month, 1).getDay();
  }

  removeAllChildren(parent: Element): void {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  handleDayClick(day: number, element: HTMLElement): void | Date {
    if (this.showMonthYearPicker) {
      return;
    }
    const year = this.currentYear;
    const month = this.currentMonth;

    if (this.isInput) {
      if (this.previousSelectedElement) {
        this.previousSelectedElement.classList.remove("calendar-day-active");
      }
      this.selectedDay = day
      element.classList.add("calendar-day-active");
      this.previousSelectedElement = element;
      this.onSelectDay.emit(new Date(year, month, day));

      return;
    }
    let conferencesOnDay = [];

    conferencesOnDay = this.highlightDates.filter((conference: any) => {
      const dateStart = new Date(`${conference.dateStart}T00:00:00`);
      const dateFinish = new Date(`${conference.dateFinish}T00:00:00`);
      const isMultiDayConference = dateStart < dateFinish;

      if (isMultiDayConference) {
        return dateStart <= new Date(year, month, day) && dateFinish >= new Date(year, month, day);
      } else {
        return dateStart.getFullYear() === year && dateStart.getMonth() === month && dateStart.getDate() === day;
      }
    });

    if (conferencesOnDay.length > 0) {
      this.onSelectDay.emit(conferencesOnDay);
    }
  }

  toggleMonthYearPicker() {
    this.showMonthYearPicker = !this.showMonthYearPicker;
  }

  onDateSelected(event: { month: number, year: number }) {
    this.currentMonth = event.month;
    this.currentYear = event.year;
    this.initialMonth = event.month;
    this.initialYear = event.year;
    const newDate = new Date(event.year, event.month, 1);
    this.generateCalendarDays(newDate);
    this.showMonthYearPicker = false;
  }
}
