import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CacheService } from '../../cache/cache.service';

const URL_PRODUCTS = `${environment.product}/product`;
const URL_FAVORITES = `${environment.product}/favorite`;

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private cache: CacheService) { }

  getProducts() {
    return this.http.get<any>(URL_PRODUCTS, { withCredentials: true });
  }

  getProductsByCategory(id: string) {
    return this.http.get<any>(`${URL_PRODUCTS}?Category=${id}`, { withCredentials: true });
  }

  getProductById(id: string) {
    return this.http.get<any>(`${URL_PRODUCTS}/${id}`, { withCredentials: true });
  }

  purchase(productId: any) {
    return this.http.post<any>(`${URL_PRODUCTS}/interested`, { productId: productId }, { withCredentials: true });
  }

  training(productId: any) {
    return this.http.post<any>(`${URL_PRODUCTS}/training`, { productId: productId }, { withCredentials: true });
  }

  addToProductToFavorite(idFavorite: any) {
    this.cache.clearByKey(URL_FAVORITES);
    this.cache.clearByKey(`${URL_PRODUCTS}/${idFavorite}`);
    return this.http.post<any>(URL_FAVORITES, { id: idFavorite }, { withCredentials: true });
  }

  getFavoritedProducts(refresh?: boolean) {
    if (refresh) {
      this.cache.clearByKey(URL_FAVORITES);
    }
    return this.http.get<any>(URL_FAVORITES, { withCredentials: true });
  }

  deleteFavorited(id: string) {
    this.cache.clearByKey(URL_FAVORITES);
    this.cache.clearByKey(`${URL_PRODUCTS}/${id}`);
    return this.http.delete<any>(`${URL_FAVORITES}/${id}`, { withCredentials: true });
  }

  getLinkedProducts(contentId: string): Observable<any> {
    return this.http.get<any>(`${URL_PRODUCTS}/content/${contentId}`, { withCredentials: true });
  }
}
