import { Injectable } from '@angular/core';

import { ToastService } from '../toast/toast.service';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';

import { TranslateService } from '@ngx-translate/core';
import { Directory, DownloadFileResult, Filesystem } from '@capacitor/filesystem';
@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  mime: { [key: string]: string } = {
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'image/gif',
    'text': 'plain/text',
    'pdf': 'application/pdf',
    'json': 'application/json',
    'msword': 'application/msword',
    'vnd.ms-powerpoint': 'application/vnd.ms-powerpoint',
    'mp4': 'video/mp4',
    'webm': 'video/webm'
  }

  constructor(private toastService: ToastService, private translate: TranslateService) { }

  async downloadFile(downloadObject: any) {
    const { extension, file, title } = downloadObject;
    const response = await fetch(file);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise<void>((resolve, reject) => {
      reader.onloadend = async () => {
        const hash = this.generateHash();
        const fileName = `${title}_${hash}.${extension}`;
        try {
          const res = await Filesystem.downloadFile({
            path: fileName,
            url: file,
            directory: Directory.Documents,
          });
          this._openFileWithType(res.path!);
          this.toastService.displayToast(this.translate.instant('file.downloading'), 'success', { position: 'top' });
          resolve();
        } catch (error) {
          const errorMessage = typeof error === 'string' ? error : (error as Error).message;
          this.toastService.displayToast(errorMessage, 'error');
          reject(error);
        }
      };
    });
  }

  private async _openFileWithType(filePath: string) {
    await FileOpener.openFile({
      path: filePath
    });
  }

  generateHash(): string {
    const randomString = Math.random().toString(36).substring(2, 10);
    let hashValue = 0;
    for (let i = 0; i < randomString.length; i++) {
      hashValue += randomString.charCodeAt(i);
    }
    const hash = hashValue % 100000000;
    return hash.toString().padStart(8, '0');
  }
}
