import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs';

import packageJson from '../../../../package.json';
import { AuthService } from 'src/app/services/ws-user/auth/auth.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { AuthResponse } from 'src/app/models';
import { CacheService } from 'src/app/services/cache/cache.service';
import { ThemeService } from 'src/app/services/theme/theme.service';

@Component({
  selector: 'app-login',
  templateUrl: './initial.component.html',
  styleUrls: ['./initial.component.scss']
})
export class InitialComponent implements OnInit {

  form!: FormGroup;
  showPassword = false;
  logged = false;
  keepLoggedIn = false;
  awaitingResponse = false;
  appVersion!: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private tokenStorage: TokenStorageService,
    private cache: CacheService,
    private toastService: ToastService,
    private translate: TranslateService,
    public themeService: ThemeService,
    private translateConfigService: TranslateConfigService) { }

  ngOnInit(): void {
    this.tokenStorage.removeTokens();
    this.cache.clearAll();
    this.appVersion = packageJson.version;
    this.route.queryParams.subscribe(params => {
      if (params['registered'] !== undefined && params['registered'] === 'true') {
        this.toastService.displayToast(this.translate.instant('account.created'), 'success');
      }
    });
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      email: new FormControl(
        {
          value: null,
          disabled: false,
        },
        Validators.compose(
          [
            Validators.pattern('^[a-zA-Z0-9]+[a-zA-Z0-9_.-]*[a-zA-Z0-9]+@[a-zA-Z0-9]+[a-zA-Z0-9.-]*[a-zA-Z0-9]+\.[a-zA-Z0-9]+[a-zA-Z0-9.]*[a-zA-Z0-9]$'),
            Validators.minLength(5),
            Validators.maxLength(100)
          ]
        )),
      password: new FormControl(
        {
          value: null,
          disabled: false
        },
        [
          Validators.required,
          Validators.minLength(7),
          Validators.maxLength(100)
        ]),
      keepLoggedIn: new FormControl({
        value: this.keepLoggedIn,
        disabled: false
      }),
    });
  }

  isValid(str: string) {
    return !!this.form.controls[str].errors && !!this.form.controls[str].touched;
  }

  onSubmit() {
    this.awaitingResponse = true;

    this.authService
      .login(this.form.getRawValue())
      .pipe(finalize(() => this.awaitingResponse = false))
      .subscribe({
        next: (res: AuthResponse) => {
          this.tokenStorage.setTokens(res.data);
          this.translateConfigService.setLanguage(res.data.languageCode);
          this.router.navigate(['/'], { queryParams: { loggedIn: 'true' } });
        },
        error: (err) => this.toastService.displayToast(err.error?.errors, 'error')
      });
  }
}
