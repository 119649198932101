<ion-content>
  <ion-refresher slot="fixed" [pullMin]="100" [pullMax]="200" (ionRefresh)="handleRefresh($event)">
    <ion-refresher-content refreshingSpinner="circles"></ion-refresher-content>
  </ion-refresher>
  <div class="flex">
    <div class="main" @routeAnimations [ngClass]="router.url.includes('/login') ? 'h-full-view' : 'h-menu'"
      [ngStyle]="iosMainStyle">
      <router-outlet></router-outlet>
    </div>
    <app-tabs [iosTab]="iosTabStyle"
      *ngIf="!router.url.includes('/login') && !router.url.includes('/profile') && !router.url.includes('/web-email')"
      class="flex justify-around items-center"></app-tabs>
  </div>
</ion-content>
