<div class="flex direction-col location-container w-full" id="area">
  <div class="flex items-center w-full">
    <img data-test="img" src="../../../assets/icons/filter_list_FILL0_wght400_GRAD0_opsz24.svg" class="pe-3">
    <div class="w-full flex direction-col pt-3">
      <p class="title-label" *ngIf="selectedItems.length <= 0"
      [ngClass]="'selectedItems > 0' ? 'aws' : 'mb-0'">{{ title }}</p>
      <button data-test="btn" fill="clear" size="small" class="w-full flex" slot="start">
        <span *ngIf="selectedItems.length <= 0" class="input my-4">{{ 'filter.content.select' | translate }}</span>
        <span class="title-label with-countries" *ngIf="selectedItems.length > 0">{{ title }}</span>
        <img slot="end" class="pb-2" src="../../../assets/icons/add_FILL0_wght400_GRAD0_opsz24.svg">
      </button>
    </div>
  </div>
  <ion-modal trigger="area" #modal data-test="mdl">
    <ng-template>
      <app-typeahead class="ion-page" [type]="type" [items]="data" [selectedItems]="selectedItems" [title]="title"
        (selectionChange)="selectionChanged($event)" (selectionCancel)="modal.dismiss()"></app-typeahead>
    </ng-template>
  </ion-modal>
  <section class="flex justify-between" *ngIf="selectedItems.length > 0">
    <div class="flex wrap g-3 chip-container">
      <ion-chip *ngFor="let item of selectedItems" data-test="chp">
        <ion-label>{{ item.title }}</ion-label>
        <ion-icon data-test="ico" (click)="$event.stopPropagation(); removeItem(item)" name="close"></ion-icon>
      </ion-chip>
    </div>
    <img data-test="img" slot="end" class="pb-2"
      src="../../../assets/icons/chevron_right_FILL0_wght400_GRAD0_opsz24.svg">
  </section>
</div>
