import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, shareReplay } from 'rxjs';

const URL = `${environment.common}/location`;

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private countryCache$?: Observable<any>;

  constructor(private http: HttpClient) { }

  getCountries(): Observable<any> {
    if (!this.countryCache$) {
      this.countryCache$ = this.requestCountries().pipe(
        shareReplay(1)
      );
    }
    return this.countryCache$;
  }

  private requestCountries(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'X-Requested-With'
    })

    return this.http.get<any>(`${URL}/country?PageSize=0`, { headers });
  }

  getCities(countryId: string) {
    return this.http.get<any>(`${URL}/country/${countryId}/location`);
  }

  getInfoByCityId(cityId: string) {
    return this.http.get<any>(`${URL}/location/${cityId}`);
  }
}
