<ion-content *ngIf="isLoaded">
  <div class="search-header flex items-center pt-9 px-4">
    <app-back-button [buttonType]="'black'"></app-back-button>
    <ion-searchbar class="p-0 custom" mode="ios" inputmode="search" [placeholder]="'search' | translate" [value]="query"
      (ionInput)="filter($event)" [debounce]="500"></ion-searchbar>
  </div>
  <ion-accordion-group class="pb-5" [multiple]="true" [value]="['products', 'categories', 'procedures']">

    <ion-accordion value="products" toggleIconSlot="start" *ngIf="(products | filterByName: query).length > 0">
      <ion-item slot="header" lines="none">
        <ion-label class="accordion-name ps-3">{{ 'products' | translate }}</ion-label>
      </ion-item>
      <div slot="content">
        <app-slider (onGotoDetail)="gotoDetails($event)" [data]="products | filterByName: query" type="products"
          [query]="query"></app-slider>
      </div>
    </ion-accordion>

    <ion-accordion value="categories" toggleIconSlot="start" *ngIf="(categories | filterByName: query).length > 0">
      <ion-item slot="header" lines="none">
        <ion-label class="accordion-name ps-3">{{ 'categories' | translate }}</ion-label>
      </ion-item>
      <div slot="content">
        <ion-list *ngFor="let cat of categories | filterByName: query" class="ion-no-padding">
          <ion-item class="list-item" (click)="gotoDetailsCategory(cat)">{{ cat.parentName }} - {{ cat.title
            }}</ion-item>
        </ion-list>
      </div>
    </ion-accordion>
    <ion-accordion value="procedures" toggleIconSlot="start" *ngIf="(procedures | filterByName: query).length > 0">
      <ion-item slot="header" lines="none">
        <ion-label class="accordion-name ps-3">{{ 'procedures' | translate }}</ion-label>
      </ion-item>
      <div slot="content">
        <ion-list *ngFor="let proc of procedures | filterByName: query">
          <ion-item class="list-item" (click)="gotoDetailsCategory(proc)">{{ proc.parentName }} - {{ proc.title
            }}</ion-item>
        </ion-list>
      </div>
    </ion-accordion>
  </ion-accordion-group>
  <section class="px-4"
    *ngIf="(categories | filterByName: query).length === 0 && (products | filterByName: query).length === 0">
    <app-no-results-card></app-no-results-card>
  </section>
</ion-content>
