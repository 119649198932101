import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss']
})
export class SegmentComponent {
  currentTab = 'subcategories';
  @Output() onSelectSub: EventEmitter<any> = new EventEmitter<any>();
  @Input() subcategories: any[] = [];

  @Input() procedures: any[] = [];

  onChangeTab(tab: any) {
    this.currentTab = tab.target.value;
  }

  selectSub(name: any) {
    this.onSelectSub.emit(name);
  }
}
