import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss']
})
export class TypeaheadComponent {
  @Input() items: any[] = [];
  @Input() selectedItems: string[] = [];
  @Input() title = '';
  @Input() type = '';

  @Output() selectionCancel = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<string[]>();

  filteredItems: any[] = [];
  workingSelectedValues: string[] = [];
  noResultsSearch = false;
  isIosDevice = false;

  ngOnInit() {
    this.filteredItems = [...this.items];
    // this.workingSelectedValues = [...this.selectedItems];
    this.selectedItems.forEach((obj: any) => {
      const match = this.items.find(obj2 => obj.id === obj2.id);
      if (match) {
        this.workingSelectedValues.push(match);
      }
    })
  }

  trackItems(index: number, item: any) {
    return item;
  }

  cancelChanges() {
    this.selectionCancel.emit();
  }

  confirmChanges() {
    this.selectionChange.emit(this.workingSelectedValues);
    this.selectionCancel.emit();
  }

  searchbarInput(ev: any) {
    this.filterList(ev.target.value);
  }

  filterList(searchQuery: string | undefined) {
    this.noResultsSearch = false;
    if (searchQuery === undefined) {
      this.filteredItems = [...this.items];
    } else {
      const normalizedQuery = this.normalize(searchQuery);
      this.filteredItems = this.items.filter((item) => {
        if (this.type === 'city') {
          return this.normalize(item.cityTitle).includes(normalizedQuery);
        } else if (this.type === 'country') {
          return this.normalize(item.countryTitle).includes(normalizedQuery);
        } else {
          return this.normalize(item.title).includes(normalizedQuery);
        }
      });
    }
    if (!this.filteredItems.length) {
      this.noResultsSearch = true;
    }
  }

  private normalize(text: string): string {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .toLowerCase();
  }

  submit() {
    this.selectionChange.emit(this.workingSelectedValues);
    this.selectionCancel.emit();
  }

  isChecked(value: string) {
    return this.workingSelectedValues.find((item) => item === value);
  }

  checkboxChange(ev: any) {
    const { checked, value } = ev.detail;
    if (checked) {
      this.workingSelectedValues = [...this.workingSelectedValues, value];
    } else {
      this.workingSelectedValues = this.workingSelectedValues.filter((item) => item !== value);
    }
  }
}
