import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  darkTheme = false;
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.darkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  getDarkTheme(): string {
    return this.darkTheme ? 'dark' : 'light';
  }

  getTheme() {
    return localStorage.getItem('mode');
  }

  setTheme(theme: string) {
    this.renderer.setAttribute(document.body, 'data-theme', theme);
  }
  setThemeWindow() {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      localStorage.setItem('mode', 'dark');
      this.renderer.setAttribute(document.body, 'data-theme', 'dark');
    } else {
      localStorage.setItem('mode', 'light');
      this.renderer.setAttribute(document.body, 'data-theme', 'light');
    }
  }
}
