import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  @Input() iosTab = {};
  darkMode = false;
  activeSvgClass = 'active-tab';

  ngOnInit(): void {
    this.darkMode = localStorage.getItem('mode') === 'dark';
    this.darkMode ? this.activeSvgClass = 'active-tab-dark' : this.activeSvgClass = 'active-tab';
  }

}
