import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Share } from '@capacitor/share';
import { TranslateService } from '@ngx-translate/core';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent implements OnInit {
  @Input() disabled = false;
  @Input() itemToShare!: any;
  @Output() onAddtoFavorites = new EventEmitter();
  @Input() isFavorite: boolean = false;
  darkMode = false;

  constructor(private router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    this.darkMode = localStorage.getItem('mode') === 'dark';
  }

  addtoFavorites() {
    this.isFavorite = !this.isFavorite;
    this.onAddtoFavorites.emit(this.isFavorite);
  }

  async toShare() {
    // const shareUrl = 'https://seu-site.com/pagina-compartilhada';
    // const appUrl = `seu-esquema-de-url://pagina?shareUrl=${encodeURIComponent(shareUrl)}`;
    const cleanUrl = this.router.url.startsWith('/') ? this.router.url.substring(1) : this.router.url;
    const deepLink = `${environment.baseUrl}${cleanUrl}`;

    const shareData = {
      // title: ,
      text: this.itemToShare.title + ' ' + this.translate.instant('share.sintegra'),
      dialogTitle: this.itemToShare.title + ' ' + this.translate.instant('share.sintegra'),
      url: deepLink,
    };

    try {
      await Share.share(shareData);
    } catch (err) {
      console.error('Error sharing URL:', JSON.stringify(err, null, 2));
    }
  }
}
