<div class="clean-input flex items-center" [ngClass]="valid ? 'color-red-50' : 'color-neutral-90'">
  <img data-test="img" *ngIf="hasIcon" [src]="iconPath" class="pe-3"
    [ngClass]="valid ? 'filter-invalid' : 'color-neutral-90'">
  <div class="input-container w-full">
    <div class="flex direction-col w-full">
      <label data-test="lbl" class="label" [ngClass]="valid ? 'color-red-50' : ''">
        {{ label }}
        <ion-text data-test="txt" *ngIf="optional">{{ 'optional' | translate }}</ion-text>
      </label>
      <input data-test="inp" [type]="type" class="input" #input [placeholder]="placeholder" *ngIf="type !== 'phone'"
        (input)="inputChange($event)" [readOnly]="readonly" [value]="value">
      <input data-test="inp" type="tel" class="input" #input [placeholder]="placeholder" *ngIf="type === 'phone'"
        (input)="inputChange($event)" [readOnly]="readonly" [value]="value" phone>
    </div>
    <img data-test="img" class="eye-icon" (click)="exhibitPass()" [ngClass]="hidePass ? 'show-password':'show-password'"
      src="../../../assets/icons/visibility_off_FILL0_wght400_GRAD0_opsz24.svg"
      *ngIf="(type === 'password' && hidePass)">
    <img data-test="img" class="eye-icon" (click)="exhibitPass()" [ngClass]="hidePass ? 'show-password':'show-password'"
      src="../../../assets/icons/visibility_FILL0_wght400_GRAD0_opsz24.svg" *ngIf="(type === 'text' && !hidePass)">
  </div>
</div>

<div *ngIf="valid" class="alert">
  {{ invalidInputMsg || ('invalid.input' | translate)}}
</div>
