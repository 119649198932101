import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const LANG_EN_US = 'en-US';
const LANG_ES_ES = 'es-ES';
const LANG_PT_BR = 'pt-BR';
const LANG_STORAGE_KEY = 'lang';

/**
 * Serviço de configuração de idioma para aplicativos Angular usando ngx-translate.
 * Este serviço gerencia as configurações de idioma e tradução do aplicativo.
 */
@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {

  /**
   * O idioma padrão do aplicativo. Inicializado com o idioma padrão pt-BR.
   */
  defaultLanguage: string = LANG_PT_BR;

  /**
   * Lista dos idiomas disponíveis no aplicativo.
   */
  availableLanguages = [LANG_EN_US, LANG_ES_ES, LANG_PT_BR];

  /**
   * Construtor que injeta o TranslateService e inicializa o idioma do aplicativo.
   * @param translate O serviço de tradução ngx-translate.
   */
  constructor(private translate: TranslateService) { }

  /**
   * Inicializa o idioma do aplicativo com base no idioma armazenado ou no idioma do navegador.
   */
  initLanguage(): void {
    const defaultLang = localStorage.getItem(LANG_STORAGE_KEY) ?? this.translate.getBrowserCultureLang() ?? this.defaultLanguage;

    this.setDefaultLanguage(defaultLang);
  }

  /**
   * Define o idioma padrão do aplicativo e o idioma ativo.
   * @param lang O código do idioma a ser definido como padrão.
   */
  setDefaultLanguage(lang: string): void {
    const langPrefix = lang.split('-')[0];
    const defaultLang = this.availableLanguages.find(l => l.startsWith(langPrefix)) ?? this.defaultLanguage;

    this.translate.setDefaultLang(this.defaultLanguage); // Define pt-BR como idioma padrão
    this.setLanguage(defaultLang); // Define o idioma ativo
  }

  /**
   * Define o idioma ativo do aplicativo e atualiza o armazenamento local.
   * @param setLang O código do idioma a ser ativado.
   */
  setLanguage(setLang: string): void {
    if (this.availableLanguages.includes(setLang)) {
      this.translate.use(setLang); // Ativa o idioma escolhido
      localStorage.setItem(LANG_STORAGE_KEY, setLang); // Armazena o idioma escolhido
    }
  }

  /**
   * Obtém o idioma atual do aplicativo do armazenamento local.
   * @returns O código do idioma atual.
   */
  getCurrentLang(): string {
    return localStorage.getItem(LANG_STORAGE_KEY) ?? this.defaultLanguage; // Retorna o idioma atual ou o padrão
  }
}
