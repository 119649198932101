import { Component, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonModal } from '@ionic/angular';
import { forkJoin } from 'rxjs';

import { LocationService } from 'src/app/services/ws-user/location/location.service';

@Component({
  selector: 'app-country-input',
  templateUrl: './country-input.component.html',
  styleUrls: ['./country-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CountryInputComponent),
      multi: true,
    }
  ],
})
export class CountryInputComponent implements OnInit, ControlValueAccessor {
  allCountries: any[] = [];
  allCities: any[] = [];
  @Input() userLocations: any[] = [];
  @ViewChild('modal') modal!: IonModal;
  @ViewChild('cityModal') cityModal!: IonModal;
  userCities: any[] = [];
  userCountries: any[] = [];
  isLoaded = false;
  isLoadedCities = false;

  constructor(private locationService: LocationService) { }

  public _value: any;
  public disabled!: boolean;
  onChanged: any = () => { };
  onTouched: any = () => { };

  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.getAllCountries();
  }

  private getAllCountries() {
    this.locationService.getCountries().subscribe(res => {
      this.allCountries = res.data;
      this.getUserData();
    });
  }

  private getUserData() {
    if (!this.userLocations.length) {
      this.isLoaded = true;
      return;
    }

    const observables = this.userLocations.map((city: any) => this.locationService.getInfoByCityId(city.locationId));
    forkJoin(observables).subscribe((responses: any) => {
      responses.forEach((res: any) => {
        this.userCities.push(res.data);
        this.allCountries.forEach(country => {
          if (country.id === res.data.countryId) {
            if (!this.userCountries.includes(country)) {
              this.userCountries.push(country);
            }
          }
        });
      });
      this.getAllCities();
    });
  }

  private getAllCities() {
    this.isLoadedCities = false;
    this.isLoaded = false;
    this.allCities = [];
    let obs = this.userCountries.map((country: any) => this.locationService.getCities(country.id));
    forkJoin(obs).subscribe((res: any) => {
      res.forEach((obj: any) => {
        this.allCities.push(...obj.data);
      });
      this.isLoadedCities = true;
      this.isLoaded = true;
    });
  }

  selectionCountry(countries: any) {
    let previousSelected = this.userCountries;
    this.userCountries = countries;
    if (JSON.stringify(previousSelected) != JSON.stringify(countries)) {
      this.getAllCities();
      const removedCountries = previousSelected.filter(
        prevCountry => !countries.some((currCountry: any) => currCountry.id === prevCountry.id));
      removedCountries.forEach((c) => this.updateUserCities(c));
    }
    this.modal.dismiss();
  }

  private updateUserCities(country: any) {
    let previousUserCity = [...this.userCities];
    previousUserCity.forEach(city => {
      if (city.countryId === country.id) {

        let index = this.userCities.findIndex(c => c.id == city.id);
        if (index !== -1) {
          this.userCities.splice(index, 1);
        }
      }
    });
    let ids = this.userCities.map(c => c.id);
    this.onTouched();
    this.onChanged(ids);
  }

  selectionCity(cities: any) {
    this.userCities = cities;
    let ids = this.userCities.map(c => c.id);
    this.onTouched();
    this.onChanged(ids);
    this.cityModal.dismiss();
  }

  removeCountry(country: any) {
    this.updateUserCities(country);

    let toRemove = this.userCountries.findIndex(c => c.id == country.id);
    this.userCountries.splice(toRemove, 1);
    let previousSelected = this.allCities;
    this.allCities = [];
    previousSelected.forEach(city => {
      if (city.countryId !== country.id) {
        this.allCities.push(city);
      }
    });
  }

  removeCity(city: any) {
    let toRemove = this.userCities.findIndex(c => c.id == city.id);
    this.userCities.splice(toRemove, 1);

    let ids = this.userCities.map(c => c.id);
    this.onTouched();
    this.onChanged(ids);
  }
}
