import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { LanguageService } from 'src/app/services/language/language.service';
import { RolesService } from 'src/app/services/ws-user/roles/roles.service';
import { SpecializationService } from 'src/app/services/ws-user/specialization/specialization.service';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { UserService } from 'src/app/services/ws-user/user/user.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  form!: FormGroup;
  passwordConfirmation = '';
  passwordMatch = false;
  showPassword = false;
  passwordHasLetter = false;
  passwordHasLength = false;
  passwordHasNumber = false;
  passwordEquals = false;
  isDoc = false;

  agreedToTerms = false;
  acceptMarketing = false;
  acceptTraining = false;
  acceptResearch = false;

  selectedCountries: any[] = [];
  awaitingResponse = false;
  rolesList = [];
  specsList = [];

  constructor(private userService: UserService, private themeService: ThemeService,
    private languageService: LanguageService, private router: Router, private toastService: ToastService,
    private roleService: RolesService, private specService: SpecializationService) { }

  ngOnInit(): void {

    this.createForm();
    this.roleService.getRoles().subscribe(res => { this.rolesList = res.data; });
    this.specService.getSpecialization().subscribe(res => { this.specsList = res.data; });
  }

  createForm() {
    this.form = new FormGroup({
      name: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
      birthDate: new FormControl({ value: null, disabled: false }),
      phone: new FormControl({ value: null, disabled: false }, [Validators.minLength(12), Validators.maxLength(15)]),
      profile: new FormControl({ value: null, disabled: false }, [Validators.required]),
      locations: new FormControl([], [Validators.required, Validators.minLength(1)]),
      email: new FormControl(
        { value: null, disabled: false }, Validators.compose([Validators.pattern('^[a-zA-Z0-9]+[a-zA-Z0-9_.-]*[a-zA-Z0-9]+@[a-zA-Z0-9]+[a-zA-Z0-9.-]*[a-zA-Z0-9]+\.[a-zA-Z0-9]+[a-zA-Z0-9.]*[a-zA-Z0-9]$'),
        Validators.minLength(5), Validators.maxLength(100)])),
      password: new FormControl(
        { value: null, disabled: false }, [Validators.required, Validators.minLength(6), Validators.maxLength(100)]),
      check: new FormControl(
        { value: null, disabled: false }, [Validators.required, Validators.minLength(6), Validators.maxLength(100)]),
      acceptMarketing: new FormControl({ value: false, disabled: false }),
      acceptTraining: new FormControl({ value: false, disabled: false }),
      acceptResearch: new FormControl({ value: false, disabled: false }),
      acceptEmail: new FormControl({ value: false, disabled: true }),
      acceptPhone: new FormControl({ value: false, disabled: true }),
      acceptTerm: new FormControl({ value: this.agreedToTerms, disabled: false }, [Validators.requiredTrue]),
      theme: new FormControl({ value: this.themeService.getDarkTheme(), disabled: false }),
      languageCode: new FormControl({ value: this.languageService.getUsersLocale(), disabled: false }),
    });
  }

  get locations() {
    return this.form.controls['locations'];
  }
  get password() {
    return this.form.controls['password'];
  }
  get check() {
    return this.form.controls['check'];
  }
  get acceptEmail() {
    return this.form.controls['acceptEmail'] as FormControl;
  }
  get acceptPhone() {
    return this.form.controls['acceptPhone'] as FormControl;
  }

  enableCommunication() {
    if (this.acceptMarketing || this.acceptTraining || this.acceptResearch) {
      this.acceptEmail.enable();
      this.acceptPhone.enable()
      return true;
    }
    this.acceptEmail.setValue(false);
    this.acceptPhone.setValue(false);
    this.acceptEmail.disable();
    this.acceptPhone.disable();
    return false;
  }

  toggle(control: FormControl) {
    control.value ? control.setValue(true) : control.setValue(false);
  }

  createCompareValidator() {
    this.password.value !== this.check.value ?
      this.check.setErrors({ passwordDoesntMatch: true }) : this.check.setErrors(null);
  };

  verifyPassword() {
    this.passwordHasLength = this.password.value.length > 8;
    this.passwordHasLetter = /[a-zA-Z]/.test(this.password.value);
    this.passwordHasNumber = /\d/.test(this.password.value);
  }

  verifyPasswordMatch() {
    this.passwordMatch = this.password.value == this.passwordConfirmation;
  }

  selectRole(profile: any) {
    if (profile === 'c922d9c6-018c-3c50-3a1a-1fb850bc07e5' || profile === '828c0fa7-15cc-9cff-8d82-30d093b01e05') {
      this.form.addControl('specialization', new FormControl('', Validators.required));
      this.form.addControl('documentType', new FormControl('CRM'));
      this.form.addControl('documentNumber', new FormControl(''));
      this.isDoc = true;
    } else {
      this.isDoc = false;
      this.form.removeControl('specialization');
      this.form.removeControl('documentType');
      this.form.removeControl('documentNumber');
    }
  }

  onSubmit() {
    this.awaitingResponse = true;
    this.userService.registerUser(this.form.getRawValue()).subscribe({
      next: () => {
        this.router.navigate(['/login'], { queryParams: { registered: 'true' } });
      },
      error: (error) => {
        const errorMessage = typeof error === 'string' ? error : (error as HttpErrorResponse).error.errors[0];
        this.toastService.displayToast(errorMessage, 'error');
        this.awaitingResponse = false;
      }
    });
  }

  isValid(str: string) {
    return !!this.form.controls[str].invalid && !!(this.form.controls[str].dirty || this.form.controls[str].touched)
  }

}
