import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { ToastService } from 'src/app/services/toast/toast.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { CategoryService } from 'src/app/services/ws-common/categories/category.service';
import { MeService } from 'src/app/services/ws-user/me/me.service';
import { AuthResponse, Me, MeResponse } from 'src/app/models';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  categories!: any;
  categoriesSelected: any = [];
  categoriesNamesWithComma = '';
  me!: Me;
  @Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled = false;

  constructor(
    private categoryService: CategoryService,
    private meService: MeService,
    private toastService: ToastService,
    private tokenStorage: TokenStorageService) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.categoryService.getSubcategory().subscribe(res => {
        this.categories = res.data;
        this.meService
          .getMeFromApi()
          .subscribe((res: MeResponse) => {
            this.me = res.data;
            if (this.me.persons[0].interestAreas) {
              this.categoriesSelected = this.findCategoryObject(this.me.persons[0].interestAreas);
              this.buildCategoriesString();
            }
            this.onFilter.emit(this.categoriesSelected);
          });
      })
    );
  }

  categoriesChange(newCategories: any[]) {
    if (this.areArraysEqual(this.categoriesSelected, newCategories)) {
      return;
    }

    const ids = newCategories.map((category: any) => category.id);
    this.me.persons[0].interestAreas = ids;

    this.subscriptions.add(
      this.meService
        .updateInterestArea(this.me.persons[0].id, ids)
        .subscribe({
          next: (res: AuthResponse) => {
            this.tokenStorage.setTokens(res.data);
            this.meService
              .getMeFromApi()
              .subscribe(() => {
                this.categoriesSelected = newCategories;
                this.buildCategoriesString();
                this.onFilter.emit(this.categoriesSelected);
              });
          },
          error: (err) => this.toastService.displayToast(err.error?.errors, 'error')
        })
    );
  }

  areArraysEqual(arr1: number[], arr2: number[]) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    return arr1.every((value, index) => value === arr2[index]);
  };


  findCategoryObject(interestAreas: any[]) {
    const objects: any[] = [];
    interestAreas.forEach(id => objects.push(...this.categories.filter((c: any) => c.id === id)));
    return objects;
  }

  buildCategoriesString() {
    let withComma = '';

    if (this.categoriesSelected.length <= 0) {
      this.categoriesNamesWithComma = '';
      return;
    }

    for (let i = 0; i < this.categoriesSelected.length - 1; i++) {
      withComma = `${withComma}${this.categoriesSelected[i].title}, `;
    }

    withComma = `${withComma}${this.categoriesSelected[this.categoriesSelected.length - 1].title}`;
    this.categoriesNamesWithComma = withComma;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
