import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';

import { AuthService } from 'src/app/services/ws-user/auth/auth.service';
import { TokenStorageService } from 'src/app/services/token/token-storage.service';
import { TranslateConfigService } from 'src/app/services/translate-config/translate-config.service';
import { AuthRefreshResponse } from 'src/app/models/ws-user/auth-refresh.model';
import { ToastService } from 'src/app/services/toast/toast.service';

/**
 * Interceptador de autenticação para requisições HTTP.
 * Adiciona cabeçalhos de autenticação e lida com a renovação de tokens JWT.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private token: string | undefined;
  private lang: string = '';
  private isRefreshing: boolean = false;

  constructor(
    private authService: AuthService,
    private toastService: ToastService,
    private tokenService: TokenStorageService,
    private translate: TranslateService,
    private translateService: TranslateConfigService,
    private route: Router) { }

  /**
   * Método que intercepta todas as requisições HTTP.
   * @param request A requisição HTTP original.
   * @param next O próximo manipulador na cadeia de interceptadores.
   * @returns Um Observable que emite eventos de HttpEvent para a requisição.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Verifica se a requisição deve ser excluída da adição de cabeçalhos de autenticação.
    if (this.pathExcludes(request) || !request.withCredentials || request.headers.get('Authorization')) {
      return next.handle(request);
    }

    // Obtém o token JWT e o idioma atual.
    this.token = this.tokenService.getJwt()!;
    this.lang = this.translateService.getCurrentLang() ?? this.translateService.defaultLanguage;

    // Adiciona cabeçalhos de autenticação e idioma à requisição.
    request = this.addHeader(request, this.lang, this.token);

    // Lida com erros de resposta, como a expiração do token.
    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          // Se o erro for 401 com indicação de expiração do token, tente atualizar o token.
          if (error.status === 401 && error.error?.errors[0] === "TokenExpired") {
            // Se o token expirou, tenta renová-lo.
            return this.handleTokenRefresh(next, request);
          } else {
            // Para todos os outros erros, lança um erro a ser tratado pelo assinante.
            return throwError(() => error);
          }
        })
      );
  }

  /**
   * Verifica se o caminho da requisição deve ser excluído do tratamento de token.
   * @param request A requisição HTTP que está sendo interceptada.
   * @returns Um booleano indicando se a requisição deve ser excluída.
   */
  private pathExcludes(request: HttpRequest<any>): boolean {
    // Lista de caminhos que tem finais que devem ser excluídos das análises.
    const pathEndsExcludes: string[] = [
      '/auth',
      '/auth/verify-email',
      '/auth/forgot-password',
      '/auth/check-password-recovery',
      '/auth/refresh-token',
      '/auth/password-recovery'
    ];

    // Lista de caminhos que contém partes que devem ser excluídos das análises.
    const pathIncludesExcludes: string[] = [
      '/assets',
    ];

    // Verifica se a URL da requisição termina com algum dos caminhos excluídos.
    return pathEndsExcludes.some(path => request.url.endsWith(path)) ||
      pathIncludesExcludes.some(path => request.url.includes(path));
  }

  /**
   * Adiciona cabeçalhos de autenticação e idioma à requisição.
   * @param request A requisição HTTP que está sendo interceptada.
   * @param lang O idioma atual selecionado.
   * @param jwt O token JWT atual.
   * @returns A requisição HTTP modificada com os novos cabeçalhos.
   */
  private addHeader(request: HttpRequest<any>, lang: string, jwt: string | null): HttpRequest<any> {
    // Cabeçalhos a serem adicionados à requisição.
    let headers: { [key: string]: string } = {
      'Accept-Language': `${lang}`,
      'Content-Type': 'application/json'
    };

    // Se um JWT for fornecido, adicione o cabeçalho de Autorização.
    if (jwt) {
      headers['Authorization'] = `Bearer ${jwt}`;
    }

    // Clone a requisição original e defina os novos cabeçalhos.
    return request.clone({
      setHeaders: headers
    });
  }

  /**
   * Lida com a atualização do token quando o token atual expira.
   * @param next O manipulador HttpHandler para a próxima interceptação na cadeia.
   * @param request A requisição HTTP que está sendo interceptada.
   * @returns Um Observable da sequência de eventos HttpEvent.
   */
  private handleTokenRefresh(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    // Se já estiver atualizando, espere pela atualização do token.
    if (this.isRefreshing) {
      return this.waitForTokenRefresh(next, request);
    }

    // Indica que o processo de atualização do token começou.
    this.isRefreshing = true;
    this.refreshTokenSubject$.next('');

    // Solicita um novo token ao serviço de autenticação.
    return this.authService
      .refreshToken(this.tokenService.getRefresh())
      .pipe(
        switchMap((newToken: AuthRefreshResponse) => this.processNewToken(newToken, next, request)),
        catchError((error) => this.handleRefreshError(error))
      );
  }

  /**
   * Aguarda a atualização do token JWT.
   * Este método é chamado quando já existe um processo de atualização de token em andamento.
   * Ele se inscreve no BehaviorSubject que armazena o novo JWT.
   * Quando um novo token é emitido, ele é aplicado à requisição pendente e a requisição é retomada.
   * @param next O manipulador HttpHandler para a próxima interceptação na cadeia.
   * @param request A requisição HTTP que está sendo interceptada.
   * @returns Um Observable da sequência de eventos HttpEvent.
   */
  private waitForTokenRefresh(next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    return this.refreshTokenSubject$
      .pipe(
        filter((token: string) => token !== ''), // Filtra para continuar apenas se o token não for uma string vazia.
        take(1), // Pega apenas o primeiro valor emitido.
        switchMap((jwt: string) => { // Muda para um novo Observable, aplicando o novo JWT à requisição.
          this.tokenService.setJwt(jwt); // Atualiza o JWT armazenado.

          return next.handle(this.addHeader(request, this.lang, jwt)); // Continua a requisição com o novo token.
        })
      );
  }

  /**
   * Processa o novo token JWT recebido do serviço de autenticação.
   * Se um novo token válido for recebido, ele é armazenado e aplicado à requisição.
   * Caso contrário, um erro é gerado e o usuário é redirecionado para a tela de login.
   * @param newToken A resposta do serviço de autenticação contendo o novo token JWT.
   * @param next O manipulador HttpHandler para a próxima interceptação na cadeia.
   * @param request A requisição HTTP que está sendo interceptada.
   * @returns Um Observable da sequência de eventos HttpEvent.
   */
  private processNewToken(newToken: AuthRefreshResponse, next: HttpHandler, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    // Se não houver JWT, lida com o erro.
    if (!newToken.data?.jwtToken) {
      return this.handleRefreshError(newToken);
    }

    this.tokenService.setTokens(newToken.data); // Armazena o novo token.
    this.refreshTokenSubject$.next(newToken.data.jwtToken); // Emite o novo token para os observadores.
    this.isRefreshing = false; // Indica que o processo de atualização do token foi concluído.

    return next.handle(this.addHeader(request, this.lang, newToken.data.jwtToken)); // Continua a requisição com o novo token.
  }

  /**
   * Lida com erros durante a atualização do token JWT.
   * Se ocorrer um erro, o token é removido, uma mensagem de erro é exibida e o usuário é redirecionado para a tela de login.
   * @param error O erro retornado pelo serviço de autenticação.
   * @returns Um Observable que emite um erro, indicando falha na atualização do token.
   */
  private handleRefreshError(error: any): Observable<never> {
    this.isRefreshing = false; // Indica que o processo de atualização do token falhou.
    this.refreshTokenSubject$.next(''); // Limpa o BehaviorSubject.
    this.tokenService.removeTokens(); // Remove os tokens armazenados.

    let message = this.translate.instant('try.error'); // Mensagem de erro padrão.

    // Verifica se o erro contém detalhes específicos e atualiza a mensagem de erro.
    if (error.status != 0 && !(error.error instanceof ErrorEvent) && error.error?.errors) {
      message = error.error.errors;
    }

    this.route.navigate(['/login']); // Redireciona para a tela de login.
    this.toastService.displayToast(message, 'error', { duration: 7000 }); // Exibe a mensagem de erro.

    // Não precisa mais retornar um erro, então simplesmente complete o Observable
    return new Observable<never>(subscriber => subscriber.complete());
  }
}
