import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-role-input',
  templateUrl: './role-input.component.html',
  styleUrls: ['./role-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RoleInputComponent),
      multi: true,
    }
  ],
})
export class RoleInputComponent implements ControlValueAccessor, OnInit {
  @Input() title!: string;
  @Input() placeholder = 'personal.profile.select';
  itemFromSelect!: string;
  @Input() selectedItems: any[] = [];
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Input() icon = '../../../assets/icons/work_FILL0_wght400_GRAD0_opsz24.svg';
  @Input() type!: string;
  @Input() data!: any[];
  themes = [{ id:'light', title: this.translate.instant('light') }, { id:'dark', title: this.translate.instant('dark') }];
  languages = [{ id: 'pt-BR', title: this.translate.instant('language.portuguese.br') },
  { id: 'en-US', title: this.translate.instant('language.english.us') }, { id: 'es-ES', title: this.translate.instant('language.spanish.es') }];

  language!: any;

  constructor(public actionSheetController: ActionSheetController, private translate: TranslateService) { }

  public _value: any;
  public disabled!: boolean;
  onChanged: any = () => { };
  onTouched: any = () => { };

  ngOnInit(): void {
    if (this.type === 'theme') {
      this.data = this.themes;
    } else if (this.type === 'language') {
      this.data = this.languages;
    }
  }

  writeValue(val: any): void {
    this._value = val;
    this.itemFromSelect = this.data.find(d => d.id === val)?.title;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  selectItem(item: any) {
    this.itemFromSelect = item.title;
    this.onSelect.emit(item.id);
    this.onChanged(item.id ?? item.title);
    this.onTouched();
  }

  isChecked(value: string) {
    return this.selectedItems.find((item) => item === value);
  }

  checkboxChange(item: any, event: any) {
    if (event.detail.checked) {
      this.selectedItems.push(item);
    } else {
      this.selectedItems = this.selectedItems.filter(obj => obj.id !== item.id);
    }
    this.onChanged(this.selectedItems);
    this.onTouched();
  }
}
