import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByUserInterest'
})
export class FilterByUserInterestPipe implements PipeTransform {

  transform(objects: any[], userInterests: any): any[] {

    if (!userInterests || userInterests.length === 0) {
      return objects;
    }

    return objects.filter(obj => {
      return (
        userInterests.some((userInterest: any) => obj.categories.includes(userInterest.id) ||
          (userInterest.children && userInterest.children.length > 0 &&
            userInterest.children.some((child: any) => obj.categories.includes(child.id))))
      );
    });
  }
}
