<article class="container flex direction-col" (click)="downloadInstruction()">
  <div class="img-cont flex items-center">
    <img data-test="img" class="img-type" src="../../../assets/img/types/pdf-2610 3.svg">
  </div>
  <p class="guide-title">{{ technicalGuide.title }}</p>
  <div class="download-spinner" *ngIf="isDownloading; else iconTemplate">
    <ion-spinner name="circular"></ion-spinner>
  </div>
  <ng-template #iconTemplate>
    <img *ngIf="!isDownloaded" class="download-icon" [ngClass]="{'svg-dark-mode': darkMode}"
      src="../../../assets/icons/download_FILL0_wght400_GRAD0_opsz24.svg">
    <img *ngIf="isDownloaded" class="download-complete"
      src="../../../assets/icons/check_small_FILL0_wght400_GRAD0_opsz24.svg">
  </ng-template>
</article>
