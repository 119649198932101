import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  languages: { [key: string]: string } = {
    'en-US': 'English (US)',
    'es-ES': 'Español (ES)',
    'pt-BR': 'Português (BR)',
  }

  getLanguage = (code: string): string => this.languages[code] ?? this.languages['en-US'];

  getCode = (lang: string): string => Object.keys(this.languages).find(key => this.languages[key] === lang) ?? 'en-US' ;

  getUsersLocale(): string {
    const wn = typeof window === 'undefined' ? undefined : window.navigator;

    return wn?.languages?.find(l => l.includes('-')) ?? 'en-US';
  }
}
