<ion-content *ngIf="isLoaded">
    <ion-refresher mode="ios" slot="fixed" [pullMin]="350" [pullMax]="650" (ionRefresh)="handleRefresh($event)">
      <ion-refresher-content mode="ios" pullingIcon="chevron-down-circle-outline"
        refreshingSpinner="circles"></ion-refresher-content>
    </ion-refresher>
    <header class="px-4 pt-8">
      <div class="w-full flex items-center">
        <h4 class="m-0">{{ 'products' | translate }}</h4>
      </div>
      <ion-searchbar class="custom w-full" mode="ios" inputmode="search" (ionInput)="search($event)"
        (keyup)="listenForSearchEnter($event)" [placeholder]="'search' | translate"></ion-searchbar>
    </header>
    <section class="px-4 pb-9 card-container m-0">
      <ion-grid>
        <ion-row class="pt-5">
          <ion-col size="6" size-xs="6" size-sm="6" size-md="3" size-lg="2" class="pt-4" *ngFor="let item of categories"
            (click)="gotoDetail(item)" class="card flex direction-col">
            <div>
              <img data-test="img" class="card-image p-2" [src]="item.picture"
                onerror="this.src='../../../assets/icons/no_photography_FILL0_wght400_GRAD0_opsz24.svg'">
              <p class="card-title m-4">{{ item.title }}</p>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>
</ion-content>

<ion-content *ngIf="!isLoaded">
    <header class="header p-6 pt-8">
      <div class="w-full flex items-center">
        <div>
          <h4 class="m-0">{{ 'products' | translate }}</h4>
        </div>
      </div>
      <ion-searchbar class="custom w-full" mode="ios" [placeholder]="'search' | translate"
        [disabled]="true"></ion-searchbar>
    </header>
    <section class="px-6 pb-5 card-container m-0 h-full">
      <ion-grid class="h-full">
        <ion-row class="pt-6 h-full">
          <ion-col size="6" size-xs="6" size-sm="6" size-md="3" size-lg="2" class="pt-4"
            class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true"
              style="height: 96%; border-radius: 12px; width: 93%"></ion-skeleton-text>
          </ion-col>
          <ion-col size="6" size-xs="6" size-sm="6" size-md="3" size-lg="2" class="pt-4"
            class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true"
              style="height: 96%; border-radius: 12px; width: 93%"></ion-skeleton-text>
          </ion-col>
          <ion-col size="6" size-xs="6" size-sm="6" size-md="3" size-lg="2" class="pt-4"
            class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true"
              style="height: 96%; border-radius: 12px; width: 93%"></ion-skeleton-text>
          </ion-col>
          <ion-col size="6" size-xs="6" size-sm="6" size-md="3" size-lg="2" class="pt-4"
            class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true"
              style="height: 96%; border-radius: 12px; width: 93%"></ion-skeleton-text>
          </ion-col>
          <ion-col size="6" size-xs="6" size-sm="6" size-md="3" size-lg="2" class="pt-4"
            class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true"
              style="height: 96%; border-radius: 12px; width: 93%"></ion-skeleton-text>
          </ion-col>
          <ion-col size="6" size-xs="6" size-sm="6" size-md="3" size-lg="2" class="pt-4"
            class="card flex direction-col ion-no-padding">
            <ion-skeleton-text [animated]="true"
              style="height: 96%; border-radius: 12px; width: 93%"></ion-skeleton-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </section>
</ion-content>
