import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastCtrl: ToastController, private translate: TranslateService) { }

  icon!: string;
  header!: string;
  duration: number = 5000;

  displayToast(
    message: string | Array<string> | null,
    cssClass: 'error' | 'success',
    options: {
      position?: 'top' | 'bottom' | 'middle', duration?: number
    } = { position: 'bottom', duration: 5000 }) {
    let translatedMessage: string = this.translate.instant('try.error');
    this.duration = options.duration ?? this.duration;

    if (message) {
      if (Array.isArray(message)) {
        translatedMessage = message.join('\n');
      } else {
        translatedMessage = message;
      }
    }

    switch (cssClass) {
      case 'error':
        this.header = this.translate.instant('error');
        this.icon = '../../../assets/icons/priority_high_FILL0_wght400_GRAD0_opsz24.svg';
        break;
      case 'success':
        this.header = this.translate.instant('success');
        this.icon = '../../../assets/icons/done_FILL0_wght400_GRAD0_opsz24.svg';
        break;
      default:
        break;
    }

    this.toastCtrl.create({
      position: options.position ?? 'bottom',
      header: this.header,
      message: translatedMessage,
      duration: this.duration,
      cssClass: cssClass,
      icon: this.icon,
      buttons: [
        {
          icon: 'close'
        },
      ],
    }).then((toast) => {
      toast.present();
    });
  }
}
