import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './auth/auth.interceptor';
import { CacheInterceptor } from './cache/cache.interceptor';
import { CustomInterceptor } from './newrelic/custom.interceptor';

export const HttpInterceptors = [
  { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
];
