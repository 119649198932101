import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAgentService {

  private userAgentSubject = new Subject<any>();
  userAgent$ = this.userAgentSubject.asObservable();
  userAgent!: any;

  constructor() {
    const userAgent = navigator.userAgent;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      this.userAgentSubject.next('ios');
      this.userAgent = 'ios';
    } else {
      this.userAgentSubject.next('android');
      this.userAgent = 'android';
    }
  }

  setUserAgent() {
    localStorage.setItem('userAgent', this.userAgent);
  }
}

// const userAgent = navigator.userAgent || navigator.vendor || window.opera;
// if (/android/i.test(userAgent)) {
//   // O usuário está usando Android
// } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
//   // O usuário está usando iOS
// } else {
//   // O usuário não está usando Android nem iOS
// }
