import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-related-content',
  templateUrl: './related-content.component.html',
  styleUrls: ['./related-content.component.scss']
})
export class RelatedContentComponent {
  @Input() relatedContent: any[] = [];

  constructor(private router: Router) {}

  gotoRelatedContent(content: any) {
    if (content.typeContent === 1) {
      this.router.navigate([`home/news/detail/${content.id}`])
    } else if (content.typeContent === 0) {
      this.router.navigate([`home/procedures/detail/${content.id}`])
    }
  }

}
