import { HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AgentConfiguration, NewRelicCapacitorPlugin } from '@newrelic/newrelic-capacitor-plugin';

import packageJson from '../../../../package.json';
import { environment } from 'src/environments/environment';
import { ELogLevel, EMetricUnit, ENetworkFailure } from 'src/app/enums';

/**
 * Serviço responsável pelo rastreamento e monitoramento de eventos,
 * integrando com o New Relic para análise de desempenho e diagnóstico.
 */
@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  /**
   * Inicia o monitoramento do New Relic com configurações específicas para a aplicação.
   * Define atributos globais como a versão e o ambiente da aplicação.
   * @param token Um objeto contendo os tokens de aplicação para iOS e Android. Contém os parâmetros ios e android.
   */
  start(token: { ios: string, android: string }): void {
    if (Capacitor.isNativePlatform()) {
      let agentConfiguration = this.agentConfig();
      let platform = Capacitor.getPlatform();
      let version = packageJson.version;
      let env = environment.environment;
      let appKey = token.android;

      if (platform === 'ios') {
        appKey = token.ios;
      }

      NewRelicCapacitorPlugin.start({ appKey, agentConfiguration });

      this.setAttribute({ version, environment: env });
      this.setMaxEventBufferTime(120);
      this.setMaxEventPoolSize(1000);
      this.setMaxStorageSize(100);

      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info('Inicializando monitoramento');
        console.info({ Start: { version, env, platform, appKey, agentConfiguration } });
      }
    }
  }

  /**
 * Gera cabeçalhos de rastreamento distribuído para uso em requisições HTTP.
 * @returns Uma promessa que resolve com os cabeçalhos de rastreamento distribuído.
 */
  async getDTHeaders(): Promise<object> {
    if (Capacitor.isNativePlatform()) {
      const headers = await NewRelicCapacitorPlugin.generateDistributedTracingHeaders();

      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ GetDTHeaders: { headers } });
      }

      return headers;
    }

    return {};
  }

  /**
   * Filtra os cabeçalhos de rastreamento distribuído para incluir apenas os permitidos.
   * @param headers Os cabeçalhos de rastreamento distribuído.
   * @returns Um objeto contendo apenas os cabeçalhos permitidos.
   */
  filterHeaders(headers: object): { [key: string]: string } {
    const allowedHeaders = ['guid', 'id', 'newrelic', 'traceid', 'traceparent', 'tracestate'];
    const filteredHeaders: { [key: string]: string } = {};

    Object
      .entries(headers)
      .forEach(([key, value]) => {
        if (allowedHeaders.includes(key)) {
          filteredHeaders[key] = value;
        }
      });

    // Registre o erro no console apenas se não for em produção
    if (!environment.production) {
      console.info({ FilterHeaders: { headers, allowedHeaders, filteredHeaders } });
    }

    return filteredHeaders;
  }

  /**
   * Simula uma falha (crash) na aplicação para testar o monitoramento e a resposta de erros.
   * @param message Uma string opcional que descreve a mensagem de erro. 'Teste de Crash' é usado como padrão (opcional, padrão 'Teste de Crash').
   */
  crash(message: string = 'Teste de Crash'): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ Crash: { Current: Date.now(), message } });
      }

      NewRelicCapacitorPlugin.crashNow({ message });
    }
  }

  /**
   * Obtém o identificador da sessão atual do New Relic.
   * @returns Uma promessa que resolve para uma string contendo o identificador da sessão atual.
   */
  async currentSession(): Promise<string> {
    if (Capacitor.isNativePlatform()) {
      let { sessionId } = await NewRelicCapacitorPlugin.currentSessionId();

      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ CurrentSession: { sessionId } });
      }

      return sessionId;
    }

    return '';
  }

  /**
   * Registra transações HTTP no New Relic.
   * @param httpResponse A resposta HTTP recebida (pode ser undefined).
   * @param request A requisição HTTP original.
   * @param headers Os cabeçalhos de rastreamento distribuído.
   * @param byteSize O tamanho dos bytes enviados e recebidos na requisição. Contém os parâmetros sentBytes e receivedBytes.
   * @param sentBytes O número de bytes enviados na requisição.
   * @param receivedBytes O número de bytes recebidos na resposta.
   * @param timeRequest O tempo de início e término da requisição. Contém os parâmetros startTime e endTime.
   * @param startTime O timestamp de início da requisição.
   * @param endTime O timestamp de término da requisição (opcional, padrão Date.now()).
   * @param url A URL da requisição (opcional).
   */
  httpTransaction(
    body: object,
    status: number,
    request: HttpRequest<any>,
    headers: object,
    byteSize: { sentBytes: number, receivedBytes: number },
    timeRequest: { startTime: number, endTime: number } = { startTime: Date.now(), endTime: Date.now() },
    url?: string): void {
    if (Capacitor.isNativePlatform()) {
      url = url ?? request.url ? Capacitor.convertFileSrc(request.url) : '';

      if (url.startsWith('http')) {
        let stringBody = body ? JSON.stringify(this.getBody({ ...body })) : '';
        let params = this.getBody({ ...request.body });
        let parameters = {
          url,
          method: request.method,
          status,
          startTime: timeRequest.startTime,
          endTime: timeRequest.endTime,
          bytesSent: byteSize.sentBytes,
          bytesReceived: byteSize.receivedBytes,
          body: stringBody,
          traceAttributes: headers,
          params
        };

        // Registre o erro no console apenas se não for em produção
        if (!environment.production) {
          console.info({ HttpTransaction: { url, parameters } });
        }

        NewRelicCapacitorPlugin.noticeHttpTransaction(parameters);
      }
    }
  }

  /**
   * Incrementa o valor de um atributo no New Relic.
   * @param name O nome do atributo a ser incrementado.
   * @param value O valor pelo qual o atributo deve ser incrementado. (opcional, padrão incrementado por 1).
   */
  increment(name: string, value?: number): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ SetAttribute: { name, value } });
      }

      if (value) {
        NewRelicCapacitorPlugin.incrementAttribute({ name, value });
      } else {
        NewRelicCapacitorPlugin.incrementAttribute({ name });
      }
    }
  }

  /**
   * Finaliza uma interação do usuário com a aplicação usando o identificador único fornecido.
   * @param interactionId Um identificador único da interação a ser finalizada.
   */
  interactionEnd(interactionId: string): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ InteractionEnd: { interactionId } });
      }

      NewRelicCapacitorPlugin.endInteraction({ interactionId });
    }
  }

  /**
   * Inicia uma interação do usuário com a aplicação e retorna um identificador único.
   * @param value Um objeto contendo o valor que descreve a interação.
   * @returns Uma promessa que resolve para um objeto contendo o identificador único da interação.
   */
  async interactionStart(value: string): Promise<string> {
    if (Capacitor.isNativePlatform()) {
      let interactionId = await NewRelicCapacitorPlugin.startInteraction({ value });

      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ InteractionStart: { value, interactionId } });
      }

      return interactionId.value;
    }

    return '';
  }

  /**
   * Registra falhas de rede no New Relic.
   * @param error O erro de resposta HTTP recebido.
   * @param request A requisição HTTP original que resultou no erro.
   * @param startTime O timestamp de início da requisição.
   * @param endTime O timestamp de término da requisição (opcional, padrão Date.now()).
   * @param url A URL da requisição (opcional).
   */
  networkFailure(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    startTime: number,
    endTime: number = Date.now(),
    url?: string
  ): void {
    if (Capacitor.isNativePlatform()) {
      let failureType = this.getFailure(error);

      url = url ?? (error.url ? Capacitor.convertFileSrc(error.url) : '');
      endTime = endTime ?? Date.now();

      let parameters = {
        url: url,
        method: request.method,
        startTime: startTime,
        endTime: endTime,
        failure: failureType,
      };

      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.error({ NetworkFailure: { error, url, failureType, parameters } });
      }

      if (url.startsWith('http')) {
        NewRelicCapacitorPlugin.noticeNetworkFailure(parameters);
      }
    }
  }

  /**
   * Registra um breadcrumb no New Relic para rastrear eventos ou ações do usuário.
   * @param name O nome do breadcrumb.
   * @param eventAttributes Os atributos adicionais do evento (opcional).
   * @param options Opções adicionais para o registro do breadcrumb (opcional). Contém os parâmetros size, someProps e hidden.
   * @param size O número máximo de propriedades a serem verificadas (opcional, padrão é 0, representa sem limites).
   * @param someProps Se verdadeiro, apenas propriedades selecionadas serão processadas (opcional, padrão é false).
   * @param hidden Se verdadeiro, os valores sensíveis serão ocultados (opcional, padrão é true).
   */
  recordBreadcrumb(
    name: string,
    eventAttributes: object = {},
    options: { size: number, someProps: boolean, hidden: boolean } = { size: 0, someProps: false, hidden: true }
  ): void {
    if (Capacitor.isNativePlatform()) {
      let attr = this.getBody(eventAttributes, options.size, options.someProps, options.hidden);

      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ RecordBreadcrumb: { name, attr } });
      }

      NewRelicCapacitorPlugin.recordBreadcrumb({ name, eventAttributes: attr });
    }
  }

  /**
   * Registra um erro no New Relic.
   * @param error O objeto de erro a ser registrado.
   * @param isFatal Indica se o erro é fatal (opcional, padrão false).
   */
  recordError(error: any, isFatal = false): void {
    if (Capacitor.isNativePlatform()) {
      let parameters = {
        name: error.name ?? 'Error',
        message: error.message ?? 'Ocorreu um erro desconhecido',
        stack: error.stack ?? error.statusText ?? error.name ?? 'Sem Stack',
        isFatal,
        attributes: error
      };

      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.error({ RecordError: { isFatal, error, parameters } });
      }

      NewRelicCapacitorPlugin.recordError(parameters);
    }
  }

  /**
   * Registra um evento personalizado no New Relic.
   * @param eventType O tipo de evento que está sendo registrado. Este é um identificador geral para o evento.
   * @param eventName O nome específico do evento. Se não for fornecido, será uma string vazia (opcional).
   * @param attributes Um objeto contendo atributos adicionais relacionados ao evento. Se não for fornecido, será um objeto vazio (opcional).
   * @param options Opções adicionais para o registro do breadcrumb (opcional). Contém os parâmetros size, someProps e hidden.
   * @param size O número máximo de propriedades a serem verificadas (opcional, padrão é 0, representa sem limites).
   * @param someProps Se verdadeiro, apenas propriedades selecionadas serão processadas (opcional, padrão é false).
   * @param hidden Se verdadeiro, os valores sensíveis serão ocultados (opcional, padrão é true).
   */
  recordEvent(
    eventType: string,
    eventName: string = '',
    attributes: object = {},
    options: { size: number, someProps: boolean, hidden: boolean } = { size: 0, someProps: false, hidden: true }
  ): void {
    if (Capacitor.isNativePlatform()) {
      let attr = this.getBody(attributes, options.size, options.someProps, options.hidden);

      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ RecordEvent: { eventType, eventName, attr } });
      }

      NewRelicCapacitorPlugin.recordCustomEvent({ eventType, eventName, attributes: attr });
    }
  }

  /**
   * Registra uma métrica personalizada no New Relic.
   * @param name O nome da métrica a ser registrada.
   * @param category A categoria da métrica, que ajuda a agrupar métricas similares.
   * @param value O valor numérico da métrica. Se não for fornecido, apenas o nome e a categoria serão registrados (opcional).
   * @param countUnit A unidade de contagem da métrica (opcional). Valores permitidos PERCENT, BYTES, SECONDS, BYTES_PER_SECOND OPERATIONS.
   * @param valueUnit A unidade de valor da métrica (opcional). Valores permitidos PERCENT, BYTES, SECONDS, BYTES_PER_SECOND OPERATIONS.
   */
  recordMetric(
    name: string,
    category: string,
    value?: number,
    countUnit?: EMetricUnit,
    valueUnit?: EMetricUnit
  ): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ RecordMetric: { name, category, value, countUnit, valueUnit } });
      }

      if (value) {
        if (countUnit && valueUnit) {
          NewRelicCapacitorPlugin.recordMetric({ name, category, value, countUnit, valueUnit });
        } else {
          NewRelicCapacitorPlugin.recordMetric({ name, category, value });
        }
      } else {
        NewRelicCapacitorPlugin.recordMetric({ name, category });
      }
    }
  }

  /**
   * Método para remover um ou mais atributos personalizados no New Relic.
   * @param nameAttributes String com o nome do atributo ou array contendo valores de um atributo.
   */
  removeAttribute(nameAttributes: string | Array<string>): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ RemoveAttribute: { nameAttributes } });
      }

      if (Array.isArray(nameAttributes)) {
        nameAttributes
          .forEach((name) => {
            NewRelicCapacitorPlugin.removeAttribute({ name });
          });
      } else if (typeof nameAttributes === 'string') {
        NewRelicCapacitorPlugin.removeAttribute({ name: nameAttributes });
      }
    }
  }

  /**
   * Método para remover todos atributos da seção atual no New Relic.
   */
  resetAttributes(): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info('Removendo todos os atributos da sessão atual');
      }

      NewRelicCapacitorPlugin.removeAllAttributes();
    }
  }

  /**
   * Remove o atributo User ID do New Relic.
   * @param userId O User ID a ser removido do New Relic (opcional).
   */
  removeUser(userId?: string): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info(`Removendo User ID do monitoramento: ${userId}`);
      }

      this.removeAttribute('userId');
    }
  }

  /**
   * Método para definir um ou mais atributos personalizados no New Relic.
   * @param nameAttributes String com o nome do atributo ou objeto contendo pares de chave-valor com mais de um atributo e cada valor é o valor do atributo.
   * @param value O valor do atributo, caso nameAttributes seja uma string. Este parâmetro é ignorado se nameAttributes for um objeto.
   */
  setAttribute(nameAttributes: string | object, value?: any): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info({ SetAttribute: { nameAttributes, value } });
      }

      if (typeof nameAttributes === 'object' && nameAttributes !== null) {
        Object
          .entries(nameAttributes)
          .forEach(([key, val]) => {
            NewRelicCapacitorPlugin.setAttribute({ name: key, value: val });
          });
      } else if (typeof nameAttributes === 'string') {
        NewRelicCapacitorPlugin.setAttribute({ name: nameAttributes, value });
      }
    }
  }

  /**
   * Define o tempo máximo de buffer para eventos no New Relic.
   * @param maxTime O tempo máximo, em segundos (opcional, padrão 120 segundos). O valor mínimo é 60 segundos e o máximo é 600 segundos.
   */
  setMaxEventBufferTime(maxTime: number = 120): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info(`Definindo tempo máximo de buffer de eventos: ${maxTime}`);
      }

      maxTime = Math.max(60, maxTime);
      maxTime = Math.min(600, maxTime);

      NewRelicCapacitorPlugin.setMaxEventBufferTime({ maxBufferTimeInSeconds: maxTime });
    }
  }

  /**
   * Define o tamanho máximo do pool de eventos no New Relic.
   * @param maxSize O tamanho máximo do pool de eventos (opcional, padrão é 1000 eventos).
   */
  setMaxEventPoolSize(maxSize: number = 1000): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info(`Definindo tamanho máximo do pool de eventos: ${maxSize}`);
      }

      NewRelicCapacitorPlugin.setMaxEventPoolSize({ maxPoolSize: maxSize })
    }
  }

  /**
   * Define o tamanho máximo de armazenamento offline para eventos no New Relic.
   * @param megaBytes O tamanho máximo, em megabytes, que o armazenamento offline pode ocupar (opcional, padrão é 100 megabytes).
   */
  setMaxStorageSize(maxSize: number = 100): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info(`Definindo tamanho máximo do pool de eventos: ${maxSize}`);
      }

      NewRelicCapacitorPlugin.setMaxOfflineStorageSize({ megaBytes: maxSize })
    }
  }

  /**
   * Define o User ID no New Relic para rastrear dados específicos do usuário.
   * @param userId O User ID a ser definido no New Relic.
   */
  setUser(userId: string): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info(`Definindo User ID para monitoramento: ${userId}`);
      }

      NewRelicCapacitorPlugin.setUserId({ userId });
    }
  }

  /**
   * Desliga o agente do New Relic na aplicação.
   */
  shutdown(): void {
    if (Capacitor.isNativePlatform()) {
      // Registre o erro no console apenas se não for em produção
      if (!environment.production) {
        console.info('Encerrando monitoramento');
      }

      NewRelicCapacitorPlugin.shutdown();
    }
  }

  /**
   * Gera a configuração do agente do New Relic com base nas definições específicas para a aplicação.
   * @returns Um objeto AgentConfiguration com as configurações do agente do New Relic.
   */
  private agentConfig(): AgentConfiguration {
    let agentConfig: AgentConfiguration = {
      // Específico para Android
      // Ativa ou desativa a coleta de dados de eventos.
      analyticsEventEnabled: true,

      // Específico para iOS
      // Habilitar/Desabilitar instrumentação automática de WebViews
      webViewInstrumentation: true,

      // Ative ou desative o relatório de falhas.
      crashReportingEnabled: true,

      // Ative ou desative o rastreamento de interação. A instrumentação de rastreamento ainda ocorre, mas nenhum rastreamento é coletado. Isso desativará as interações padrão e personalizadas.
      interactionTracingEnabled: true,

      // Ative ou desative o relatório de solicitações HTTP bem-sucedidas para o tipo de evento MobileRequest.
      networkRequestEnabled: true,

      // Ative ou desative a rede de relatórios e erros de solicitação HTTP para o tipo de evento MobileRequestError.
      networkErrorRequestEnabled: true,

      // Ative ou desative a captura de corpos de resposta HTTP para rastreamentos de erros HTTP e eventos MobileRequestError.
      httpResponseBodyCaptureEnabled: true,

      // Ative ou desative o log do agente.
      loggingEnabled: true,

      // Especifica o nível de log. Omita este campo para o nível de log padrão.
      // As opções incluem: ERROR (menos detalhado), WARNING, INFO, VERBOSE, AUDIT (mais detalhado).
      logLevel: environment.production ? ELogLevel.INFO : ELogLevel.AUDIT,

      // Habilite ou desabilite o envio de logs do console JS para New Relic.
      sendConsoleEvents: true,

      // habilite ou desabilite dados de relatórios usando endpoints diferentes para clientes do governo dos EUA.
      fedRampEnabled: false,

      // habilite ou desabilite o armazenamento de dados offline quando não houver conexão com a Internet disponível.
      offlineStorageEnabled: true,

      // Defina um endereço de coletor específico para envio de dados. Omita este campo para endereço padrão.
      // collectorAddress: "",

      // Defina um endereço específico do coletor de falhas para enviar falhas. Omita este campo para endereço padrão.
      // crashCollectorAddress: "",  
    };

    return agentConfig;
  }

  /**
   * Obtém o tipo de falha com base no erro de resposta HTTP.
   * @param error O erro de resposta HTTP recebido.
   * @returns O tipo de falha correspondente ao status do erro.
   */
  private getFailure(error: HttpErrorResponse): ENetworkFailure {
    // Registre o erro no console apenas se não for em produção
    if (!environment.production) {
      console.error({ GetFailure: { status: error?.status, error } });
    }

    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 0: // Desconhecido
          return ENetworkFailure.UNKNOWN; // Desconhecido
        case 400: // Solicitação incorreta
        case 402: // Pagamento necessário
        case 404: // Não encontrado
        case 405: // Método não permitido
        case 406: // Não aceitável
        case 409: // Conflito
        case 410: // Excluído
        case 411: // Comprimento necessário
        case 412: // Falha na pré-condição
        case 413: // Carga útil muito grande
        case 415: // Tipo de mídia não suportado
        case 416: // Faixa não satisfatória
        case 417: // Expectativa falhou
        case 423: // Bloqueado
        case 424: // Dependência com falha
        case 425: // Muito cedo
        case 426: // Atualização necessária
        case 428: // Pré-condição necessária
        case 429: // Muitas solicitações
        case 431: // Campos de cabeçalho de solicitação muito grandes
          return ENetworkFailure.BAD_REQUEST; // URL incorreto
        case 408: // Tempo limite da solicitação
        case 504: // Tempo limite do gateway
          return ENetworkFailure.TIMEOUT; // Tempo esgotado
        case 451: // Indisponível por motivos legais
          return ENetworkFailure.CANNOT_HOST; // Não é possível conectar ao host
        case 414: // URI muito longo
          return ENetworkFailure.DNS_FAILED; // Falha na pesquisa de DNS
        case 418: // Sou um bule de chá
        case 421: // Solicitação mal direcionada
        case 422: // Entidade não processável
        case 500: // Erro interno do servidor
        case 501: // Não implementado
        case 502: // Bad Gateway
        case 503: // serviço indisponível
        case 505: // Versão HTTP não suportada
        case 506: // Variante também negocia
        case 507: // Armazenamento insuficiente
        case 508: // Loop detectado
        case 510: // Não estendido
          return ENetworkFailure.BAD_RESPONSE; // Resposta incorreta do servidor
        case 401: // Não autorizado / Não autenticado
        case 403: // Proibido
        case 407: // Autenticação de proxy necessária
        case 511: // Autenticação de rede necessária
          return ENetworkFailure.SECURE_FAILED; // Falha na Conexão Segura
        default: // Default
          return ENetworkFailure.UNKNOWN; // Desconhecido
      }
    }

    return ENetworkFailure.UNKNOWN;
  }

  /**
   * Processa o corpo da requisição e oculta valores de propriedades sensíveis.
   * @param reqBody O corpo da requisição que pode conter dados sensíveis.
   * @param limit Define o número máximo de propriedades a serem verificadas (opcional, padrão é 3).
   * @param someProps Define se apenas propriedades selecionadas devem ser retornadas (opcional, padrão é true).
   * @param hidden Define se os valores sensíveis devem ser ocultados (opcional, padrão é true).
   * @returns Um objeto com os dados sensíveis ocultados ou apenas as propriedades selecionadas (id, title).
   */
  private getBody(reqBody: object, limit: number = 3, someProps: boolean = true, hidden: boolean = true): { [key: string]: any } {
    if (typeof reqBody !== 'object' || reqBody === null) {
      return reqBody;
    }

    let propHidden = ['password', 'token', 'key', 'secret'];
    let propGet = ['id', 'title'];

    let newBody: object = JSON.parse(JSON.stringify(reqBody));

    let body: { [key: string]: any } = this.processObject(newBody, propHidden, propGet, limit, someProps, hidden);

    // Registre o erro no console apenas se não for em produção
    if (!environment.production) {
      console.info({ GetBody: { body } });
    }

    return body;
  }

  /**
   * Processa um objeto recursivamente para ocultar ou obter propriedades específicas.
   * 
   * @param obj O objeto a ser processado.
   * @param propHidden Lista de propriedades cujos valores devem ser ocultados.
   * @param propGet Lista de propriedades que devem ser obtidas.
   * @param limit Limite de propriedades a serem processadas.
   * @param someProps Se verdadeiro, apenas propriedades selecionadas serão processadas.
   * @param hidden Se verdadeiro, os valores sensíveis serão ocultados.
   * @param count Contador para o número de propriedades processadas.
   * @returns Um objeto com as propriedades processadas.
   */
  private processObject(obj: any, propHidden: string[], propGet: string[], limit: number, someProps: boolean, hidden: boolean, count: number = 0): { [key: string]: any } {
    if (this.aboveLimit(count, limit) || !this.isObject(obj)) {
      return {};
    }

    let dataReturn: { [key: string]: any } = {};

    for (const [key, value] of Object.entries(obj)) {
      if (this.aboveLimit(count, limit)) {
        break;
      }

      const keyLower = key.toLowerCase();

      if (this.isObject(value)) {
        const tmpValue = this.processObject(value, propHidden, propGet, limit, someProps, hidden, count);

        if (Object.keys(tmpValue).length > 0) {
          dataReturn[key] = tmpValue;
          count++;
        }
      } else if (this.isSelectedProp(keyLower, propGet, someProps)) {
        dataReturn[key] = this.shouldHideValue(keyLower, propHidden, hidden) ? 'value hidden' : value;
        count++;
      }
    }
    return dataReturn;
  }

  /**
   * Verifica se o número de propriedades processadas excedeu o limite.
   * @param count O número atual de propriedades processadas.
   * @param limit O limite máximo de propriedades a serem processadas.
   * @returns Verdadeiro se o limite for acima de zero e o contador tiver atingido o limite.
   */
  private aboveLimit(count: number, limit: number): boolean {
    return limit > 0 && count >= limit;
  }

  /**
   * Verifica se um valor é um objeto.
   * 
   * @param value O valor a ser verificado.
   * @returns Verdadeiro se o valor for um objeto, falso caso contrário.
   */
  private isObject(value: any): boolean {
    return value && typeof value === 'object';
  }

  /**
   * Determina se o valor de uma chave deve ser ocultado com base em uma lista de palavras-chave sensíveis.
   * @param keyLower A chave convertida para minúsculas.
   * @param propHidden Lista de palavras-chave sensíveis.
   * @param hidden Se verdadeiro, os valores sensíveis serão ocultados.
   * @returns Verdadeiro se o valor deve ser ocultado, falso caso contrário.
   */
  private shouldHideValue(keyLower: string, propHidden: string[], hidden: boolean): boolean {
    return hidden && propHidden.some(keyword => keyLower.includes(keyword));
  }

  /**
   * Verifica se uma chave é uma das propriedades selecionadas para serem incluídas.
   * @param keyLower A chave convertida para minúsculas.
   * @param propGet Lista de propriedades que devem ser obtidas.
   * @param someProps Se verdadeiro, apenas propriedades selecionadas serão processadas.
   * @returns Verdadeiro se a chave for uma das propriedades selecionadas, falso caso contrário.
   */
  private isSelectedProp(keyLower: string, propGet: string[], someProps: boolean): boolean {
    return !someProps || propGet.some(keyword => keyLower.includes(keyword));
  }
}

/**
 * Exporta a função start da classe TrackingService.
 * Este export é um atalho para o método estático 'start' da classe TrackingService.
 * @param token Um objeto contendo os tokens de aplicação para iOS e Android.
 */
export const StartMonitoring: (token: { ios: string, android: string }) => void = new TrackingService().start.bind(new TrackingService());

/**
 * Filtra os cabeçalhos de rastreamento distribuído para incluir apenas os permitidos.
 * @param headers Os cabeçalhos de rastreamento distribuído.
 * @returns Um objeto contendo apenas os cabeçalhos permitidos.
 */
export const FilterHeaders: (headers: object) => { [key: string]: string } = new TrackingService().filterHeaders.bind(new TrackingService());

/**
 * Gera cabeçalhos de rastreamento distribuído para uso em requisições HTTP.
 * Este export é um atalho para o método estático 'getDTHeaders' da classe TrackingService.
 * @returns Uma promessa que resolve com os cabeçalhos de rastreamento distribuído.
 */
export const GetDTHeaders: () => Promise<object> = new TrackingService().getDTHeaders.bind(new TrackingService());

/**
 * Objeto `Attributes` que fornece métodos para adicionar, remover e limpar atributos.
 */
export const Attributes: {
  /**
   * Método para definir um ou mais atributos personalizados no New Relic.
   * Este export é um atalho para o método estático 'setAttribute' da classe TrackingService.
   * @param nameAttributes String com o nome do atributo ou objeto contendo pares de chave-valor com mais de um atributo e cada valor é o valor do atributo.
   * @param value O valor do atributo, caso nameAttributes seja uma string. Este parâmetro é ignorado se nameAttributes for um objeto.
   */
  Add: (nameAttributes: string | object, value?: any) => void,
  /**
   * Método para remover um ou mais atributos personalizados no New Relic.
   * Este export é um atalho para o método estático 'removeAttribute' da classe TrackingService.
   * @param nameAttributes String com o nome do atributo ou array contendo valores de um atributo.
   */
  Remove: (nameAttributes: string | Array<string>) => void,
  /**
   * Método para remover todos atributos da seção atual no New Relic.
   * Este export é um atalho para o método estático 'resetAttributes' da classe TrackingService.
   */
  Reset: () => void,
} = {
  Add: new TrackingService().setAttribute.bind(new TrackingService()),
  Remove: new TrackingService().removeAttribute.bind(new TrackingService()),
  Reset: new TrackingService().resetAttributes.bind(new TrackingService())
};

/**
 * Simula uma falha (crash) na aplicação para testar o monitoramento e a resposta de erros.
 * Este export é um atalho para o método estático 'crash' da classe TrackingService.
 * @param message Uma string opcional que descreve a mensagem de erro. 'Teste de Crash' é usado como padrão (opcional, padrão 'Teste de Crash').
 */
export const Crash: (message?: string) => void = new TrackingService().crash.bind(new TrackingService());

/**
 * Obtém o identificador da sessão atual do New Relic.
 * Este export é um atalho para o método estático 'currentSession' da classe TrackingService.
 * @returns Uma promessa que resolve para uma string contendo o identificador da sessão atual.
 */
export const CurrentSession: () => Promise<string> = new TrackingService().currentSession.bind(new TrackingService());

/**
 * Registra transações HTTP no New Relic.
 * Este export é um atalho para o método estático 'httpTransaction' da classe TrackingService.
 * @param httpResponse A resposta HTTP recebida (pode ser undefined).
 * @param request A requisição HTTP original.
 * @param headers Os cabeçalhos de rastreamento distribuído.
 * @param sentBytes O número de bytes enviados na requisição.
 * @param receivedBytes O número de bytes recebidos na resposta.
 * @param startTime O timestamp de início da requisição.
 * @param endTime O timestamp de término da requisição (opcional, padrão Date.now()).
 * @param url A URL da requisição (opcional).
 */
export const HttpTransaction: (
  body: object,
  status: number,
  request: HttpRequest<any>,
  headers: object,
  byteSize: { sentBytes: number, receivedBytes: number },
  timeRequest?: { startTime: number, endTime: number },
  url?: string
) => void = new TrackingService().httpTransaction.bind(new TrackingService());


/**
 * Incrementa o valor de um atributo no New Relic.
 * Este export é um atalho para o método estático 'increment' da classe TrackingService.
 * @param name O nome do atributo a ser incrementado.
 * @param value O valor pelo qual o atributo deve ser incrementado. (opcional, padrão incrementado por 1).
 */
export const Increment: (name: string, value?: number) => void = new TrackingService().increment.bind(new TrackingService());

/**
 * Inicia uma interação do usuário com a aplicação e retorna um identificador único.
 * Este export é um atalho para o método estático 'interactionStart' da classe TrackingService.
 * @param value Uma string que descreve a interação que está sendo iniciada.
 * @returns Uma promessa que resolve para um objeto contendo o identificador único da interação.
 */
export const StartInteraction: (value: string) => Promise<string> = new TrackingService().interactionStart.bind(new TrackingService());

/**
 * Finaliza uma interação do usuário com a aplicação usando o identificador único fornecido.
 * Este export é um atalho para o método estático 'interactionEnd' da classe TrackingService.
 * @param interactionId Uma string representando o identificador único da interação a ser finalizada.
 */
export const EndInteraction: (interactionId: string) => void = new TrackingService().interactionEnd.bind(new TrackingService());

/**
 * Registra falhas de rede no New Relic.
 * Este export é um atalho para o método estático 'networkFailure' da classe TrackingService.
 * @param error O erro de resposta HTTP recebido.
 * @param request A requisição HTTP original que resultou no erro.
 * @param startTime O timestamp de início da requisição.
 * @param endTime O timestamp de término da requisição (opcional, padrão Date.now()).
 * @param url A URL da requisição (opcional).
 */
export const NetworkFailure: (
  error: HttpErrorResponse,
  request: HttpRequest<any>,
  startTime: number,
  endTime?: number,
  url?: string
) => void = new TrackingService().networkFailure.bind(new TrackingService());

/**
 * Registra um breadcrumb no New Relic para rastrear eventos ou ações do usuário.
 * Este export é um atalho para o método estático 'recordBreadcrumb' da classe TrackingService.
 * @param name O nome do breadcrumb.
 * @param eventAttributes Os atributos adicionais do evento (opcional).
 * @param options Opções adicionais para o registro do breadcrumb (opcional). Contém os parâmetros size, someProps e hidden.
 * @param size O número máximo de propriedades a serem verificadas (opcional, padrão é 0, representa sem limites).
 * @param someProps Se verdadeiro, apenas propriedades selecionadas serão processadas (opcional, padrão é false).
 * @param hidden Se verdadeiro, os valores sensíveis serão ocultados (opcional, padrão é true).
 */
export const RecordBreadcrumb: (
  name: string,
  eventAttributes?: object,
  options?: { size: number, someProps: boolean, hidden: boolean }
) => void = new TrackingService().recordBreadcrumb.bind(new TrackingService());

/**
 * Registra um erro no New Relic.
 * Este export é um atalho para o método estático 'recordError' da classe TrackingService.
 * @param error O objeto de erro a ser registrado.
 * @param isFatal Indica se o erro é fatal (opcional, padrão false).
 */
export const RecordError: (error: any, isFatal?: boolean) => void = new TrackingService().recordError.bind(new TrackingService());

/**
 * Registra um evento personalizado no New Relic.
 * Este export é um atalho para o método estático 'recordEvent' da classe TrackingService.
 * @param eventType O tipo de evento que está sendo registrado. Este é um identificador geral para o evento.
 * @param eventName O nome específico do evento. Se não for fornecido, será uma string vazia (opcional).
 * @param attributes Um objeto contendo atributos adicionais relacionados ao evento. Se não for fornecido, será um objeto vazio (opcional).
 * @param options Opções adicionais para o registro do breadcrumb (opcional). Contém os parâmetros size, someProps e hidden.
 * @param size O número máximo de propriedades a serem verificadas (opcional, padrão é 0, representa sem limites).
 * @param someProps Se verdadeiro, apenas propriedades selecionadas serão processadas (opcional, padrão é false).
 * @param hidden Se verdadeiro, os valores sensíveis serão ocultados (opcional, padrão é true).
 */
export const RecordEvent: (
  eventType: string,
  eventName?: string,
  attributes?: object,
  options?: { size: number, someProps: boolean, hidden: boolean }
) => void = new TrackingService().recordEvent.bind(new TrackingService());

/**
 * Registra uma métrica personalizada no New Relic.
 * Este export é um atalho para o método estático 'recordMetric' da classe TrackingService.
 * @param name O nome da métrica a ser registrada.
 * @param category A categoria da métrica, que ajuda a agrupar métricas similares.
 * @param value O valor numérico da métrica. Se não for fornecido, apenas o nome e a categoria serão registrados (opcional).
 * @param countUnit A unidade de contagem da métrica (opcional). Valores permitidos PERCENT, BYTES, SECONDS, BYTES_PER_SECOND OPERATIONS.
 * @param valueUnit A unidade de valor da métrica (opcional). Valores permitidos PERCENT, BYTES, SECONDS, BYTES_PER_SECOND OPERATIONS.
 */
export const RecordMetric: (
  name: string,
  category: string,
  value?: number,
  countUnit?: EMetricUnit,
  valueUnit?: EMetricUnit
) => void = new TrackingService().recordMetric.bind(new TrackingService());

/**
 * Objeto `SetMaxInfo` que fornece métodos para configurar limites de buffer, pool e storage.
 */
export const SetMaxInfo: {
  /**
   * Define o tempo máximo de buffer para eventos no New Relic.
   * Este export é um atalho para o método estático 'setMaxEventBufferTime' da classe TrackingService.
   * @param maxTime O tempo máximo, em segundos, (opcional, padrão 120 segundos). O valor mínimo é 60 segundos e o máximo é 600 segundos.
   */
  Buffer: (maxTime: number) => void,
  /**
   * Define o tamanho máximo do pool de eventos no New Relic.
   * Este export é um atalho para o método estático 'setMaxEventPoolSize' da classe TrackingService.
   * @param maxSize O tamanho máximo do pool de eventos (opcional, padrão é 1000 eventos).
   */
  Pool: (maxSize: number) => void,
  /**
   * Define o tamanho máximo de armazenamento offline para eventos no New Relic.
   * Este export é um atalho para o método estático 'setMaxStorageSize' da classe TrackingService.
   * @param megaBytes O tamanho máximo, em megabytes, que o armazenamento offline pode ocupar (opcional, padrão é 100 megabytes).
   */
  Storage: (maxSize?: number) => void
} = {
  Buffer: new TrackingService().setMaxEventBufferTime.bind(new TrackingService()),
  Pool: new TrackingService().setMaxEventPoolSize.bind(new TrackingService()),
  Storage: new TrackingService().setMaxStorageSize.bind(new TrackingService())
};

/**
 * Define o tempo máximo de buffer para eventos no New Relic.
 * Este export é um atalho para o método estático 'setMaxEventBufferTime' da classe TrackingService.
 * @param maxTime O tempo máximo, em segundos, (opcional, padrão 120 segundos). O valor mínimo é 60 segundos e o máximo é 600 segundos.
 */
export const SetBufferTime: (maxTime: number) => void = new TrackingService().setMaxEventBufferTime.bind(new TrackingService());

/**
 * Define o tamanho máximo do pool de eventos no New Relic.
 * Este export é um atalho para o método estático 'setMaxEventPoolSize' da classe TrackingService.
 * @param maxSize O tamanho máximo do pool de eventos (opcional, padrão é 1000 eventos).
 */
export const SetPoolSize: (maxSize: number) => void = new TrackingService().setMaxEventPoolSize.bind(new TrackingService());

/**
 * Define o tamanho máximo de armazenamento offline para eventos no New Relic.
 * Este export é um atalho para o método estático 'setMaxStorageSize' da classe TrackingService.
 * @param megaBytes O tamanho máximo, em megabytes, que o armazenamento offline pode ocupar (opcional, padrão é 100 megabytes).
 */
export const SetStorageSize: (maxSize?: number) => void = new TrackingService().setMaxStorageSize.bind(new TrackingService());

/**
 * Remove o atributo User ID do New Relic.
 * Este export é um atalho para o método estático 'removeUser' da classe TrackingService.
 * @param userId O User ID a ser removido do New Relic (opcional).
 */
export const RemoveUser: (userId?: string) => void = new TrackingService().removeUser.bind(new TrackingService());

/**
 * Define o User ID no New Relic para rastrear dados específicos do usuário.
 * Este export é um atalho para o método estático 'setUser' da classe TrackingService.
 * @param userId O User ID a ser definido no New Relic.
 */
export const SetUser: (userId: string) => void = new TrackingService().setUser.bind(new TrackingService());

/**
 * Desliga o agente do New Relic na aplicação.
 * Este export é um atalho para o método estático 'shutdown' da classe TrackingService.
 */
export const Shutdown: () => void = new TrackingService().shutdown.bind(new TrackingService());
