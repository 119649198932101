import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Serviço de cache para armazenar e recuperar respostas HTTP com estratégias de invalidação.
 */
@Injectable({
  providedIn: 'root'
})
export class CacheService {
  private cache: Map<string, { data: HttpResponse<any>; expiry: number }> = new Map();
  private readonly cacheDuration = 86400000;

  /**
   * Armazena uma resposta no cache com um tempo de expiração.
   * @param url A URL da solicitação como chave do cache.
   * @param response A resposta HTTP a ser armazenada.
   * @param duration Tempo em milissegundos após o qual o cache é considerado expirado.
   */
  put(url: string, data: HttpResponse<any>, duration: number = this.cacheDuration): void {
    const expiry = Date.now() + duration;
    this.cache.set(url, { data, expiry });
  }

  /**
   * Recupera uma resposta do cache se não estiver expirada.
   * @param url A URL da solicitação cuja resposta é para ser recuperada.
   * @returns A resposta HTTP armazenada ou null se expirada ou não presente.
   */
  get(url: string): HttpResponse<any> | null {
    const cached = this.cache.get(url);

    if (!cached) {
      return null;
    }

    if (Date.now() > cached.expiry) {
      this.cache.delete(url);
      return null;
    }

    return cached.data;
  }

  /**
   * Limpa uma entrada específica do cache pela chave.
   * @param url A URL da solicitação a ser removida do cache.
   */
  clearByKey(url: string): void {
    this.cache.delete(url);
  }

  /**
   * Limpa o cache.
   */
  clearAll(): void {
    this.cache.clear();
  }
}
