import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { IonicModule } from '@ionic/angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import localePt from '@angular/common/locales/pt';
import localeEs from '@angular/common/locales/es';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GlobalErrorHandler } from './handlers/global-error-handler';
import { HttpInterceptors } from './interceptors';
import { ProductsModule } from './products/products.module';
import { ComponentsModule } from './components/components.module';
import { LoginModule } from './login/login.module';
import { NavigationHistoryService } from './services/navigation/navigation-history.service';

registerLocaleData(localePt);
registerLocaleData(localeEs);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function getBrowserLanguage(): string {
  if (localStorage.getItem('lang')) {
    return localStorage.getItem('lang')?.split('-')[0] || 'pt';
  }
  return navigator.language || (navigator as any).userLanguage;
}

export function initializeNavigationHistoryService(navigationHistoryService: NavigationHistoryService) {
  return () => navigationHistoryService.initialize();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    LoginModule,
    ProductsModule,
    ComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LOCALE_ID, useValue: getBrowserLanguage() },
    HttpInterceptors,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeNavigationHistoryService,
      deps: [NavigationHistoryService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
