import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';

import { environment } from 'src/environments/environment';

const URL_CATEGORY = `${environment.common}/category`;

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private categoryCache$?: Observable<any>;
  private categoryProductCache$?: Observable<any>;
  private subcategoryCache$?: Observable<any>;

  constructor(private http: HttpClient) { }

  getCategoryCache(): Observable<any> {
    if (!this.categoryCache$) {
      this.categoryCache$ = this.getCategory().pipe(
        shareReplay(1)
      );
    }
    return this.categoryCache$;
  }

  getCategoryProductCache(): Observable<any> {
    if (!this.categoryProductCache$) {
      this.categoryProductCache$ = this.getCategoryProductRelated().pipe(
        shareReplay(1)
      );
    }
    return this.categoryProductCache$;
  }

  getCategory(): Observable<any> {
    this.categoryCache$ = this.http.get<any>(URL_CATEGORY, { withCredentials: true }).pipe(shareReplay(1));
    return this.categoryCache$;
  }

  getSubcategory(): Observable<any> {
    this.categoryCache$ = this.http.get<any>(`${URL_CATEGORY}/subcategory`, { withCredentials: true }).pipe(shareReplay(1));
    return this.categoryCache$;
  }

  getSubcategoryCache(): Observable<any> {
    if (!this.subcategoryCache$) {
      this.subcategoryCache$ = this.getSubcategory().pipe(
        shareReplay(1)
      );
    }
    return this.subcategoryCache$;
  }

  getSubcategoryProductRelated(): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/subcategory/product`, { withCredentials: true });
  }

  getSubcategoryAllRelated(): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/subcategory/all`, { withCredentials: true });
  }

  getCategoryProductRelated(): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/product`, { withCredentials: true }).pipe(shareReplay(1));
  }

  getSubcategoryByIdProductRelated(id: string): Observable<any> {
    return this.http.get<any>(`${URL_CATEGORY}/${id}/subcategory/product`, { withCredentials: true });
  }
}
