import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastService } from 'src/app/services/toast/toast.service';

import { CategoryService } from 'src/app/services/ws-common/categories/category.service';

@Component({
  selector: 'app-subcategories-list',
  templateUrl: './subcategories-list.component.html',
  styleUrls: ['./subcategories-list.component.scss']
})
export class SubcategoriesListComponent implements OnInit, OnDestroy {

  isLoaded = false;
  categoryId!: any;
  parentCategoryName!: string;
  subcategories: any[] = [];
  procedures: any[] = [];
  subscriptions: Subscription = new Subscription;
  previousRoute = 'products/list';
  previousQuery = '';

  constructor(private categoryService: CategoryService, private activatedRoute: ActivatedRoute,
    private router: Router, private toastService: ToastService) { }

  ngOnInit(): void {
    this.categoryId = this.activatedRoute.snapshot.queryParamMap.get('category')!;
    this.categoryService.getSubcategoryByIdProductRelated(this.categoryId).subscribe(
      {
        next: (res) => {
          this.parentCategoryName = res.data.title;
          res.data.children?.forEach((item: any) => {
            item.category ? this.subcategories.push(item) : this.procedures.push(item)
          });
          this.isLoaded = true;
        },
        error: (e) => {
          this.toastService.displayToast(e.error.errors[0], 'error');
        }
      });
    this.previousRoute = this.activatedRoute.snapshot.queryParamMap.get('prev')!;
    this.previousQuery = this.activatedRoute.snapshot.queryParamMap.get('query')!;

    if (this.previousRoute === null) {
      this.previousRoute = 'products/categories';
    }
  }

  selectSubcategory(subcategory: any) {
    this.router.navigate(['products/list'], { queryParams: { category: this.categoryId, subcategory: subcategory.id } });
  }

  handleSearch(e: any) {
    this.router.navigate(['products/search'], {
      queryParams:
        { prev: 'subcategories', category: this.categoryId, query: e.target.value }
    });
  }

  gotoPreviousPage() {
    this.previousRoute === 'products/categories' ?
      this.router.navigate([`${this.previousRoute}`]) :
      this.router.navigate([`${this.previousRoute}`], { queryParams: { query: this.previousQuery } });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

// class CategoryFull {
//   id: string;
//   name: string;
//   category: boolean;
//   procedure: boolean;
//   picture: string;
//   children: [
//     {
//       id: string;
//       name: string;
//       category: boolean;
//       procedure: boolean;
//     },
//     {
//       id: string;
//       name: string;
//       category: boolean;
//       procedure: boolean;
//     }
//   ]

//   constructor(id: string, name: string, category: boolean, procedure: boolean, picture: string,children:any) {
//     this.id = id;
//     this.name = name;
//     this.category = category;
//     this.picture = picture;
//     this.procedure = procedure;
//     this.children = children;
//   }
// }
