import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import { environment } from 'src/environments/environment';
import { CacheService } from '../../cache/cache.service';
import { ContentResponse, ContentResponseList } from 'src/app/models';

const URL_CONTENT = `${environment.content}/content`;
const URL_FAVORITES = `${environment.content}/favorite`;


@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient, private cache: CacheService) { }

  getContent(): Observable<ContentResponseList> {
    return this.http.get<ContentResponseList>(URL_CONTENT, { withCredentials: true });
  }

  getContentByCategory(id: string): Observable<ContentResponseList> {
    return this.http.get<ContentResponseList>(`${URL_CONTENT}?Category=${id}`, { withCredentials: true });
  }

  getContentById(id: string): Observable<ContentResponse> {
    return this.http.get<ContentResponse>(`${URL_CONTENT}/${id}`, { withCredentials: true });
  }

  addToContentToFavorite(idFavorite: any) {
    this.cache.clearByKey(`${URL_FAVORITES}/news`);
    this.cache.clearByKey(`${URL_FAVORITES}/video`);
    this.cache.clearByKey(`${URL_CONTENT}/${idFavorite}`);
    return this.http.post<any>(URL_FAVORITES, { id: idFavorite }, { withCredentials: true });
  }

  getUserFavoritedContents() {
    return this.http.get<any>(URL_FAVORITES, { withCredentials: true });
  }

  getFavoritedNews(refresh?: boolean) {
    if (refresh) {
      this.cache.clearByKey(`${URL_FAVORITES}/news`);
    }
    return this.http.get<any>(`${URL_FAVORITES}/news`, { withCredentials: true });
  }

  getFavoritedVideos(refresh?: boolean) {
    if (refresh) {
      this.cache.clearByKey(`${URL_FAVORITES}/video`);
    }
    return this.http.get<any>(`${URL_FAVORITES}/video`, { withCredentials: true });
  }

  deleteFavorited(id: string) {
    this.cache.clearByKey(`${URL_FAVORITES}/news`);
    this.cache.clearByKey(`${URL_FAVORITES}/video`);
    this.cache.clearByKey(`${URL_CONTENT}/${id}`);
    return this.http.delete<any>(`${URL_FAVORITES}/${id}`, { withCredentials: true });
  }
}
