import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';

const URL = `${environment.url}/role`;

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  private roleCache$?: Observable<any>;

  constructor(private http: HttpClient) { }

  getRoles(): Observable<any> {
    if (!this.roleCache$) {
      this.roleCache$ = this.requestRoles().pipe(
        shareReplay(1)
      );
    }
    return this.roleCache$;
  }

  private requestRoles(): Observable<any> {
    return this.http.get<any>(URL);
  }

}
