import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';


const URL = `${environment.url}/specialization`;

@Injectable({
  providedIn: 'root'
})
export class SpecializationService {

  private specCache$?: Observable<any>;

  constructor(private http: HttpClient) { }

  getSpecialization(): Observable<any> {
    if (!this.specCache$) {
      this.specCache$ = this.requestSpecialization().pipe(
        shareReplay(1)
      );
    }
    return this.specCache$;
  }

  private requestSpecialization(): Observable<any> {
    return this.http.get<any>(URL);
  }

  getInfoBySpecId(specId: string) {
    return this.http.get<any>(`${URL}/${specId}`);
  }
}
