<ion-content>
    <div class="px-6 pt-10 card-container h-full">
      <header class="flex direction-col items-center">
        <img data-test="img" class="pt-10 pb-6"
          [src]="isDarkTheme ? '../../../assets/img/sintegra_darkmode.svg' : '../../../assets/img/sintegra.svg'">
        <section class="pt-7 pb-7 flex direction-col items-center">
          <h3 class="title">{{ 'password.redefine' | translate }}</h3>
          <p class="color-prim-60">{{ 'email.type' | translate }}</p>
        </section>
      </header>
      <section class="flex direction-col items-center">
        <form data-test="frm" class="w-full flex direction-col" [formGroup]="form" (ngSubmit)="onSubmit()">
          <ion-list data-test="lst" class="ion-no-padding">
            <app-simple-input formControlName="email"
              [iconPath]="'../../../assets/icons/mail_FILL0_wght400_GRAD0_opsz24.svg'" [label]="'E-mail'"
              [type]="'email'" [placeholder]="'email.type' | translate" [valid]="isValid('email')"
              [required]="true"></app-simple-input>
          </ion-list>
          <ion-button data-test="btn" id="open-toast" expand="block" type="submit"
            class="w-full py-7 submit-button self-center" [disabled]="!form.valid" *ngIf="!awaitingResponse">{{
            'password.redefine' | translate }}</ion-button>
          <ion-button data-test="btn" expand="block" type="submit" class="w-full py-7 submit-button self-center"
            *ngIf="awaitingResponse" disabled="true"><ion-spinner name="crescent"></ion-spinner></ion-button>
        </form>
        <ion-button data-test="btn" fill="clear" [routerLink]="['/login/']">
          <p class="color-prim-60 pt-2">{{ 'login.back' | translate }}</p>
        </ion-button>
      </section>
    </div>
</ion-content>
