import { Component, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonModal } from '@ionic/angular';

import { CategoryService } from 'src/app/services/ws-common/categories/category.service';

@Component({
  selector: 'app-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationInputComponent),
      multi: true,
    }
  ],
})
export class LocationInputComponent implements ControlValueAccessor, OnInit {
  @ViewChild('modal') modal!: IonModal;
  @Input() trigger!: string;
  @Input() title!: string;
  @Input() type!: string;
  @Input() me!: any;
  @Input() selectedItems: any[] = [];
  data: any[] = [];

  constructor(private categoryService: CategoryService) { }

  public _value: any;
  public disabled!: boolean;
  onChanged: any = () => { };
  onTouched: any = () => { };

  writeValue(val: any): void {
    this._value = val;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.findUserAreasOfInterest();
  }

  private findUserAreasOfInterest() {
    this.categoryService.getCategory().subscribe(res => {
      this.data = res.data;
      if (this.me.persons[0].interestAreas) {
        this.selectedItems = this.me.persons[0].interestAreas.map((id: any) => {
          const foundCategory: any = this.data.find((category: any) => category.id === id);
          return foundCategory ? foundCategory : null;
        });
      }
    });
  }

  selectionChanged(areas: string[]) {
    this.selectedItems = areas;
    const ids = this.selectedItems.map(item => item.id);
    this.onTouched();
    this.onChanged(ids);
    this.modal.dismiss();
  }

  removeItem(data: any) {
    const i = this.selectedItems.findIndex(item => item == data);
    this.selectedItems.splice(i, 1);
    const ids = this.selectedItems.map(item => item.id);
    this.onTouched();
    this.onChanged(ids);
  }
}
