<div class="calendar-container flex direction-col p-4">
  <div [class.display-none]="showMonthYearPicker" class="calendar-arrow-container flex justify-center items-center">
    <button class="calendar-left-arrow me-3" (click)="goLeft()">
      <img src="../../../assets/icons/arrow_back_ios_FILL0_wght400_GRAD0_opsz24.svg">
    </button>
    <div class="calendar-month-year" (click)="toggleMonthYearPicker()">
      <p>{{ monthArray[currentMonth] }}, {{ currentYear }}</p>
    </div>
    <button class="calendar-right-arrow ms-3" (click)="goRight()">
      <img src="../../../assets/icons/arrow_forward_ios_FILL0_wght400_GRAD0_opsz24.svg">
    </button>
  </div>
  <app-month-year-picker *ngIf="showMonthYearPicker" (onDateSelected)="onDateSelected($event)" [initialYear]="initialYear" [initialMonth]="initialMonth"></app-month-year-picker>
  <ul class="calendar-week pt-5" [class.disable-click]="showMonthYearPicker">
    <li class="calendar-week-day">{{ 'day.week.sun' | translate }}</li>
    <li class="calendar-week-day">{{ 'day.week.mon' | translate }}</li>
    <li class="calendar-week-day">{{ 'day.week.tue' | translate }}</li>
    <li class="calendar-week-day">{{ 'day.week.wed' | translate }}</li>
    <li class="calendar-week-day">{{ 'day.week.thr' | translate }}</li>
    <li class="calendar-week-day">{{ 'day.week.fri' | translate }}</li>
    <li class="calendar-week-day">{{ 'day.week.sat' | translate }}</li>
  </ul>
  <ul class="calendar-days pt-5" [class.disable-click]="showMonthYearPicker">
  </ul>
</div>
