<ion-content>
  <div class="bg-grad pt-9">
    <div class="px-6 pt-6 card-container h-full">
      <header class="pt-4 pb-7 flex items-center g-3">
        <app-back-button [buttonType]="'blue'"></app-back-button>
        <h3 class="title color-prim-50">{{ 'account.create' | translate }}</h3>
      </header>
      <section class="flex direction-col items-center">
        <form data-test="frm" class="w-full flex direction-col" [formGroup]="form">
          <ion-list data-test="lst" class="ion-no-padding">
            <p class="form-title pb-3">{{ 'personal.details' | translate }}</p>
            <app-simple-input formControlName="name" [hasIcon]="true"
              [iconPath]="'../../../assets/icons/person_FILL0_wght400_GRAD0_opsz24.svg'"
              [label]="'personal.name' | translate" [type]="'text'" [placeholder]="'personal.name.type' | translate"
              [valid]="isValid('name')"></app-simple-input>
            <app-simple-input formControlName="phone"
              [iconPath]="'../../../assets/icons/call_FILL0_wght400_GRAD0_opsz24.svg'" [label]="'phone' | translate"
              [type]="'phone'" [placeholder]="'(xx) xxxxx-xxxx'" [optional]="true"
              [valid]="isValid('phone')"></app-simple-input>

            <app-role-input formControlName="profile" [type]="'role'" [title]="'profile' | translate"
              [placeholder]="'personal.profile.select' | translate" [data]="rolesList"
              (onSelect)="selectRole($event)"></app-role-input>

            <app-role-input formControlName="specialization" [type]="'spec'"
              [title]="'personal.specialization' | translate"
              [icon]="'../../../assets/icons/medical_mask_FILL0_wght400_GRAD0_opsz24.svg'"
              [placeholder]="'personal.specialization.select' | translate" [data]="specsList"
              *ngIf="isDoc"></app-role-input>

            <app-simple-input formControlName="documentNumber"
              [iconPath]="'../../../assets/icons/stethoscope_FILL0_wght400_GRAD0_opsz24 1.svg'" *ngIf="isDoc"
              [label]="'CRM'" [type]="'number'" [placeholder]="'personal.crm.type' | translate"
              [optional]="true"></app-simple-input>

            <p class="form-title pt-5 pb-3">{{ 'workplace' | translate }}</p>
            <app-country-input formControlName="locations" [userLocations]="[]"></app-country-input>

            <p class="form-title pt-5 pb-3">{{ 'account' | translate }}</p>

            <app-simple-input formControlName="email"
              [iconPath]="'../../../assets/icons/mail_FILL0_wght400_GRAD0_opsz24.svg'" [label]="'email' | translate"
              [type]="'email'" [placeholder]="'email.type' | translate"
              [valid]="isValid('email')"></app-simple-input>

            <app-simple-input formControlName="password"
              [iconPath]="'../../../assets/icons/lock_FILL0_wght400_GRAD0_opsz24.svg'" [label]="'password' | translate"
              [type]="'password'" [placeholder]="'password.type' | translate" [valid]="isValid('password')"
              (input)="verifyPassword()"  (input)="createCompareValidator()"></app-simple-input>
            <app-password-check [password]="password.value"></app-password-check>
            <app-simple-input formControlName="check" [invalidInputMsg]="'password.match' | translate"
              [iconPath]="'../../../assets/icons/lock_FILL0_wght400_GRAD0_opsz24.svg'"
              [label]="'password.confirm' | translate" [type]="'password'"
              [placeholder]="'password.type.again' | translate" (input)="createCompareValidator()"
              [valid]="isValid('check')"></app-simple-input>
          </ion-list>

          <p class="password-ul color-prim-50 mt-4">{{ 'agree.notifications' | translate }}</p>
          <div class="flex direction-col items-center">
            <ion-toggle data-test="tgl" justify="space-between" formControlName="acceptMarketing"
              (ionChange)="acceptMarketing = !acceptMarketing; enableCommunication()">
              <p class="pt-4 agreement-p">{{ 'agree.notifications.marketing' | translate }}</p>
            </ion-toggle><br />
            <ion-toggle data-test="tgl" justify="space-between" formControlName="acceptTraining"
              (ionChange)="acceptTraining = !acceptTraining; enableCommunication()">
              <p class="pt-4 agreement-p">{{ 'agree.notifications.training' | translate }}</p>
            </ion-toggle><br />
            <ion-toggle data-test="tgl" justify="space-between" formControlName="acceptResearch"
              (ionChange)="acceptResearch = !acceptResearch; enableCommunication()">
              <p class="pt-4 agreement-p">{{ 'agree.notifications.research' | translate }}</p>
            </ion-toggle><br />

            <p class="password-ul color-prim-50 mt-4 self-start">{{ 'agree.by' | translate }}</p>
            <ion-toggle data-test="tgl" justify="space-between" formControlName="acceptEmail"
              (ionChange)="toggle(acceptEmail)">
              <p class="pt-4 agreement-p">{{ 'email' | translate }}</p>
            </ion-toggle><br />
            <ion-toggle data-test="tgl" justify="space-between" formControlName="acceptPhone"
              (ionChange)="toggle(acceptPhone)">
              <p class="pt-4 agreement-p">{{ 'agree.notifications.phone' | translate }}</p>
            </ion-toggle><br />

            <div class="flex w-full items-center pb-6">
              <p class="pt-4 agreement-p">{{ 'agree.terms' | translate }}
                <a class="links" href="https://www.sintegrasurgical.com.br/brasil/politica-de-privacidade">{{ 'terms.user' | translate
                  }}</a>
                {{ 'agree.and' | translate }}
                <a class="links" href="https://www.sintegrasurgical.com.br/brasil/aviso-legal">{{ 'terms.privacy' | translate }}</a>.
              </p>
              <ion-toggle data-test="tgl" justify="end" formControlName="acceptTerm" class="p-4 ion-no-padding"
                (ionChange)="agreedToTerms = !agreedToTerms">
              </ion-toggle><br />
            </div>
          </div>
          <ion-button data-test="btn" expand="block" type="submit" class="w-full pb-7 submit-button self-center"
            [disabled]="!form.valid || !agreedToTerms" *ngIf="!awaitingResponse"
            (click)="onSubmit()">{{ 'register' | translate }}</ion-button>
          <ion-button data-test="btn" expand="block" type="submit" class="w-full pb-7 submit-button self-center"
            *ngIf="awaitingResponse" disabled="true">
            <ion-spinner name="circles"></ion-spinner>
          </ion-button>
        </form>
      </section>
    </div>
  </div>
</ion-content>
