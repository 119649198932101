import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent {

  @Input() data!: any;
  @Input() type!: any;
  @Input() query!: any;
  @Input() isHighlight = false;
  @Output() onGotoDetail = new EventEmitter();
  wrapper = document.querySelector('.wrapper');
  pressed = false;
  startX = 0;
  @Output() unfavorited = new EventEmitter<boolean>();

  mouseDown(e: any) {
    this.pressed = true;
    this.startX = e.clientX;
  }

  mouseMove(e: any) {
    if (!this.pressed) {
      return;
    }
    if (this.wrapper)
      this.wrapper.scrollLeft += this.startX - e.clientX;
  }

  unfavorite(e: any): void {
    this.unfavorited.emit(e);
  }
}
