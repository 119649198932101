import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { register } from 'swiper/element/bundle';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import Swiper from 'swiper';

import { ProductService } from 'src/app/services/ws-product/products/product.service';
import { ContentService } from 'src/app/services/ws-content/content/content.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { FileDownloadService } from 'src/app/services/download/file-download.service';
import { RecordEvent } from 'src/app/services/tracking/tracking.service';

register();

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDetailComponent implements OnInit, OnDestroy {

  isLoaded = false;
  private subscriptions: Subscription = new Subscription();

  product!: any;
  categoryId!: any;
  subcategoryId!: any;
  images: string[] = [];
  isRequestTraining = false;
  isRequestDemonstration = false;
  instructionToDownload!: any;
  instructionNameFormatted = '';

  @ViewChild('productSwiper') productSwiper!: ElementRef | undefined;
  swiper?: Swiper;
  darkMode = false;
  relatedContent: any[] = [];
  instructions: any[] = [];

  constructor(private productService: ProductService, private activeRoute: ActivatedRoute,
    private contentService: ContentService, private toastService: ToastService, private fileDownloadService: FileDownloadService, private translate: TranslateService) { }

  ngOnInit(): void {
    const id = this.getUrlParameters();
    this.darkMode = localStorage.getItem('mode') === 'dark';

    this.subscriptions.add(
      this.productService.getProductById(id).subscribe(res => {
        this.product = res.data;
        this.product.contents.forEach((contentId: string) => {
          this.contentService.getContentById(contentId).subscribe((content) => {
            this.relatedContent.push(content.data);
          });
        });

        this.setSwiper(this.productSwiper);
        this.product = this.prioritizeHighlightPicture(this.product);
        this.instructions = this.product.instructions;
        this.populateSlides();
        this.isLoaded = true;
      })
    );
  }

  private getUrlParameters() {
    const id = this.activeRoute.snapshot.paramMap.get('id')!;
    this.categoryId = this.activeRoute.snapshot.queryParamMap.get('category')!;
    this.subcategoryId = this.activeRoute.snapshot.queryParamMap.get('subcategory')!;
    return id;
  }

  requestDemonstration() {
    this.isRequestDemonstration = true;
    RecordEvent('Product', 'Demonstration', { productId: this.product.id });
    this.productService.purchase(this.product.id).subscribe({
      next: () => {
        this.toastService.displayToast(this.translate.instant('product.purchase'), 'success');
        this.isRequestDemonstration = false;
      },
      error: (er) => {
        this.toastService.displayToast(er.error.errors[0], 'error');
        this.isRequestDemonstration = false;
      }
    });
  }

  requestTraining() {
    this.isRequestTraining = true;
    RecordEvent('Product', 'Training', { productId: this.product.id });
    this.productService.training(this.product.id).subscribe({
      next: () => {
        this.toastService.displayToast(this.translate.instant('product.training'), 'success');
        this.isRequestTraining = false;
      },
      error: (er) => {
        this.toastService.displayToast(er.error.errors[0], 'error');
        this.isRequestTraining = false;
      }
    });
  }

  downloadInstruction(instruction: any) {
    this.fileDownloadService.downloadFile(instruction);
  }

  addToFavorites(isFavorited: any) {
    if (isFavorited) {
      this.productService.addToProductToFavorite(this.product.id).subscribe({
        next: () => {
          this.subscriptions.add(this.productService.getFavoritedProducts().subscribe());
          this.subscriptions.add(this.productService.getProducts().subscribe());
        },
        error: (er) => {
          this.toastService.displayToast(er.error.errors[0], 'error');
        }
      });
    } else {
      this.productService.deleteFavorited(this.product.id).subscribe({
        next: () => {
          this.subscriptions.add(this.productService.getFavoritedProducts().subscribe());
          this.subscriptions.add(this.productService.getProducts().subscribe());
        },
        error: (er) => {
          this.toastService.displayToast(er.error.errors[0], 'error');
        }
      });
    }
  }

  setSwiper(swiperRef: ElementRef | undefined) {
    this.swiper = swiperRef?.nativeElement.swiper;
    if (this.swiper) {
      this.swiper.params.zoom = true; //testar
      this.swiper.params.pagination = true;
    }
  }

  private populateSlides() {
    this.product.pictures.forEach((pic: any) => this.images.push(pic?.file));
  }

  private prioritizeHighlightPicture(product: any) {
    const highlightPicture = product.pictures.find((pic: any) => pic.highlight);
    const otherPictures = product.pictures.filter((pic: any) => !pic.highlight);

    return {
      ...product,
      pictures: [highlightPicture, ...otherPictures],
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
