import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, tap } from 'rxjs';

import { ToastService } from 'src/app/services/toast/toast.service';
import { RecordBreadcrumb } from 'src/app/services/tracking/tracking.service';
import { CategoryService } from 'src/app/services/ws-common/categories/category.service';
import { ProductService } from 'src/app/services/ws-product/products/product.service';

@Component({
  selector: 'app-products-search',
  templateUrl: './products-search.component.html',
  styleUrls: ['./products-search.component.scss']
})
export class ProductsSearchComponent {
  isLoaded = false;
  query = '';
  categories: any[] = [];
  procedures: any[] = [];
  products: any = [];
  previousPage!: any;

  constructor(private activatedRoute: ActivatedRoute, private categoryService: CategoryService,
    private productService: ProductService, private router: Router, private toastService: ToastService) { }

  ngOnInit(): void {
    this.query = this.activatedRoute.snapshot.queryParamMap.get('query')!;
    this.previousPage = this.activatedRoute.snapshot.queryParamMap.get('prev')!;

    if (this.query) { RecordBreadcrumb('Search', { component: 'Product', search: this.query }); }

    const callCategories$ = this.getCategories();
    const callProducts$ = this.getProducts();

    forkJoin([callCategories$, callProducts$]).subscribe({
      next: () => this.isLoaded = true,
      error: (err) => this.toastService.displayToast(err, 'error')
    });
  }

  private getCategories() {
    return this.categoryService.getSubcategoryAllRelated().pipe(
      tap(res => {
        res.data.forEach((category: any) => {
          const categoryName = category.title;
          category.children?.forEach((child: any) => {
            const childWithParent = { ...child, parentName: categoryName, parentId: category.id };
            child.category ? this.categories.push(childWithParent) : this.procedures.push(childWithParent);
          });
        });
      }));
  }

  private getProducts() {
    return this.productService.getProducts().pipe(
      tap(res => {
        this.products = res.data;
      }));
  }

  gotoDetails(item: any): void {
    this.router.navigate([`products/detail/${item.id}`], { queryParams: { prev: `products/search`, query: this.query } });
  }

  gotoDetailsCategory(category: any) {
    category.children ?
      this.router.navigate(['products/subcategories'],
        { queryParams: { prev: `products/search`, query: this.query, category: category.id } })
      :
      this.router.navigate(['products/list'],
        { queryParams: { prev: `products/search`, query: this.query, category: category.parentId, subcategory: category.id } });
  }

  filter(e: any) {
    this.query = e.target.value;
    if (this.query) { RecordBreadcrumb('Search', { component: 'Product', search: this.query }); }
  }

  gotoPreviousPage() {
    if (this.previousPage === 'subcategories') {
      this.router.navigate([`products/${this.previousPage}`], {
        queryParams:
          { category: this.activatedRoute.snapshot.queryParamMap.get('category')! }
      });
    } else if (this.previousPage === 'list') {
      this.router.navigate([`products/${this.previousPage}`], {
        queryParams:
        {
          category: this.activatedRoute.snapshot.queryParamMap.get('category')!,
          subcategory: this.activatedRoute.snapshot.queryParamMap.get('subcategory')!
        }
      });
    } else {
      this.router.navigate([this.previousPage]);
    }
  }
}
