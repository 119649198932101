<ion-content>
  <div class="flex">
    <div class="main" @routeAnimations [ngClass]="router.url.includes('/login') ? 'h-full-view' : iosMainStyle">
      <router-outlet></router-outlet>
    </div>
    <app-tabs [iosTab]="iosTabStyle"
      *ngIf="!router.url.includes('/login') && !router.url.includes('/profile') && !router.url.includes('/web-email')"
      class="flex justify-around items-center"></app-tabs>
  </div>
</ion-content>
