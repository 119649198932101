<div class="wrapper px-4" (mousedown)="mouseDown($event)" (mouseleave)="pressed = false" (mouseup)="pressed = false"
  (mousemove)="mouseMove($event)">
  <div class="flex g-3 pe-4" *ngIf="type === 'procedures'">
    <app-shorts-card *ngFor="let item of data" [procedure]="item" (unfavorited)="unfavorite($event)"
      (click)="onGotoDetail.emit(item)"></app-shorts-card>
  </div>
  <div class="flex g-3 pe-4" *ngIf="type === 'news'">
    <app-slide-card *ngFor="let item of data" [data]="item" [isHighlight]="isHighlight"
      (unfavorited)="unfavorite($event)" (click)="onGotoDetail.emit(item)"></app-slide-card>
  </div>
  <div class="flex g-3 pe-4" *ngIf="type === 'videos'">
    <app-slide-card *ngFor="let item of data" [data]="item" [type]="type" [isHighlight]="isHighlight"
      (unfavorited)="unfavorite($event)" (click)="onGotoDetail.emit(item)"></app-slide-card>
  </div>
  <div class="flex g-3 pe-4" *ngIf="type === 'products'">
    <app-product-card *ngFor="let item of data" [product]="item" (unfavorited)="unfavorite($event)"
      (click)="onGotoDetail.emit(item)"></app-product-card>
  </div>
  <div class="flex g-3 pe-4" *ngIf="type === 'guides'">
    <app-guide-card *ngFor="let item of data" [technicalGuide]="item"></app-guide-card>
  </div>
</div>
