import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { finalize } from 'rxjs';

import { StringResponse } from 'src/app/models';
import { ThemeService } from 'src/app/services/theme/theme.service';
import { ToastService } from 'src/app/services/toast/toast.service';
import { AuthService } from 'src/app/services/ws-user/auth/auth.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

  form!: FormGroup;
  awaitingResponse = false;
  isDarkTheme = false;

  constructor(private toastService: ToastService, private authService: AuthService, public themeService: ThemeService) { }

  ngOnInit(): void {
    this.createForm();
    this.isDarkTheme = this.themeService.getTheme() === 'dark';
  }

  createForm() {
    this.form = new FormGroup({
      email: new FormControl({ value: null, disabled: false },
        [Validators.required, Validators.email, Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)]
      )
    });
  }

  isValid(str: string) {
    return !!this.form.controls[str].errors && !!this.form.controls[str].touched;
  }

  onSubmit() {
    this.awaitingResponse = true;
    this.authService
      .forgotPassword(this.form.getRawValue())
      .pipe(finalize(() => this.awaitingResponse = false))
      .subscribe({
        next: (res: StringResponse) => {
          this.toastService.displayToast(res.data, 'success');
          this.form.reset();
        },
        error: (err) => this.toastService.displayToast(err.error?.errors, 'error')
      });
  }
}
