import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent } from '@ionic/angular';
import { CacheService } from 'src/app/services/cache/cache.service';

import { CategoryService } from 'src/app/services/ws-common/categories/category.service';
import { ProductService } from 'src/app/services/ws-product/products/product.service';

@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit {
  isLoaded = false;
  @ViewChild(IonContent) content!: IonContent;

  subcategoryId!: any;
  categoryId!: any;
  subcategoryName!: any;
  products: any[] = [];
  previousRoute = 'products/list';
  previousQuery = '';
  searchQuery = '';

  constructor(private productService: ProductService, private categoryService: CategoryService,
    private activatedRoute: ActivatedRoute, private router: Router, private cache: CacheService) { }

  ngOnInit(): void {
    this.extractAllRouterParams();
    this.fetchData();
  }

  private fetchData() {
    this.isLoaded = false;
    this.categoryService.getSubcategoryByIdProductRelated(this.categoryId).subscribe(response => {
      this.subcategoryName = response.data.children.find((c: any) => c.id === this.subcategoryId)?.title;
    });
    this.productService.getProductsByCategory(this.subcategoryId).subscribe(res => {
      let arrayRef = res.data;
      this.products = [...arrayRef];
      this.isLoaded = true;
    });

    if (this.previousRoute === null) {
      this.previousRoute = 'products/subcategories';
    }
  }

  private extractAllRouterParams() {
    this.subcategoryId = this.activatedRoute.snapshot.queryParamMap.get('subcategory')!;
    this.categoryId = this.activatedRoute.snapshot.queryParamMap.get('category')!;
    this.previousRoute = this.activatedRoute.snapshot.queryParamMap.get('prev')!;
    this.previousQuery = this.activatedRoute.snapshot.queryParamMap.get('query')!;
  }

  gotoDetail(product: any) {
    this.router.navigate(['products/detail', product.id], { queryParams: { category: this.categoryId, subcategory: this.subcategoryId } });
  }

  gotoPreviousPage() {
    this.previousRoute === 'products/subcategories' ?
      this.router.navigate([`${this.previousRoute}`], { queryParams: { category: this.categoryId } }) :
      this.router.navigate([`${this.previousRoute}`], { queryParams: { query: this.previousQuery } });
  }

  listenForSearchEnter(e: KeyboardEvent) {
    if (e.code === 'Enter' || e.key === 'Enter') {
      this.router.navigate(['products/search'], { queryParams: { prev: 'list', category: this.categoryId, subcategory: this.subcategoryId, query: this.searchQuery } });
    }
  }

  search(e: CustomEvent) {
    const query = (e.detail as any).value;
    if (query) {
      this.searchQuery = query;
    }
  }

  handleRefresh(event: any) {
    this.cache.clearAll();
    this.extractAllRouterParams();
    this.fetchData();
    event.target.complete();
  }
}
