<button class="arrow-back" *ngIf="buttonType === 'white'">
  <ion-icon class="pe-3" (click)="goBack()" name="arrow-back-outline"></ion-icon>
</button>

<button class="arrow-back" *ngIf="buttonType === 'blue'">
  <img class="pe-3" (click)="goBack()" src="../../../assets/icons/arrow_back_FILL0_wght400_GRAD0_opsz24.svg">
</button>

<button class="arrow-back" *ngIf="buttonType === 'black'">
  <img class="pe-3" (click)="goBack()" src="../../../assets/icons/arrow_back_FILL0_wght400_GRAD0_opsz24_black.svg">
</button>

<button class="arrow-back button-background" *ngIf="buttonType === 'background'">
  <img class="pe-3" (click)="goBack()" src="../../../assets/icons/arrow_back_FILL0_wght400_GRAD0_opsz24.svg">
</button>
<button class="arrow-back button-background video" *ngIf="buttonType === 'video'">
  <img class="pe-3" (click)="goBack()" src="../../../assets/icons/arrow_back_FILL0_wght400_GRAD0_opsz24.svg">
</button>
