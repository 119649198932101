import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByName'
})
export class FilterByNamePipe implements PipeTransform {

  transform(data: any, descriptionQuery: string) {

    descriptionQuery = this.normalize(descriptionQuery).toLowerCase().trim();

    if (descriptionQuery) {
      return data.filter((obj: any) => {
        if (obj.title) {
          return this.normalize(obj.title || '').toLowerCase().includes(descriptionQuery);
        } else {
          return this.normalize(obj.name || '').toLowerCase().includes(descriptionQuery);
        }
      });
    } else {
      return data;
    }
  }

  private normalize(text: string): string {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9]/g, '');
  }
}
