import { Component, Input, OnInit } from '@angular/core';

import { FileDownloadService } from 'src/app/services/download/file-download.service';

@Component({
  selector: 'app-guide-card',
  templateUrl: './guide-card.component.html',
  styleUrls: ['./guide-card.component.scss']
})
export class GuideCardComponent implements OnInit {
  @Input() technicalGuide!: any;
  fileToDownload!: any;
  fileNameFormatted = '';
  isDownloading: boolean = false;
  isDownloaded: boolean = false;

  constructor(private fileDownloadService: FileDownloadService) { }
  ngOnInit(): void {
    this.fileToDownload = this.technicalGuide.attachments[0]?.file;
  }

  async downloadInstruction() {
    this.isDownloading = true;
    try {
      await this.fileDownloadService.downloadFile(this.technicalGuide.attachments[0]);
    } finally {
      this.isDownloading = false;
      this.isDownloaded = true;
    }
  }
}
