import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './guards/auth/auth.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    canActivate: [authGuard],
    data: {
      animation: 'isRight'
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
    canActivate: [authGuard],
    data: {
      animation: 'isRight'
    }
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [authGuard]
  },
  {
    path: 'home/procedures',
    loadChildren: () => import('./procedures/procedures.module').then(m => m.ProceduresModule),
    canActivate: [authGuard]
  },
  {
    path: 'home/news',
    loadChildren: () => import('./news/news.module').then(m => m.NewsModule),
    canActivate: [authGuard]
  },
  {
    path: 'home/guides',
    loadChildren: () => import('./technical-guides/technical-guides.module').then(m => m.TechnicalGuidesModule),
    canActivate: [authGuard]
  },
  {
    path: 'favorites',
    loadChildren: () => import('./favorites/favorites.module').then(m => m.FavoritesModule),
    canActivate: [authGuard],
    data: {
      animation: 'isRight'
    }
  },
  {
    path: 'events',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
    canActivate: [authGuard],
    data: {
      animation: 'isLeft'
    }
  },
  {
    path: 'web-email',
    loadChildren: () => import('./web-email/web-email.module').then(m => m.WebEmailModule)
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
