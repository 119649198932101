import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CategoryService } from 'src/app/services/ws-common/categories/category.service';

@Component({
  selector: 'app-categories-list',
  templateUrl: './categories-list.component.html',
  styleUrls: ['./categories-list.component.scss']
})
export class CategoriesListComponent implements OnInit {

  isLoaded = false;
  categories: any = [];

  constructor(private router: Router, private categoryService: CategoryService) { }

  ngOnInit(): void {
    this.categoryService.getCategoryProductRelated().subscribe(res => {
      this.categories = res.data;
      this.isLoaded = true;
    });
  }

  handleSearch(e: any) {
    if (e.target.value) {
      this.router.navigate(['/products/search'], { queryParams: { query: e.target.value, prev: 'products/categories' } });
    }
  }

  gotoDetail(category: any) {
    this.router.navigate(['/products/subcategories'], { queryParams: { category: category.id } });
  }

  goBack() {
    this.router.navigate(['/home']);
  }
}
