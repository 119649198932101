import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, of, shareReplay, tap } from 'rxjs';

import { environment } from 'src/environments/environment';
import { CacheService } from '../../cache/cache.service';
import { AuthResponse, MeResponse, MeUpdate, MeUpdatePassword, StringResponse } from 'src/app/models';

const URL_ME = `${environment.url}/me`;

@Injectable({
  providedIn: 'root'
})
export class MeService {
  private meCache$?: Observable<any>;
  meSubject$: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(private http: HttpClient, private cache: CacheService) { }

  getMe() {
    this.meCache$ = this.getMeFromApi().pipe(tap((data: MeResponse) => {
      this.meSubject$.next(data);
    }), shareReplay(1));
    return this.meCache$;
  }

  getMeCache(): Observable<any> {
    if (!this.meCache$) {
      this.meCache$ = this
        .getMeFromApi()
        .pipe(
          tap((data: MeResponse) => {
            this.meSubject$.next(data);
          }),
          shareReplay(1)
        );
    }
    return this.meCache$;
  }

  updateCache(newData: any): void {
    this.meSubject$.next(newData);
  }

  getMeFromApi(clearCache = false): Observable<MeResponse> {
    if (clearCache) {
      this.cache.clearByKey(URL_ME);
    }

    return this.http.get<MeResponse>(URL_ME, { withCredentials: true })
  }

  updateMe(user: MeUpdate): Observable<AuthResponse> {
    this.cache.clearByKey(URL_ME);
    return this.http.post<AuthResponse>(`${URL_ME}/update`, user, { withCredentials: true });
  }

  updatePassword(passwords: MeUpdatePassword): Observable<AuthResponse> {
    return this.http.post<AuthResponse>(`${URL_ME}/update/password`, passwords, { withCredentials: true });
  }

  updateInterestArea(idPerson: string, interestAreas: string[]): Observable<AuthResponse> {
    this.cache.clearByKey(URL_ME);
    return this.http.post<AuthResponse>(`${URL_ME}/update/interest-area`, { idPerson, interestAreas }, { withCredentials: true });
  }

  deleteMe(): Observable<StringResponse> {
    this.cache.clearByKey(URL_ME);
    return this.http.delete<StringResponse>(`${URL_ME}/`, { withCredentials: true });
  }
}
