
<ion-content *ngIf="isLoaded">
  <div class="bg-grad">
    <header class="px-4 pt-9">
      <div class="w-full flex items-center">
        <app-back-button></app-back-button>
        <div>
          <h3 class="m-0">{{ parentCategoryName }}</h3>
        </div>
      </div>
      <ion-searchbar class="custom w-full mb-5" mode="ios" inputmode="search" (keyup.enter)="handleSearch($event)"
        [placeholder]="'search' | translate"></ion-searchbar>
    </header>
    <section class="px-4 pb-9 pt-4 card-container m-0">
      <app-segment [subcategories]="subcategories" [procedures]="procedures" (onSelectSub)="selectSubcategory($event)"></app-segment>
    </section>
  </div>
</ion-content>
