<div class="container flex justify-start direction-col">
  <img class="h-full w-full picture" [src]="data.pictures[0]?.file || data.pictures" onerror="this.src='../../../assets/icons/no_photography_FILL0_wght400_GRAD0_opsz24.svg'">
  <div class="flex items-center">
    <div>
      <p class="title pt-3">{{ data.title }}</p>
      <p class="date">{{ data.publishStart | date }}</p>
    </div>
    <img data-test="img" class="star" *ngIf="isFavoritesRoute()" (click)="unfavorite($event)" src="../../../assets/icons/star_yellow.svg">
  </div>
</div>
