import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

import { CacheService } from 'src/app/services/cache/cache.service';

/**
 * Serviço injetável que atua como um interceptor HTTP para implementar o cache de respostas.
 */
@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(private cacheService: CacheService) { }

  /**
   * Intercepta todas as requisições HTTP de saída para implementar a lógica de cache.
   * @param request O objeto HttpReque mst de saída.
   * @param next O HttpHandler para o próximo interceptor na cadeia.
   * @returns Um Observable da sequência de eventos HttpEvent.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Verifica se a requisição é do tipo GET, que é passível de cache.
    if (request.method !== 'GET') {
      // Se não for GET, prossegue com a próxima interceptação sem aplicar cache.
      return next.handle(request);
    }

    // Tenta recuperar a resposta do cache usando a URL da requisição.
    const cachedResponse = this.cacheService.get(request.url);
    if (cachedResponse) {
      // Se uma resposta em cache estiver disponível, retorna-a imediatamente sem fazer uma nova requisição.
      return of(cachedResponse);
    }

    // Se não houver resposta em cache, continua com a requisição e armazena a resposta quando recebida.
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        // Verifica se o evento é do tipo Response, o que indica que a resposta foi recebida.
        if (event.type === HttpEventType.Response) {
          // Armazena a resposta no cache para uso futuro.
          this.cacheService.put(request.url, event);
        }
      })
    );
  }
}
