import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-month-year-picker',
  templateUrl: './month-year-picker.component.html',
  styleUrls: ['./month-year-picker.component.scss']
})
export class MonthYearPickerComponent implements OnInit {
  @Output() onDateSelected = new EventEmitter<{ month: number, year: number }>();
  @Input() initialMonth!: number;
  @Input() initialYear!: number;
  months: string[];
  years: number[];
  selectedMonth: number;
  selectedYear: number;

  constructor(private translate: TranslateService) {
    this.months = [
      this.translate.instant('month.january'),
      this.translate.instant('month.february'),
      this.translate.instant('month.march'),
      this.translate.instant('month.april'),
      this.translate.instant('month.may'),
      this.translate.instant('month.june'),
      this.translate.instant('month.july'),
      this.translate.instant('month.august'),
      this.translate.instant('month.september'),
      this.translate.instant('month.october'),
      this.translate.instant('month.november'),
      this.translate.instant('month.december')
    ];

    const currentYear = new Date().getFullYear();
    this.years = Array.from({ length: 100 }, (_, i) => currentYear - i);

    const today = new Date();
    this.selectedMonth = today.getMonth();
    this.selectedYear = today.getFullYear();
  }
  ngOnInit(): void {
    this.selectedMonth = this.initialMonth;
    this.selectedYear = this.initialYear;
  }

  prevMonth() {
    if (this.selectedMonth > 0) {
      this.selectedMonth--;
    }
  }

  nextMonth() {
    if (this.selectedMonth < 11) {
      this.selectedMonth++;
    }
  }

  nextYear() {
    this.selectedYear = Number(this.selectedYear);
    const currentIndex = this.years.indexOf(this.selectedYear);
    if (currentIndex > 0) {
      this.selectedYear = this.years[currentIndex - 1];
    }
  }

  prevYear() {
    this.selectedYear = Number(this.selectedYear);
    const currentIndex = this.years.indexOf(this.selectedYear);
    if (currentIndex < this.years.length - 1) {
      this.selectedYear = this.years[currentIndex + 1];
    }
  }

  confirmSelection() {
    this.onDateSelected.emit({ month: this.selectedMonth, year: this.selectedYear });
  }
}
