<article *ngIf="type === 'filter'" class="p-5 no-results rounded-5 flex direction-col justify-center items-center">
  <img src="../../../assets/icons/search_off_FILL0_wght400_GRAD0_opsz48 1.svg" alt="">
  <h3>{{ 'no.results.filter' | translate }}</h3>
  <p>{{ 'typing.verify' | translate }}</p>
</article>

<article *ngIf="type === 'search'" class="p-5 mx-4 no-results rounded-5 flex direction-col justify-center items-center">
  <img src="../../../assets/icons/search_off_FILL0_wght400_GRAD0_opsz48 1.svg" alt="">
  <h3>{{ 'no.results.found' | translate }}</h3>
  <p>{{ 'typing.verify' | translate }}</p>
</article>

<article *ngIf="type === 'favorites'" class="p-5 mx-4 no-results rounded-5 flex direction-col justify-center items-center">
  <img src="../../../assets/icons/search_off_FILL0_wght400_GRAD0_opsz48 1.svg" alt="">
  <h3>{{ 'favorited.no' | translate }}</h3>
  <p>{{ 'favorited.click' | translate }}</p>
</article>
