import { Component, Input } from '@angular/core';

import { NavigationHistoryService } from 'src/app/services/navigation/navigation-history.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent {
  @Input() buttonType = 'white';
  constructor(private navigationHistoryService: NavigationHistoryService) {}

  goBack() {
    this.navigationHistoryService.navigateBack();
  }
}
