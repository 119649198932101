import { ErrorHandler, Injectable } from '@angular/core';
import { RecordError } from '../services/tracking/tracking.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }

  override handleError(error: any): void {
    RecordError(error);

    super.handleError(error);
  }
}
